import React, { useEffect, useState } from "react";
import "firebase/firestore";
import firebase from "firebase";
import { Button, Grid, Segment } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import LocalStoreNumber from "../components/inputTel";
import { deleteAllMarketAction } from "../store/marketAction";

const db = firebase.firestore();
toast.configure();

function ButtonValiderAchat({ markets, facture }, props) {
  const dispatch = useDispatch();
  let history = useHistory();
  const user = firebase.auth().currentUser;
  // const tel = localStorage.getItem("ivoireBookPhone");
  const [tel, setTel] = useState(null);

  useEffect(() => {
    setTel(localStorage.getItem("ivoireBookPhone"));
  }, []);

  const ChangeTel = () => {
    localStorage.removeItem("ivoireBookPhone");
    setTel(null);
  };

  const showMessages = () => {
    toast.success("🦄 votre achat a ete valider!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handelSubmit = async () => {
    const liste = await markets.map(function (elem) {
      return {
        titre: elem.titre,
        price: elem.price2,
        ref: elem.ref,
        image: elem.image,
      };
    });
    db.collection("commandes")
      .add({
        liste: liste,
        facture: facture,
        created: new Date(),
        owner: user ? user.displayName : localStorage.getItem("ivoireBookUser"),
        tel: localStorage.getItem("ivoireBookPhone"),
        lieux: localStorage.getItem("ivoireBookLieux"),
      })
      .then(function (docRef) {
        // console.log("Document written with ID: ", docRef.id);
        showMessages();
        dispatch(deleteAllMarketAction());
        history.push("/");
      })
      .catch(function (error) {
        // console.error("Error adding document: ", error);
      });
  };

  return (
    <>
      {!tel ? (
        <LocalStoreNumber achater={handelSubmit} />
      ) : (
        <div>
          {/* <Grid centered columns={2}>
            <Grid.Column>
              <Button onClick={handelSubmit} color="blue">
                valider votre achat
              </Button>
            </Grid.Column>
          </Grid> */}
          <Segment style={{ marginTop: "10px" }} color="orange">
            Bonjour {user ? user.displayName : ""}, après validation de
            votre commande vous serez livrés dans les 24h , ou vous pouvez nous
            appeler pour planifier une date de livraison.
            <br />
            votre numéro est bien le: {tel}
            <br />
            <Button basic color="red" onClick={ChangeTel}>
            modifier mes coordonnées <span role="img" aria-label="Panda">👆</span>
            </Button>
          </Segment>
          <Grid centered columns={2}>
            <Grid.Column>
              <Button onClick={handelSubmit} color="blue">
                valider votre achat
              </Button>
            </Grid.Column>
          </Grid>
        </div>
      )}
    </>
  );
}

export default ButtonValiderAchat;
