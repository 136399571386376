import React, { Component } from "react";
import { Icon, Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";

import MarketButton from "./marketButton";

export default class MenuExampleInverted extends Component {
  state = { activeItem: "" };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const { activeItem } = this.state;

    const url = window.location.href;
    // console.log(url);

    const homeUrl = "http://localhost:3000/";
    const loginUrl = "http://localhost:3000/login";
    const searchurl = "http://localhost:3000/recherche-livres";
    const storeUrl = "http://localhost:3000/mes_achats";

    return (
      <Menu color="blue" borderless fixed="bottom" widths={4}>
        <Menu.Item
          name="home"
          active={activeItem === "home" || url === homeUrl}
          onClick={this.handleItemClick}
        >
          <Link to="/" style={{ textDecoration: "none" }}>
            <Icon name="home" color="orange" size="large" />
          </Link>
        </Menu.Item>

        <Menu.Item
          name="search"
          active={activeItem === "search" || url === searchurl}
          onClick={this.handleItemClick}
        >
          <Link to="/recherche-livres" style={{ textDecoration: "none" }}>
            <Icon name="search" color="orange" size="large" />
          </Link>
        </Menu.Item>

        <Menu.Item
          name="shopping bag"
          active={activeItem === "shopping bag" || url === storeUrl}
          onClick={this.handleItemClick}
        >
          <Link to="/mes_achats" style={{ textDecoration: "none" }}>
            <Icon name="shopping bag" color="orange" size="large" />
            <MarketButton />
          </Link>
        </Menu.Item>

        <Menu.Item
          name="user"
          active={activeItem === "user" || url === loginUrl}
          onClick={this.handleItemClick}
        >
          <Link to="/inscription" style={{ textDecoration: "none" }}>
            <Icon name="user" color="orange" size="large" />
          </Link>
        </Menu.Item>
      </Menu>

      // <Button.Group
      //   size="small"
      //   style={{ position: "fixed", bottom: "3%", borderRadius: "40%" }}
      // >
      //   <Button
      //     name="home"
      //     active={activeItem === "home" || url === homeUrl}
      //     onClick={this.handleItemClick}
          
      //     // style={{  borderBottomLeftRadius: "20px", borderTopLeftRadius: "20px" }}
      //   >
      //     <Link to="/" style={{ textDecoration: "none" }}>
      //       <Icon name="home" size="large" />
      //     </Link>
      //   </Button>

      //   <Button
      //     name="search"
      //     active={activeItem === "search" || url === searchurl}
      //     onClick={this.handleItemClick}
      //   >
      //     <Link to="/recherche-livres" style={{ textDecoration: "none" }}>
      //       <Icon name="search" size="large" />
      //     </Link>
      //   </Button>

      //   <Button
      //     name="user"
      //     active={activeItem === "user" || url === loginUrl}
      //     onClick={this.handleItemClick}
      //   >
      //     <Link to="/inscription" style={{ textDecoration: "none" }}>
      //       <Icon name="user" size="large" />
      //     </Link>
      //   </Button>

      //   <Button
      //     name="shopping bag"
      //     active={activeItem === "shopping bag" || url === storeUrl}
      //     onClick={this.handleItemClick}

      //     // style={{  borderBottomRightRadius: "20px", borderTopRightRadius: "20px" }}
      //   >
      //     <Link to="/mes_achats" style={{ textDecoration: "none" }}>
      //       <Icon name="shopping bag" size="large" />
      //       <MarketButton />
      //     </Link>
      //   </Button>
      // </Button.Group>
    );
  }
}
