import React from "react";
import { Grid, Divider, Icon, Header, Button, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";

function linkCathegorie() {
  return (
    <>
      <Image
        src="https://cdn.pixabay.com/photo/2015/05/25/14/53/book-783394_960_720.png"
        fluid
        style={{ height: "130px" }}
      />
      <Divider horizontal>
        <Header as="h4">
          <Icon name="bar chart" />
          Catégories en vedette
        </Header>
      </Divider>
      <Grid columns={2}>
      <Grid.Column style={{ padding: "5px" }}>
        <Link to="catalogue/développement">
          <Button color="red" fluid circular compact>
            developement personel
          </Button>
        </Link>
      </Grid.Column>
      <Grid.Column style={{ padding: "5px" }}>
        <Link to="catalogue/marketing">
          <Button color="green" fluid circular compact>
            Marketing, Vente
          </Button>
        </Link>
      </Grid.Column>
      <Grid.Column style={{ padding: "5px" }}>
        <Link to="catalogue/philosophie">
          <Button color="brown" fluid circular compact>
            Sciences politiques et société
          </Button>
        </Link>
      </Grid.Column>
      <Grid.Column style={{ padding: "5px" }}>
        <Link to="catalogue/economie">
          <Button color="blue" fluid circular compact>
            Économie, finance et Comptabilité
          </Button>
        </Link>
      </Grid.Column>
      <Grid.Column style={{ padding: "5px" }}>
        <Link to="catalogue/leadership">
          <Button color="violet" fluid circular compact>
            Management & Leadership
          </Button>
        </Link>
      </Grid.Column>
      <Grid.Column style={{ padding: "5px" }}>
        <Link to="catalogue/communication">
          <Button color="purple" fluid circular compact>
            Communication
          </Button>
        </Link>
      </Grid.Column>
      <Grid.Column style={{ padding: "5px" }}>
        <Link to="catalogue/spiritualiter">
          <Button color="pink" fluid circular compact>
            Religions et spiritualiter
          </Button>
        </Link>
      </Grid.Column>
      <Grid.Column style={{ padding: "5px" }}>
        <Link to="catalogue/science">
          <Button color="grey" fluid circular compact>
          science et technologie
          </Button>
        </Link>
      </Grid.Column>
      <Grid.Column style={{ padding: "5px" }}>
        <Link to="catalogue/roman">
          <Button color="black" fluid circular compact>
            Roman et literatures
          </Button>
        </Link>
      </Grid.Column>
      <Grid.Column style={{ padding: "5px", paddingBottom: "40px" }}>
        <Link to="catalogue/maitrise-de-la-langue">
          <Button color="yellow" fluid circular compact style={{ color: "black"}}>
          Maîtrise de la langue
          </Button>
        </Link>
      </Grid.Column>
      </Grid>
    </>
  );
}

export default linkCathegorie;
