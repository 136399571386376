import React, { useState } from "react";
import { Button, Header, Icon, Modal } from "semantic-ui-react";

function ModalExampleBasic(props) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleMessageChange = (event) => {
    // 👇️ access textarea value
    setMessage(event.target.value);
    // console.log(event.target.value);
  };

  const sendNote = (event) => {
    // 👇️ access textarea value
    console.log(message);
    console.log(props.userEmail1);
    console.log(props.bookTitre);

    props.dataBase
      .collection("notes")
      .add({
        bookTitre: props.bookTitre,
        note: message,
        created: new Date(),
        owner: props.userEmail1,
        userName: props.userName,
        userImage: props.userImage,
        illustation: props.Illustation,
        progresValue: props.ProgresValue,
        status: "public"
      })
      .then(function (docRef) {
        // console.log("Document written with ID: ", docRef.id);
        // showMessages();
        // dispatch(deleteAllMarketAction());
        // history.push("/");
        // console.log(docRef);
      })
      .catch(function (error) {
        // console.error("Error adding document: ", error);
      });

    setOpen(false);
    setMessage("");
  };

  return (
    <Modal
      closeIcon
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
      trigger={
        <Icon
          size="large"
          circular
          color="blue"
          name="pencil alternate"
          style={{
            position: "fixed",
            bottom: "30px",
            right: "10px",
            textDecoration: "none"
          }}
        />
      }
    >
      <Header>Archive Old Messages</Header>

      {/* <Form.TextArea
        style={{ rows: 5, cols: 30 }}
        placeholder="j ai une inspiration"
      /> */}
      <Modal.Content>
        <textarea
          placeholder="j ai une idee"
          backgroundImage="url(http://placekitten.com/500/500)"
          style={{ width: "100%" }}
          rows="7"
          id="message"
          name="message"
          value={message}
          onChange={handleMessageChange}
        />
      </Modal.Content>

      <Modal.Actions>
        <Button color="green" inverted onClick={() => sendNote()}>
          <Icon name="checkmark" /> Enregistre
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ModalExampleBasic;
