import React from "react";
import "firebase/firestore";
import firebase from "firebase";
import { Input, Form, Segment } from "semantic-ui-react";
import NumberFormat from "react-number-format";
import { Redirect } from "react-router-dom";

class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = { userTel: "", user: "", lieux: "", inputEnd: false };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeUser = this.handleChangeUser.bind(this);
    this.handleChangeLieux = this.handleChangeLieux.bind(this);
  }

  handleChange(event) {
    this.setState({
      userTel: event.target.value,
    });
  }

  handleChangeUser(event) {
    this.setState({
      user: event.target.value,
    });
  }

  handleChangeLieux(event) {
    this.setState({
      lieux: event.target.value,
    });
  }

  mySubmitHandler = async (event) => {
    event.preventDefault();
    const tel = this.state.userTel;
    const user = this.state.user;
    const lieu = this.state.lieux;
    localStorage.setItem("ivoireBookPhone", tel);
    localStorage.setItem("ivoireBookUser", user);
    localStorage.setItem("ivoireBookLieux", lieu);
    this.props.achater();
    this.setState({ inputEnd: true });

    console.log(tel.length);
  };

  render() {
    const user = firebase.auth().currentUser;
    return (
      <div style={{ marginTop: "45px"}}>
        {this.state.inputEnd ? (
          <Redirect to="/" />
        ) : (
          <>
            <span style={{ marginTop: "45px", fontWeight: "bold" }}>
              veuillez saisir vos coordonnées de livraison
              <span role="img" aria-label="ivoire-book">
                👇
              </span>
              :
            </span>
            <Segment raised textAlign="center">
              <span style={{ fontWeight: "bold" }}>numero de telephone:</span>
              <NumberFormat
                label={{ icon: "phone" }}
                placeholder="numero de telephone pour la livraison"
                customInput={Input}
                onChange={this.handleChange}
                value={this.state.card}
                format="+225 ## ## ## ## ##"
                fluid
              />

              <Form
                style={{ marginTop: "20px" }}
                onSubmit={this.mySubmitHandler}
              >
                {/* <h1>Tel {this.state.userTel}</h1> */}

                {/* <input type="text" onChange={this.myChangeHandler} /> */}
                {!user ? (
                  <Form.Group widths="equal">
                    <Form.Input
                      onChange={this.handleChangeUser}
                      label="Nom:"
                      placeholder="votre Nom et Premon"
                    />
                    {/* <Form.Input
                  fluid
                  id='form-subcomponent-shorthand-input-first-name'
                  label='First name'
                  placeholder='First name'
                /> */}
                    <Form.Input
                      onChange={this.handleChangeLieux}
                      label="Lieux de livraison:"
                      placeholder="Lieux de livraison"
                    />
                  </Form.Group>
                ) : (
                  ""
                )}

                {this.state.userTel ? (
                  <Form.Button
                    color="blue"
                    style={{ margin: "30px" }}
                    content="Valider"
                  />
                ) : (
                  ""
                )}
                {/* <input type="submit" /> */}
              </Form>
            </Segment>
          </>
        )}
      </div>
    );
  }
}

// class SignInScreen extends React.Component {
//   // The component's Local state.
//   state = {
//     userTel: "",
//     inputEnd: false,
//   };

//   mySubmitHandler = (event) => {
//     event.preventDefault();
//     localStorage.setItem("ivoireBookPhone", this.state.userTel);
//     this.setState({ inputEnd: true });

//     console.log(this.state.userTel);
//   };

//   myChangeHandler = (event) => {
//     this.setState({ userTel: event.target.value });
//   };

//   render() {
//     const LocalStoreNumber = () => {
//       return (
//         <div>
//           {this.state.inputEnd ? (
//             ""
//           ) : (
//             <form onSubmit={this.mySubmitHandler}>
//               <h1>Hello {this.state.userTel}</h1>
//               <p>votre numero de telephone pour vos livraisons:</p>
//               <NumberFormat
//                 label={{ icon: "phone" }}
//                 placeholder="numero de telephone pour la livraison"
//                 customInput={Input}
//                 onChange={(event) => this.myChangeHandler(event)}
//                 value={this.state.card}
//                 format="+225 ## ## ## ##"
//               />
//               {/* <input type="text" onChange={this.myChangeHandler} /> */}
//               <input type="submit" />
//             </form>
//           )}
//         </div>
//       );
//     };

//     return <LocalStoreNumber />;
//   }
// }

export default Example;
