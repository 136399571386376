import _ from "lodash";
import React, { useState } from "react";
import {
  Search,
  Grid,
  Dropdown,
  Image,
  Responsive,
  Container,
  Button
} from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import slugify from "slugify";

import CathegorieLink from "../linkCathegorie";

const initialState = {
  loading: false,
  results: [],
  value: ""
};

function exampleReducer(state, action) {
  switch (action.type) {
    case "CLEAN_QUERY":
      return initialState;
    case "START_SEARCH":
      return { ...state, loading: true, value: action.query };
    case "FINISH_SEARCH":
      return { ...state, loading: false, results: action.results };
    case "UPDATE_SELECTION":
      return { ...state, value: action.selection };

    default:
      throw new Error();
  }
}

function SearchExampleStandard(allData) {
  const [state, dispatch] = React.useReducer(exampleReducer, initialState);
  const { loading, results, value } = state;
  const [defaulSelected, setDefaulSelected] = useState("titre");
  let history = useHistory();

  const source = allData.allData.map((livre) => ({
    title: livre.titre,
    cathegory: livre.prodcathegory,
    // description: `${livre.text.substr(0, 40).trim()} ...`,
    image: livre.image,
    price: `${livre.price2} fcfa`,
    ref: livre.ref,
    auteur: livre.auteur
  }));

  const imagesSelected = (title, ref) => {
    const slugifyUrl = slugify(title);
    history.push(`/livres/${slugifyUrl}/${ref}`);
  };

  const Cathegorie = () => (
    <div style={{ padding: "0px", marginTop: "30px" }}>
      <CathegorieLink />
    </div>
  );

  const timeoutRef = React.useRef();
  const handleSearchChange = React.useCallback(
    (e, data) => {
      clearTimeout(timeoutRef.current);
      dispatch({ type: "START_SEARCH", query: data.value });

      timeoutRef.current = setTimeout(() => {
        if (data.value.length === 0) {
          dispatch({ type: "CLEAN_QUERY" });
          return;
        }

        const re = new RegExp(_.escapeRegExp(data.value), "i");
        // si la rechercher se base sur le titre du livre
        const isMatch = (result) => re.test(result.title);
        // si la recherche se base sur l auteur du livre
        const isMatchAeuteur = (result) => re.test(result.auteur);

        if (defaulSelected === "auteur") {
          dispatch({
            type: "FINISH_SEARCH",
            results: _.filter(source, isMatchAeuteur)
          });
        } else {
          dispatch({
            type: "FINISH_SEARCH",
            results: _.filter(source, isMatch)
          });
        }
      }, 300);
    },
    [defaulSelected, source]
  );
  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <div style={{ marginBottom: "20px" }}>
      <Responsive {...Responsive.onlyMobile}>
        <Grid centered columns={2}>
          <Grid.Column width={10}>
            <Search
              loading={loading}
              showNoResults={false}
              onResultSelect={(e, data) => {
                dispatch({
                  type: "UPDATE_SELECTION",
                  selection: data.result.title
                });
              }}
              onSearchChange={handleSearchChange}
              // results={results}
              value={value}
            />
          </Grid.Column>

          <Grid.Column width={5}>
            <Button.Group color="teal">
              <Dropdown
                item
                icon="filter"
                floating
                labeled
                button
                className="button icon"
                text={defaulSelected}
                color="red"
              >
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setDefaulSelected("titre")}>
                    Titre
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setDefaulSelected("auteur")}>
                    Auteur
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Button.Group>
          </Grid.Column>
        </Grid>
        <Grid columns={2} padded>
          {results.length ? (
            results.map((result) => (
              <Grid.Column key={result.ref} textAlign="center">
                <Image
                  style={{ height: "180px", width: "100%" }}
                  onClick={() => imagesSelected(result.title, result.ref)}
                  rounded
                  src={result.image}
                />
                <p style={{ fontWeight: "bold" }}>{result.title}</p>
              </Grid.Column>
            ))
          ) : (
            <Cathegorie />
          )}
        </Grid>
      </Responsive>

      <Responsive minWidth={768}>
        <Container style={{ marginTop: "20px" }}>
          <Grid>
            <Grid.Column textAlign="center" width={13}>
              <Search
                loading={loading}
                showNoResults={false}
                onResultSelect={(e, data) => {
                  dispatch({
                    type: "UPDATE_SELECTION",
                    selection: data.result.title
                  });
                }}
                onSearchChange={handleSearchChange}
                // results={results}
                value={value}
              />
            </Grid.Column>

            <Grid.Column width={3}>
              <Button.Group color="teal">
                <Dropdown
                  item
                  icon="filter"
                  floating
                  labeled
                  button
                  className="button icon"
                  text={defaulSelected}
                  color="red"
                >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setDefaulSelected("titre")}>
                      Titre
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setDefaulSelected("auteur")}>
                      Auteur
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Button.Group>
            </Grid.Column>
          </Grid>

          <Grid columns={4} padded>
            {results.length ? (
              results.map((result) => (
                <Grid.Column key={result.ref} textAlign="center">
                  <Image
                    style={{ height: "280px", width: "100%" }}
                    onClick={() => imagesSelected(result.title, result.ref)}
                    rounded
                    src={result.image}
                  />
                  <p style={{ fontWeight: "bold" }}>{result.title}</p>
                </Grid.Column>
              ))
            ) : (
              <Cathegorie />
            )}
          </Grid>
        </Container>
      </Responsive>
    </div>
  );
}

export default SearchExampleStandard;
