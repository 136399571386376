/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { Responsive } from "semantic-ui-react";

// import BigMenu from "../components/headerMenu";
import MobileProfil from "../components/userProfile/mobileProfil";
import ComputerProfil from "../components/userProfile/computerProfil";
import firebase from "firebase";
// import "firebase/firestore";
// // import fireStore from "firebase/firestore"

import FooterMenu from "../components/fotterMenu";

const db = firebase.firestore();

function profil() {
  return (
    <div>
      <Responsive {...Responsive.onlyMobile}>
        <MobileProfil db={db} />
        <FooterMenu />
      </Responsive>
      <Responsive minWidth={768}>
        <ComputerProfil db={db} />
      </Responsive>
    </div>
  );
}

export default profil;
