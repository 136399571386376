import React from "react";
import { Segment } from "semantic-ui-react";

import { Helmet } from "react-helmet";

function Description(props) {
  //   const [descriptionValue, setDescriptionValue] = useState("");
  const route = props.url;

  return (
    <div>
      {(() => {
        switch (route) {
          case "marketing":
            return (
              <>
                <Helmet>
                  <title>marketing</title>
                  <meta
                    name="description"
                    content="Le marketing permet aux entreprises de promouvoir leurs
                  produits ou services auprès de leur public cible et de
                  stimuler les ventes."
                  />
                </Helmet>
                <Segment raised tertiary>
                  Le marketing permet aux entreprises de promouvoir leurs
                  produits ou services auprès de leur public cible et de
                  stimuler les ventes. il permet d'identifier les besoins et les
                  désirs des consommateurs, d'adapter les produits et les
                  services aux besoins de ces derniers, et de communiquer
                  efficacement sur ces produits et services.
                </Segment>
              </>
            );
          case "développement":
            return (
              <>
                <Helmet>
                  <title>développement</title>
                  <meta
                    name="description"
                    content="Le développement personnel est une démarche globale de réflexion
                sur soi et de valorisation de son potentiel dans le but
                d’améliorer la qualité de sa vie et de réaliser ses aspirations
                profondes."
                  />
                </Helmet>
                <Segment raised tertiary>
                  Le développement personnel est une démarche globale de
                  réflexion sur soi et de valorisation de son potentiel dans le
                  but d’améliorer la qualité de sa vie et de réaliser ses
                  aspirations profondes. Il peut toucher différents aspects de
                  la vie : professionnel, personnel, spirituel, financier,
                  relationnel, familial.
                </Segment>
              </>
            );
          case "philosophie":
            return (
              <>
                <Helmet>
                  <title>philosophie</title>
                  <meta
                    name="description"
                    content="   la philosophie aider à développer des compétences de pensée
                critique, de raisonnement logique et de résolution de problèmes.
                Elle permet de décortiquer les idées et les arguments, de les
                évaluer et de les mettre en doute. Cela peut être très utile
                dans la vie professionnelle et personnelle"
                  />
                </Helmet>
                <Segment raised tertiary>
                  la philosophie aider à développer des compétences de pensée
                  critique, de raisonnement logique et de résolution de
                  problèmes. Elle permet de décortiquer les idées et les
                  arguments, de les évaluer et de les mettre en doute. Cela peut
                  être très utile dans la vie professionnelle et personnelle.
                </Segment>
              </>
            );
          case "communication":
            return (
              <>
                <Helmet>
                  <title>communication</title>
                  <meta
                    name="description"
                    content="   la philosophie aider à développer des compétences de pensée
                critique, de raisonnement logique et de résolution de problèmes.
                Elle permet de décortiquer les idées et les arguments, de les
                évaluer et de les mettre en doute. Cela peut être très utile
                dans la vie professionnelle et personnelle"
                  />
                </Helmet>
                <Segment raised tertiary>
                  Dans les relations interpersonnelles, la communication
                  efficace est essentielle pour maintenir des relations saines
                  et constructives. Elle permet de comprendre les besoins, les
                  sentiments et les perspectives des autres, ainsi que de
                  résoudre les conflits de manière pacifique.
                </Segment>
              </>
            );
          case "spiritualiter":
            return (
              <>
                <Helmet>
                  <title>spiritualiter</title>
                  <meta
                    name="description"
                    content=" En général, elle fait référence à des croyances ou des pratiques
                qui concernent la dimension spirituelle ou transcendante de
                l'existence humaine."
                  />
                </Helmet>
                <Segment raised tertiary>
                  En général, elle fait référence à des croyances ou des
                  pratiques qui concernent la dimension spirituelle ou
                  transcendante de l'existence humaine. Cela peut inclure des
                  croyances religieuses, des pratiques méditatives ou de prière,
                  ou une quête personnelle de sens et de vérité.
                </Segment>
              </>
            );
          case "science":
            return (
              <>
                <Helmet>
                  <title>science et technologie</title>
                  <meta
                    name="description"
                    content=" L’humanité à été modelée depuis sa création par l’homme. Ils ont
                longtemps utilisés des techniques et méthodes pour s’adapter à
                leur environnement. Ces techniques ont été développées au fur et
                à mesure que l’humanité évoluait."
                  />
                </Helmet>
                <Segment raised tertiary>
                  L’humanité à été modelée depuis sa création par l’homme. Ils
                  ont longtemps utilisés des techniques et méthodes pour
                  s’adapter à leur environnement. Ces techniques ont été
                  développées au fur et à mesure que l’humanité évoluait. Les
                  sciences sont donc le résultat de l’intelligence de l’homme
                  qui veut comprendre son environnement et le faire évoluer.
                </Segment>
              </>
            );

          case "economie":
            return (
              <>
                <Helmet>
                  <title>economie et finance</title>
                  <meta
                    name="description"
                    content="L'économie est importante car elle détermine comment les ressources limitées sont allouées pour satisfaire les besoins et les désirs de la société."
                  />
                </Helmet>
                <Segment raised tertiary>
                  L'économie est importante car elle détermine comment les
                  ressources limitées sont allouées pour satisfaire les besoins
                  et les désirs de la société. Elle aide à comprendre les choix
                  économiques, tels que la production, la consommation et les
                  investissements, et peut influencer les politiques publiques
                  pour améliorer le bien-être économique général.
                </Segment>
              </>
            );
          case "roman":
            return (
              <>
                <Helmet>
                  <title>roman</title>
                  <meta
                    name="description"
                    content=" Le roman est une œuvre littéraire en prose d’une certaine
                longueur mêlant le réel et l’imaginaire. Dans sa forme la plus
                traditionnelle, le roman cherche à susciter l’intérêt et le
                plaisir du lecteur en racontant le destin d’un héros principal,
                une intrigue entre plusieurs personnages."
                  />
                </Helmet>
                <Segment raised tertiary>
                  Le roman est une œuvre littéraire en prose d’une certaine
                  longueur mêlant le réel et l’imaginaire. Dans sa forme la plus
                  traditionnelle, le roman cherche à susciter l’intérêt et le
                  plaisir du lecteur en racontant le destin d’un héros
                  principal, une intrigue entre plusieurs personnages. Ces
                  personnages qui sont présentés dans leurs psychologies, leur
                  passion, leurs aventures, leur milieu social. Tout ceci sur un
                  arrière-fond moral, métaphysique.
                </Segment>
              </>
            );
          case "maitrise-de-la-langue":
            return (
              <>
                <Helmet>
                  <title>maitrise de la langue</title>
                  <meta
                    name="description"
                    content=" La maîtrise de la langue française joue un rôle crucial dans la
                réussite scolaire et l'insertion professionnelle et sociale; son
                apprentissage par chacun doit être encouragé très tôt et
                renforcé, en fonction des besoins identifiés."
                  />
                </Helmet>
                <Segment raised tertiary>
                  La maîtrise de la langue française joue un rôle crucial dans
                  la réussite scolaire et l'insertion professionnelle et
                  sociale; son apprentissage par chacun doit être encouragé très
                  tôt et renforcé, en fonction des besoins identifiés.
                </Segment>
              </>
            );
          case "leadership":
            return (
              <>
                <Helmet>
                  <title>leadership</title>
                  <meta
                    name="description"
                    content=" Au sein de chaque entreprise, si chacun fait son propre travail,
                il est néanmoins important et indispensable de définir un
                ensemble de règles générales"
                  />
                </Helmet>
                <Segment raised tertiary>
                  Au sein de chaque entreprise, si chacun fait son propre
                  travail, il est néanmoins important et indispensable de
                  définir un ensemble de règles générales. Celles-ci servent de
                  cadre de référence unique applicable à tous les collaborateurs
                  afin de générer des synergies collectives. Dans ce contexte,
                  suivre une formation en management est un atout majeur et dans
                  tout domaine d'activités. En effet, savoir gérer une équipe
                  nécessite un savoir-être et un savoir-faire particulier pour
                  mieux organiser les tâches, gérer les ressources et atteindre
                  les objectifs fixés.
                </Segment>
              </>
            );
          default:
            return null;
        }
      })()}
    </div>
  );
}

export default Description;

// function description(props) {
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   const [descriptionValue, setDescriptionValue] = useState("");
//   const url = window.location.href;

//   //   const expr = "Papayas";
//   switch (url) {
//     case "http://localhost:3000/catalogue/marketing":
//       setDescriptionValue("cest du marquetim");
//       break;
//     case "http://localhost:3000/catalogue/d%C3%A9veloppement":
//       setDescriptionValue("cest developement");
//       break;
//     case "http://localhost:3000/catalogue/leadership":
//       setDescriptionValue("cest le leader");
//       // Expected output: "Mangoes and papayas are $2.79 a pound."
//       break;
//     default:
//       setDescriptionValue("");
//   }
//   return <Segment> la description de la pages est {descriptionValue}</Segment>;
// }

// export default description;
