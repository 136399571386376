import React from "react";
import {
  Container,
  Image,
  Segment,
  Grid,
  Breadcrumb,
  Header,
  Icon,
  Card
} from "semantic-ui-react";
import { Link } from "react-router-dom";
// import { useHistory } from "react-router-dom";
// import slugify from "slugify";
// import NumberFormat from "react-number-format";
import moment from "moment";

import Stats from "../stat";

import "firebase/firestore";
import firebase from "firebase";

import "moment/locale/fr"; // without this line it didn't work
moment.locale("fr");

function computerProfil(props) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // let history = useHistory();
  const userName = firebase.auth().currentUser
    ? firebase.auth().currentUser.displayName
    : false;

  const userImage = firebase.auth().currentUser
    ? firebase.auth().currentUser.photoURL
    : false;

  // const userName = firebase.auth().currentUser.displayName;
  // const userImage = c;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const [data, setData] = useState([]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  // useEffect(() => {
  //   props.db
  //     .collection("commandes")
  //     .where("owner", "==", userName)
  //     .get()
  //     .then((querySnapshot) => {
  //       const values = querySnapshot.docs.map((doc) => doc.data());
  //       setData(values);
  //     })

  // }, [props.db, userName]);

  // const selected = (titre, ref) => {
  //   const slugifyUrl = slugify(titre);
  //   history.push(`/livres/${slugifyUrl}/${ref}`);
  // };

  const BreadcrumbExample = () => (
    <Breadcrumb size="large" style={{ marginTop: "5px" }}>
      <Breadcrumb.Section link>
        <Link to="/">Acceille</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider />
      <Breadcrumb.Section active>Profil</Breadcrumb.Section>
    </Breadcrumb>
  );

  // console.log(props.getBiblio)

  // const selected = (titre, ref) => {
  //   const slugifyUrl = slugify(titre);
  //   history.push(`/livres/${slugifyUrl}/${ref}`);
  // };

  // commande.created.toDate().toDateString()

  // const panels = data.map((commande) => ({
  //   key: `panel-${commande.created}`,
  //   title: {
  //     content: (
  //       <Label color="blue">
  //         {moment(commande.created.toDate(), "YYYYMMDD").fromNow()}
  //         <Label.Detail>
  //           {commande.envoyer ? "deja livre" : "pas encore livree"}
  //         </Label.Detail>
  //       </Label>
  //     ),
  //   },
  //   content: {
  //     content: (
  //       <div>
  //         <Grid columns={4} padded="vertically">
  //           {commande.liste.map((livre) => (
  //             <Grid.Column
  //               key={livre.ref}
  //               width={4}
  //               textAlign="center"
  //               onClick={() => selected(livre.titre, livre.ref)}
  //             >
  //               <Image
  //                 style={{ height: "190px", width: "100%" }}
  //                 rounded
  //                 src={livre.image}
  //               />
  //               <h4 style={{ fontWeight: "bold" }}> {livre.titre}</h4>
  //             </Grid.Column>
  //           ))}
  //         </Grid>
  //       </div>
  //     ),
  //   },
  // }));

  const deleteConection = () => {
    firebase.auth().signOut();
    document.location.href = "/";
    // history.push("/");
  };

  // const AccordionExampleShorthand = () => (
  //   <Accordion defaultActiveIndex={3} panels={panels} />
  // );

  // const DataView = () => (
  //   <Grid columns={4} padded="vertically">
  //     {data ? (
  //       data.map((commande) => (
  //         <Grid.Column
  //           // key={livre.ref}
  //           width={4}
  //           textAlign="center"
  //           // onClick={() => selected(livre.titre, livre.ref)}
  //         >
  //           <p style={{ fontWeight: "bold" }}> {commande.tel} fcfa</p>
  //         </Grid.Column>
  //       ))
  //     ) : (
  //       <>
  //         <Grid.Column width={4} textAlign="center">
  //           <Placeholder style={{ height: 180, width: 150 }}>
  //             <Placeholder.Image />
  //           </Placeholder>
  //         </Grid.Column>
  //         <Grid.Column width={4} textAlign="center">
  //           <Placeholder style={{ height: 180, width: 150 }}>
  //             <Placeholder.Image />
  //           </Placeholder>
  //         </Grid.Column>
  //         <Grid.Column width={4} textAlign="center">
  //           <Placeholder style={{ height: 180, width: 150 }}>
  //             <Placeholder.Image />
  //           </Placeholder>
  //         </Grid.Column>
  //         <Grid.Column width={4} textAlign="center">
  //           <Placeholder style={{ height: 180, width: 150 }}>
  //             <Placeholder.Image />
  //           </Placeholder>
  //         </Grid.Column>
  //       </>
  //     )}
  //   </Grid>
  // );

  const GridExampleColumns = () => (
    <Grid style={{ marginTop: "20px" }}>
      <Grid.Row>
        <Grid.Column width={8}>
          <Link to="/BookGameHome">
            <Card fluid raised color="orange">
              <Card.Content textAlign="center">
                <Icon name="game" size="huge" color="orange" />
                <Card.Header style={{ marginTop: "5px", marginBottom: "10px" }}>
                  Game-Book
                </Card.Header>
              </Card.Content>
            </Card>
          </Link>
        </Grid.Column>

        <Grid.Column width={8}>
          <Link to="/stats">
            <Card fluid raised color="orange">
              <Card.Content textAlign="center">
                <Icon name="chart line" size="huge" color="orange" />
                <Card.Header style={{ marginTop: "5px", marginBottom: "10px" }}>
                  Mes stats
                </Card.Header>
              </Card.Content>
            </Card>
          </Link>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={8}>
          <Link to="/notes">
            <Card fluid raised color="orange">
              <Card.Content textAlign="center">
                <Icon name="sticky note outline" size="huge" color="orange" />
                <Card.Header style={{ marginTop: "5px", marginBottom: "10px" }}>
                  Notes
                </Card.Header>
              </Card.Content>
            </Card>
          </Link>
        </Grid.Column>
        <Grid.Column width={8}>
          <Link to="/meetup">
            <Card fluid raised color="orange" style={{ marginBottom: "40px" }}>
              <Card.Content textAlign="center">
                <Icon name="bullhorn" size="huge" color="orange" />
                <Card.Header style={{ marginTop: "5px", marginBottom: "10px" }}>
                  meetup
                </Card.Header>
              </Card.Content>
            </Card>
          </Link>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );

  // const panes = [
  //   {
  //     menuItem: "Ma Bibliotheque",
  //     render: () => (
  //       <Tab.Pane attached={false}>
  //         <AccordionExampleShorthand />
  //       </Tab.Pane>
  //     ),
  //   },
  //   {
  //     menuItem: "Titrologue 2",
  //     render: () => <Tab.Pane attached={false}>Titrologue</Tab.Pane>,
  //   },
  //   {
  //     menuItem: "Note de lecture",
  //     render: () => <Tab.Pane attached={false}>Note de lecture</Tab.Pane>,
  //   },
  // ];

  // const TabExampleSecondary = () => (
  //   <Tab menu={{ secondary: true }} panes={panes} />
  // );

  // const localStoreNumber = () => {
  //   const phone = localStorage.getItem("ivoireBookPhone");
  //   if (phone) {
  //     return "votre numero detelephone est deja enregistre";
  //   } else {
  //     return (
  //       <div>
  //           <form onSubmit={this.mySubmitHandler}>
  //             <h1>Hello {this.state.userTel}</h1>
  //             <p>votre numero de telephone pour vos livraisons:</p>
  //             <NumberFormat
  //               label={{ icon: "phone" }}
  //               placeholder="numero de telephone pour la livraison"
  //               customInput={Input}
  //               onChange={this.myChangeHandler}
  //               value={this.state.card}
  //               format="+225 ## ## ## ##"
  //             />
  //             {/* <input type="text" onChange={this.myChangeHandler} /> */}
  //             <input type="submit" />
  //           </form>
  //       </div>
  //     );
  //   }
  // };
  return (
    <div>
      <Container>
        <Header textAlign="center" as="h2" color="orange">
          IvoireBook
        </Header>

        <BreadcrumbExample />

        <Grid columns="equal" style={{ marginTop: "10px" }}>
          <Grid.Row>
            <Grid.Column width={4}>
              <Image alt="profile picture" src={userImage} />
              <h4>{userName}</h4>
              <br />
              {/* {localStoreNumber()} */}
              <br />
              <a href onClick={() => deleteConection()}>
                Deconnexion
              </a>
              <br />
            </Grid.Column>

            <Grid.Column>
              <Grid columns={2}>
                <Grid.Row stretched>
                  <Grid.Column>
                    <Image
                      style={{
                        height: "280px"
                      }}
                      alt="profile picture"
                      src="https://cdn.pixabay.com/photo/2016/11/30/12/16/question-mark-1872665_1280.jpg"
                    />
                  </Grid.Column>

                  <Grid.Column>
                    <Segment
                      style={{
                        height: "280px"
                      }}
                      raised
                    >
                      <Stats />
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Grid.Row style={{ marginTop: "15px" }}>
                {/* <TabExampleSecondary /> */}
                <GridExampleColumns />
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  );
}

export default computerProfil;
