import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Image, Responsive } from "semantic-ui-react";
import "firebase/firestore";
import firebase from "firebase";
import { useHistory } from "react-router-dom";
import slugify from "slugify";
import NumberFormat from "react-number-format";

import Placeholder from "../placeholder";

const db = firebase.firestore();

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    />
  );
}

function SliderView() {
  let history = useHistory();
  const [data, setData] = useState(null);

  useEffect(() => {
    db.collection("livres")
      .where("status", "==", "best-seller")
      .get()
      .then((querySnapshot) => {
        const values = querySnapshot.docs.map((doc) => doc.data());
        setData(values);
      });
    // .catch(function (error) {
    //   console.log("Error getting documents: ", error);
    // });
  }, []);

  const settings = {
    slidesToShow: 3,
    slidesToScroll: 2,
    autoplay: true,
    speed: 4000
  };

  const settings2 = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    dots: true,
    autoplaySpeed: 5000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  const selected = (titre, ref) => {
    const slugifyUrl = slugify(titre);
    history.push(`/livres/${slugifyUrl}/${ref}`);
  };

  const MobileView = () => (
    <div style={{ padding: "7px 0px" }}>
      {data ? (
        <Slider {...settings}>
          {data.map((value) => (
            <div
              key={value.titre}
              onClick={() => selected(value.titre, value.ref)}
            >
              <Image
                style={{
                  height: "200px",
                  padding: "0px 3px"
                }}
                rounded
                src={value.image}
              />
              <NumberFormat
                value={value.price2}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                allowNegative={false}
                suffix={" Fcfa"}
                renderText={(value) => (
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      marginLeft: "25%"
                    }}
                  >
                    {value}
                  </div>
                )}
              />
              {/* <p style={{ fontWeight: "bold", marginLeft: "25%" }}>
                {value.price2} fcfa
              </p> */}
            </div>
          ))}
        </Slider>
      ) : (
        <Placeholder />
      )}
    </div>
  );

  const LargeScreenView = () => (
    <div style={{ padding: "7px 0px" }}>
      {data ? (
        <Slider {...settings2}>
          {data.map((value) => (
            <div
              key={value.titre}
              onClick={() => selected(value.titre, value.ref)}
            >
              <Image
                style={{
                  height: "250px",
                  width: "180px",
                  padding: "0px 2px",
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                }}
                src={value.image}
              />
              <p style={{ fontWeight: "bold", marginLeft: "25%" }}>
                {value.price2} fcfa
              </p>
            </div>
          ))}
        </Slider>
      ) : (
        <Placeholder />
      )}
    </div>
  );

  return (
    <div>
      <Responsive {...Responsive.onlyMobile}>
        <MobileView />
      </Responsive>

      <Responsive minWidth={768}>
        <LargeScreenView />
      </Responsive>
    </div>
  );
}

export default SliderView;
