// // Import FirebaseAuth and firebase.
// import React from "react";
// import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
// import firebase from "firebase";
// import "firebase/firestore";
// // import fireStore from "firebase/firestore"
// import {
//   Container,
//   Responsive,
//   Image,
//   Grid,
// } from "semantic-ui-react";
// // import NumberFormat from "react-number-format";
// // import { Link } from "react-router-dom";

// import BigMenu from "../components/headerMenu";
// import MobileProfil from "../components/userProfile/mobileProfil"
// import ComputerProfil from "../components/userProfile/computerProfil"
// import config from "../config/firebase"

// // Configure Firebase.
// // const config = {
// //   apiKey: "AIzaSyCF-iL-Vr-2qpDHvXbJV6wvkcdbEiodkdw",
// //   authDomain: "ivoire-book.firebaseapp.com",
// //   databaseURL: "https://ivoire-book.firebaseio.com",
// //   projectId: "ivoire-book",
// //   storageBucket: "ivoire-book.appspot.com",
// //   messagingSenderId: "115146406016",
// //   appId: "1:115146406016:web:4c8ec27aecb6244a084e65",
// //   measurementId: "G-YQM3X8349L",
// // };
// firebase.initializeApp(config);
// const db = firebase.firestore();
// // console.log(db)

// // const getBiblio = () => {
// //   db.collection("commandes")
// //   .where("owner", "==", "Abdel Kader Drabo")
// //   .get()
// //   .then((querySnapshot) => {
// //     const values = querySnapshot.docs.map((doc) => doc.data());
// //     // setData(values);
// //     return values
// //   })
// //   .catch(function (error) {
// //     console.log(error);
// //   });
// // };
// class SignInScreen extends React.Component {
//   // The component's Local state
//   constructor(props) {
//     super(props);
//     this.state = {
//       isSignedIn: false, // Local signed-in state.
//       userTel: "",
//       inputEnd: false,
//     };
//   }

//   // Configure FirebaseUI.
//   uiConfig = {
//     // Popup signin flow rather than redirect flow.
//     signInFlow: "popup",
//     // We will display Google and Facebook as auth providers.
//     signInOptions: [
//       firebase.auth.GoogleAuthProvider.PROVIDER_ID,
//       firebase.auth.FacebookAuthProvider.PROVIDER_ID,
//     ],
//     callbacks: {
//       // Avoid redirects after sign-in.
//       signInSuccessWithAuthResult: () => false,
//     },
//   };

//   // Listen to the Firebase Auth state and set the local state.
//   componentDidMount() {
//     this.unregisterAuthObserver = firebase
//       .auth()
//       .onAuthStateChanged((user) => this.setState({ isSignedIn: !!user }));
//   }

//   // Make sure we un-register Firebase observers when the component unmounts.
//   componentWillUnmount() {
//     this.unregisterAuthObserver();
//   }

//   mySubmitHandler = (event) => {
//     event.preventDefault();
//     localStorage.setItem("ivoireBookPhone", this.state.userTel);
//     this.setState({ inputEnd: true });

//     console.log(this.state.userTel);
//   };

//   myChangeHandler = (event) => {
//     this.setState({ userTel: event.target.value });
//   };

//   render() {
//     const StateStatus = typeof this.state === 'undefined';
//     const signedIn = this.state.isSignedIn

//     if (!signedIn || StateStatus) {
//       return (
//         <div>
//           <Responsive {...Responsive.onlyMobile}>
//             <Container>
//               <BigMenu />
//               <h1>Mon Compte </h1>
//               <p>Connexion via :</p>
//               <div
//                 style={{
//                   position: "absolute",
//                   top: "35%",
//                   right: "15%",
//                   zIndex: 1,
//                 }}
//               >
//                 <StyledFirebaseAuth
//                   uiConfig={this.uiConfig}
//                   firebaseAuth={firebase.auth()}
//                 />
//               </div>

//               <Image
//                 src="https://cdn.pixabay.com/photo/2013/07/13/10/49/book-157851_960_720.png"
//                 fluid
//                 style={{ marginTop: "25%" }}
//               />
//             </Container>
//           </Responsive>
//           <Responsive minWidth={768}>
//             <Container>
//               <div
//                 style={{
//                   position: "fixed",
//                   top: "30%",
//                   right: "27%",
//                   zIndex: 1,
//                 }}
//               >
//                 <Grid columns="equal" divided padded>
//                   <Grid.Row textAlign="center">
//                     <Grid.Column>
//                       <p>
//                         connecter vous pour beneficier de plus d obtion et
//                         avantage
//                       </p>
//                     </Grid.Column>
//                     <Grid.Column>
//                       <div
//                       // style={{ position: "fixed", top: "30%", right: "20%" }}
//                       >
//                         <h1>Mon Compte </h1>
//                         <p>Connexion via :</p>
//                         <StyledFirebaseAuth
//                           uiConfig={this.uiConfig}
//                           firebaseAuth={firebase.auth()}
//                         />
//                       </div>
//                     </Grid.Column>
//                   </Grid.Row>
//                 </Grid>
//               </div>
//               <Image
//                 src="https://cdn.pixabay.com/photo/2013/07/13/10/49/book-157851_960_720.png"
//                 fluid
//               />
//             </Container>
//           </Responsive>
//         </div>
//       );
//     } else {
//       return (
//         <>
//           <Responsive {...Responsive.onlyMobile}>
//             <MobileProfil db={db} />
//           </Responsive>
//           <Responsive minWidth={768}>
//             <ComputerProfil db={db}  />
//           </Responsive>
//         </>
//       );
//     }
//   }
// }

// export default SignInScreen;

// Import FirebaseAuth and firebase.
import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase";
import { Redirect } from "react-router-dom";
import { Container, Responsive, Image, Grid, Segment } from "semantic-ui-react";

import config from "../config/firebase";
import BigMenu from "../components/headerMenu";

// Configure Firebase.
firebase.initializeApp(config);

class SignInScreen extends React.Component {
  // The component's Local state.
  state = {
    isSignedIn: false // Local signed-in state.
  };

  // Configure FirebaseUI.
  uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false
    }
  };

  // Listen to the Firebase Auth state and set the local state.
  componentDidMount() {
    this.unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => this.setState({ isSignedIn: !!user }));
  }

  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    if (!this.state.isSignedIn) {
      return (
        <div>
          <Responsive {...Responsive.onlyMobile}>
            <Container>
              <BigMenu />
              <h1>Mon Compte </h1>
              <p>Connexion via :</p>
              <div
                style={{
                  position: "absolute",
                  top: "35%",
                  right: "15%",
                  zIndex: 1
                }}
              >
                <StyledFirebaseAuth
                  uiConfig={this.uiConfig}
                  firebaseAuth={firebase.auth()}
                />
              </div>

              <Image
                src="https://cdn.pixabay.com/photo/2013/07/13/10/49/book-157851_960_720.png"
                fluid
                style={{ marginTop: "25%" }}
              />
            </Container>
          </Responsive>
          <Responsive minWidth={768}>
            <Container>
              <div
                style={{
                  position: "fixed",
                  top: "30%",
                  right: "13%",
                  zIndex: 1
                }}
              >
                <Grid columns="equal" divided padded>
                  <Grid.Row textAlign="center">
                    <Grid.Column>
                      <Segment textAlign="center" color="red">
                        <p style={{ fontSize: "20px", fontStyle: "oblique" }}>
                          Connectez vous et bénéficier de livres pdf gratuit, de
                          bon de réduction, et bien d' autre surprise
                        </p>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column>
                      <div
                      // style={{ position: "fixed", top: "30%", right: "20%" }}
                      >
                        <h1>Mon Compte </h1>
                        <p>Connexion via :</p>
                        <StyledFirebaseAuth
                          uiConfig={this.uiConfig}
                          firebaseAuth={firebase.auth()}
                        />
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
              <Image
                src="https://cdn.pixabay.com/photo/2013/07/13/10/49/book-157851_960_720.png"
                fluid
              />
            </Container>
          </Responsive>
        </div>
      );
    }
    return (
      <Redirect to={"/profil"} />
      // <div>
      //   <h1>My App</h1>
      //   <p>Welcome {firebase.auth().currentUser.displayName}! You are now signed-in!</p>
      //   <p onClick={() => firebase.auth().signOut()}>Sign-out</p>
      // </div>
    );
  }
}

export default SignInScreen;
