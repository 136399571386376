import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Image,
  Segment,
  Grid,
  Tab,
  Table,
  Placeholder,
  Container
} from "semantic-ui-react";
import Markdown from "markdown-to-jsx";
import firebase from "firebase";
import "firebase/firestore";
import { useParams } from "react-router-dom";
import NumberFormat from "react-number-format";
import slugify from "slugify";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { FacebookIcon, TwitterIcon } from "react-share";
import { FacebookProvider, Like } from "react-facebook";

// import Mobileheader from "../headerMenu/mobile";
import { AddMarketButton } from "../ajouterAupanier";
import GetBookCathegorie from "../getBookCathegorie";

const db = firebase.firestore();

const PlaceholderExampleHeaderImage = () => (
  <Placeholder>
    <Placeholder>
      <Placeholder.Header image>
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Header>
      <Placeholder.Paragraph>
        <Placeholder.Line length="medium" />
        <Placeholder.Line length="short" />
      </Placeholder.Paragraph>
    </Placeholder>
  </Placeholder>
);

function book() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let { ref } = useParams();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [data, setData] = useState(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const livre = (id) => db.doc(`livres/${id}`);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    livre(ref)
      .get()
      .then((doc) => {
        if (doc && doc.exists) {
          const myData = doc.data();
          setData(myData);
          document.title = `livre : ${myData.titre} | auteur : ${myData.auteur}`;
        }
      });

    // .catch((error) => console.log(error));
  }, [ref]);

  const titleF = data ? `#${slugify(data.titre, "_")}` : "#ivoirebook";

  const Detail = () => (
    <Table unstackable>
      <Table.Body>
        <Table.Row>
          <Table.Cell collapsing>Date de parution</Table.Cell>
          <Table.Cell textAlign="right">{data.parution}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Editeur</Table.Cell>
          <Table.Cell textAlign="right">{data.editeur}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Dimensions</Table.Cell>
          <Table.Cell textAlign="right">{data.format}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell collapsing>Nombre de pages</Table.Cell>
          <Table.Cell textAlign="right">{data.pages}</Table.Cell>
        </Table.Row>
        {data.intro ? (
          <Table.Row>
            <Table.Cell collapsing>Extrait</Table.Cell>
            <Table.Cell textAlign="right">
              <a href={data.intro} target="_blank" rel="noopener noreferrer">
                telecharger PDF
              </a>
            </Table.Cell>
          </Table.Row>
        ) : (
          ""
        )}
      </Table.Body>
    </Table>
  );
  const panes = [
    {
      menuItem: { key: "1", icon: "book", content: "Résumé" },
      render: () => (
        <Tab.Pane attached={false}>
          <Container text>
            <Markdown style={{ fontSize: "15px", fontStyle: "italic" }}>
              {`${data.resumer}`}
            </Markdown>
          </Container>
        </Tab.Pane>
      )
    },
    {
      menuItem: { key: "2", icon: "list ul", content: "Détails" },
      render: () => (
        <Tab.Pane attached={false}>
          <Detail />
        </Tab.Pane>
      )
    },
    {
      menuItem: { key: "3", icon: "users", content: "recommender" },
      render: () => (
        <Tab.Pane attached={false}>
          <GetBookCathegorie cathego={data.cathegorie} />
        </Tab.Pane>
      )
    }
  ];

  const DetailTab = () => (
    <Tab menu={{ color: "orange", secondary: true }} panes={panes} />
  );

  const Share = () => (
    <div style={{ position: "relative", top: "-70px", left: "-50px" }}>
      <FacebookShareButton
        url={"https://ivoire-book.com/"}
        quote={data ? data.resumer : "a lire absolument"}
        hashtag={titleF}
        description={"ivoire-book"}
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <br />
      <TwitterShareButton
        title={"test"}
        url={"https://ivoire-book.com/"}
        hashtags={["ivoireBook", "titleF"]}
      >
        <TwitterIcon size={32} round />
      </TwitterShareButton>
    </div>
  );

  return (
    <Container>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data ? data.titre : ""}</title>
        <meta
          name="description"
          content={data ? data.resumer.substring(0, 500) : ""}
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div
        style={{
          paddingBottom: "50px",
          marginTop: "20px"
        }}
      >
        <Grid columns="equal">
          <Grid.Column width={5}>
            {data ? (
              <div>
                <Image
                  fluid
                  alt={`couverture ${data.title} de ${data.auteur}`}
                  style={{
                    height: "400px",
                    width: "80%",
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                  }}
                  src={data.image}
                />
                <Share />
              </div>
            ) : (
              <Placeholder style={{ height: "400px" }}>
                <Placeholder.Image />
              </Placeholder>
            )}
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Segment size="mini" color="orange" raised secondary>
              {data ? (
                <Grid>
                  <Grid.Column floated="left" width={3}>
                    {data.auteurImage ? (
                      <Image
                        alt={`auteur ${data.auteur}`}
                        avatar
                        src={data.auteurImage}
                        size="large"
                      />
                    ) : (
                      ""
                    )}
                  </Grid.Column>
                  <Grid.Column
                    style={{ padding: "14px 0px" }}
                    textAlign="center"
                    verticalAlign="middle"
                    width={9}
                  >
                    {data ? (
                      <h1 style={{ fontSize: "20px" }}>{data.titre}</h1>
                    ) : (
                      ""
                    )}
                    {data ? (
                      <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                        {" "}
                        Auteur : {data.auteur}
                      </p>
                    ) : (
                      ""
                    )}
                  </Grid.Column>
                  <Grid.Column
                    style={{ padding: "14px 5px" }}
                    textAlign="center"
                    verticalAlign="middle"
                    floated="right"
                    width={4}
                  >
                    {data ? (
                      <NumberFormat
                        value={data.price2}
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        allowNegative={false}
                        suffix={" Fcfa"}
                        renderText={(value) => (
                          <div style={{ fontWeight: "bold", fontSize: "22px" }}>
                            {value}
                          </div>
                        )}
                      />
                    ) : (
                      ""
                    )}
                  </Grid.Column>
                </Grid>
              ) : (
                <PlaceholderExampleHeaderImage />
              )}
              {/* <div style={{ marginTop: "10px" }}>
                {data ? <AddMarketButton market={data} /> : ""}
              </div> */}
            </Segment>
            <div style={{ marginTop: "30px" }}>
              {data ? <AddMarketButton market={data} /> : ""}
            </div>
          </Grid.Column>
          <Grid.Column width={4}>
            <Segment>
              <p>
                {" "}
                <span role="img" aria-label="Rapide">
                  🛵
                </span>{" "}
                Livraison rapide
              </p>
              <p>
                {" "}
                <span role="img" aria-label="Panda">
                  🧑🏾‍🤝‍🧑🏾
                </span>{" "}
                Paiement à la livraison
              </p>
              <p>
                <span role="img" aria-label="Panda">
                  {" "}
                  ✔️
                </span>{" "}
                retour accepté sous 48 heurs
              </p>
            </Segment>
          </Grid.Column>
        </Grid>

        <FacebookProvider appId="320050232708180">
          <Like
            href="https://ivoire-book.com/"
            colorScheme="dark"
            showFaces
            share
          />
        </FacebookProvider>

        <div style={{ marginTop: "20px" }}>{data ? <DetailTab /> : ""}</div>
      </div>
    </Container>
  );
}

export default book;
