import React from "react";
import { Container, Segment, Grid, Icon } from "semantic-ui-react";

import NavBar from "./navBar";
import HomeQuery from "./homeQuery";
import CathegorieView from "../cathegorieView"

function index() {
  const SegmentView = () => (
    <Segment secondary raised>
      <Grid columns={4} divided>
        <Grid.Row>
          <Grid.Column>
            <Icon name="shipping fast" size="large" />
            livraison sous 48 heures <br />
            Commande disponible en 48h max...
          </Grid.Column>
          <Grid.Column>
            <Icon name="handshake outline" size="large" />
            Payez à la livraison <br />
            cash ou via Mobile Money.
          </Grid.Column>
          <Grid.Column>
            <Icon name="redo" size="large" />
            retour accepter <br /> sans frais supplémentaire.
          </Grid.Column>
          <Grid.Column>
            <Icon name="phone" size="large" />
            service client 07 07 81 52 01
            <br />
            disponible 24/7h
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
  return (
    <Container>
      <NavBar />
      <SegmentView />
      <HomeQuery />
      <CathegorieView segmented={3} />
    </Container>
  );
}

export default index;
