import React, { useState, useEffect } from "react";
import {
  Header,
  Icon,
  Grid,
  Image,
  Placeholder,
  Label,
} from "semantic-ui-react";
import { useHistory, Link } from "react-router-dom";
import slugify from "slugify";
import "firebase/firestore";
import firebase from "firebase";
import NumberFormat from 'react-number-format';


import AllBookSlider from "../slider/allBookSlider";

const db = firebase.firestore();

function homeQuery() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let history = useHistory();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [data, setData] = useState(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [networkError, setnetworkError] = useState(null);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    db.collection("livres")
      .orderBy("update", "desc")
      .limit(12)
      .get()
      .then((querySnapshot) => {
        const values = querySnapshot.docs.map((doc) => doc.data());
        setData(values);
      })
      .catch(function (error) {
        setnetworkError(error);
      });
  }, [networkError]);

  const selected = (titre, ref) => {
    const slugifyUrl = slugify(titre);
    history.push(`/livres/${slugifyUrl}/${ref}`);
  };

  return (
    <div style={{ marginBottom: "30px" }}>
      <Grid>
        <Grid.Column style={{ paddingTop: "17px" }} floated="left" width={11}>
          <Header as="h2" style={{ fontSize: "15px" }}>
          <span role="img" aria-label="Best">🏅</span>
            <Header.Content>Best sellers</Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column
          style={{ paddingTop: "17px" }}
          textAlign="right"
          floated="right"
          width={5}
        >
          <div>
            <Link to="catalogue/best-seller">
              voir plus <Icon name="angle double right" color="blue" />
            </Link>
          </div>
        </Grid.Column>
      </Grid>

      {networkError && "verifier votre connection internet"}

      <AllBookSlider />

      <Grid>
        <Grid.Column style={{ paddingTop: "30px" }} floated="left" width={11}>
          <Header as="h2" style={{ fontSize: "15px" }}>
          <span role="img" aria-label="New-Book">📚</span>
            <Header.Content>News</Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column
          style={{ paddingTop: "30px" }}
          textAlign="right"
          floated="right"
          width={5}
        >
          <div>
            <Link to="/recherche-livres">
              voir plus <Icon name="angle double right" color="blue" />
            </Link>
          </div>
        </Grid.Column>
      </Grid>

      <Grid columns={4} padded="vertically">
        {data ? (
          data.map((livre) => (
            <Grid.Column
              key={livre.ref}
              width={4}
              textAlign="center"
              onClick={() => selected(livre.titre, livre.ref)}
            >
              {livre.rupture ? (
                <Label
                  color="red"
                  tag
                  style={{
                    position: "absolute",
                    top: "5%",
                    right: "30%",
                    zIndex: 1,
                  }}
                >
                  <Icon name="exclamation circle" /> ruptute
                </Label>
              ) : (
                ""
              )}

              <Image
                style={{
                  cursor: "pointer",
                  height: "320px",
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                }}
                src={livre.image}
              />
              <NumberFormat
                      value={livre.price2}
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      allowNegative={false}
                      suffix={" Fcfa"}
                      renderText={(value) => (
                        <div style={{ fontWeight: "bold", fontSize: "22px" }}>{value}</div>
                      )}
                    />

            </Grid.Column>
          ))
        ) : (
          <>
            <Grid.Column width={4} textAlign="center">
              <Placeholder style={{ height: 280, width: 250 }}>
                <Placeholder.Image />
              </Placeholder>
            </Grid.Column>
            <Grid.Column width={4} textAlign="center">
              <Placeholder style={{ height: 280, width: 250 }}>
                <Placeholder.Image />
              </Placeholder>
            </Grid.Column>
            <Grid.Column width={4} textAlign="center">
              <Placeholder style={{ height: 280, width: 250 }}>
                <Placeholder.Image />
              </Placeholder>
            </Grid.Column>
            <Grid.Column width={4} textAlign="center">
              <Placeholder style={{ height: 280, width: 250 }}>
                <Placeholder.Image />
              </Placeholder>
            </Grid.Column>
          </>
        )}
      </Grid>

      {/* <Grid>
        <Grid.Column style={{ paddingTop: "10px" }} floated="left" width={11}>
          <Header as="h2" style={{ fontSize: "15px" }}>
            <Icon name="address book outline" color="blue" />
            <Header.Content>Auteurs</Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column
          style={{ paddingTop: "10px" }}
          textAlign="right"
          floated="right"
          width={5}
        >
          <div>
          <Link to="/recherche-livres">
              voir plus <Icon name="angle double right" color="blue" />
            </Link>
          </div>
        </Grid.Column>
      </Grid> */}
    </div>
  );
}

export default homeQuery;
