import React from "react";
import {
  Container,
  Image,
  Grid,
  Card,
  Icon,
  Dropdown
} from "semantic-ui-react";
import { Link } from "react-router-dom";
// import { useHistory } from "react-router-dom";
// import NumberFormat from "react-number-format";
// import slugify from "slugify";
// import moment from "moment";

// import SimpleHeader from "../headerMenu/simpleHeader";

import firebase from "firebase";

function mobileProfil() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // let history = useHistory();

  const userName = firebase.auth().currentUser
    ? firebase.auth().currentUser.displayName
    : false;

  const userImage = firebase.auth().currentUser
    ? firebase.auth().currentUser.photoURL
    : false;
  // const userImage = firebase.auth().currentUser.photoURL;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const [data, setData] = useState([]);

  // eslint-disable-next-line react-hooks/rules-of-hooks

  // useEffect(() => {
  //   props.db
  //     .collection("commandes")
  //     .where("owner", "==", userName)
  //     .get()
  //     .then((querySnapshot) => {
  //       const values = querySnapshot.docs.map((doc) => doc.data());
  //       setData(values);
  //     });
  // }, [props.db, userName]);

  // const selected = (titre, ref) => {
  //   const slugifyUrl = slugify(titre);
  //   history.push(`/livres/${slugifyUrl}/${ref}`);
  // };

  // const panels = data.map((commande) => ({
  //   key: `panel-${commande.created}`,
  //   title: {
  //     content: (
  //       <Label color="blue">
  //         {moment(commande.created.toDate(), "YYYYMMDD").fromNow()}
  //         <Label.Detail>
  //           {commande.envoyer ? "deja livre" : "pas encore livree"}
  //         </Label.Detail>
  //       </Label>
  //     ),
  //   },
  //   content: {
  //     content: (
  //       <div>
  //         <Grid columns={2} padded="vertically">
  //           {commande.liste.map((livre) => (
  //             <Grid.Column
  //               key={livre.ref}
  //               width={8}
  //               textAlign="center"
  //               onClick={() => selected(livre.titre, livre.ref)}
  //             >
  //               <Image
  //                 style={{ height: "150px", width: "100%" }}
  //                 rounded
  //                 src={livre.image}
  //               />
  //               <h4 style={{ fontWeight: "bold" }}> {livre.titre}</h4>
  //             </Grid.Column>
  //           ))}
  //         </Grid>
  //       </div>
  //     ),
  //   },
  // }));

  // const AccordionExampleShorthand = () => <Accordion panels={panels} />;

  // const Biblotheque = () => (
  //   <div>
  //     <p>liste de livre pdf a telecharger</p>
  //     <p>liste des livre acheter</p>
  //     <AccordionExampleShorthand />
  //   </div>
  // );

  // const Titrologie = () => (
  //   <div>
  //     <p>revue de presse titrologie</p>
  //     <p>metup</p>
  //   </div>
  // );

  // const Note = () => (
  //   <div>
  //     <p>revue de presse titrologie</p>
  //   </div>
  // );

  // const panes = [
  //   {
  //     menuItem: "Ma Biblotheque",
  //     render: () => (
  //       <Tab.Pane attached={false}>
  //         <Biblotheque />
  //       </Tab.Pane>
  //     ),
  //   },
  //   {
  //     menuItem: "Titrologie",
  //     render: () => (
  //       <Tab.Pane attached={false}>
  //         <Titrologie />
  //       </Tab.Pane>
  //     ),
  //   },
  //   {
  //     menuItem: "note",
  //     render: () => (
  //       <Tab.Pane attached={false}>
  //         <Note />
  //       </Tab.Pane>
  //     ),
  //   },
  // ];

  // const TabExampleSecondary = () => (
  //   <Tab menu={{ secondary: true }} panes={panes} />
  // );

  // const deleteConection = () => {
  //   firebase.auth().signOut();
  //   history.push("/");
  // };

  // const DropdownImageTriggerExample = () => (
  //   <Dropdown
  //     trigger={
  //       <span>
  //         <Image avatar src={faker.internet.avatar()} />
  //       </span>
  //     }
  //     options={options}
  //     pointing="top left"
  //     icon={null}
  //   />
  // );

  const deleteConection = () => {
    firebase.auth().signOut();
    document.location.href = "/";
  };

  const GridExampleColumns = () => (
    <Grid style={{ marginTop: "20px" }}>
      <Grid.Row>
        <Grid.Column width={8}>
          <Link to="/BookGameHome">
            <Card raised color="orange">
              <Card.Content textAlign="center">
                <Icon name="game" size="huge" color="orange" />
                <Card.Header style={{ marginTop: "5px", marginBottom: "10px" }}>
                  Game-Book
                </Card.Header>
              </Card.Content>
            </Card>
          </Link>
        </Grid.Column>

        <Grid.Column width={8}>
          <Link to="/stats">
            <Card raised color="orange">
              <Card.Content textAlign="center">
                <Icon name="chart line" size="huge" color="orange" />
                <Card.Header style={{ marginTop: "5px", marginBottom: "10px" }}>
                  Mes stats
                </Card.Header>
              </Card.Content>
            </Card>
          </Link>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={8}>
          <Link to="/notes">
            <Card raised color="orange">
              <Card.Content textAlign="center">
                <Icon name="sticky note outline" size="huge" color="orange" />
                <Card.Header style={{ marginTop: "5px", marginBottom: "10px" }}>
                  Notes
                </Card.Header>
              </Card.Content>
            </Card>
          </Link>
        </Grid.Column>
        <Grid.Column width={8}>
          <Link to="/meetup">
            <Card raised color="orange" style={{ marginBottom: "40px" }}>
              <Card.Content textAlign="center">
                <Icon name="bullhorn" size="huge" color="orange" />
                <Card.Header style={{ marginTop: "5px", marginBottom: "10px" }}>
                  meetup
                </Card.Header>
              </Card.Content>
            </Card>
          </Link>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );

  // const localStoreNumber = () => {
  //   const phone = localStorage.getItem("ivoireBookPhone");
  //   if (phone) {
  //     return "votre numero detelephone est deja enregistre";
  //   } else {
  //     return (
  //       <div>
  //           <form onSubmit={this.mySubmitHandler}>
  //             <h1>Hello {this.state.userTel}</h1>
  //             <p>votre numero de telephone pour vos livraisons:</p>
  //             <NumberFormat
  //               label={{ icon: "phone" }}
  //               placeholder="numero de telephone pour la livraison"
  //               customInput={Input}
  //               onChange={this.myChangeHandler}
  //               value={this.state.card}
  //               format="+225 ## ## ## ##"
  //             />
  //             {/* <input type="text" onChange={this.myChangeHandler} /> */}
  //             <input type="submit" />
  //           </form>
  //       </div>
  //     );
  //   }
  // };

  return (
    <>
      {/* <div
        style={{
          background: "gold",
          height: "180px",
          border: "gold",
          borderRadius: "0% 0% 30% 30%",
          boxShadow: "6px 3px 5px red",
          Zindex: 1
        }}
      > */}
      <Image
        alt="sapologue_abidjan"
        style={{
          height: "180px",
          width: "100%",
          borderRadius: "0% 0% 10% 10%",
          boxShadow: "6px 3px 5px #969696"
        }}
        src="https://cdn.pixabay.com/photo/2018/01/12/10/19/fantasy-3077928_1280.jpg"
      />
      {/* </div> */}
      {/* <Image
        style={{
          position: "absolute",
          top: "16%",
          right: "38%",
          height: "70px",
          width: "70px",
        }}
        alt="profile picture"
        src={userImage}
        circular
      /> */}
      <Dropdown
        style={{
          position: "absolute",
          top: "16%",
          right: "38%",
          height: "70px",
          width: "70px"
        }}
        trigger={<Image alt="profile picture" src={userImage} circular />}
        pointing="top left"
        icon={null}
      >
        <Dropdown.Menu>
          <Dropdown.Item
            icon="sign out"
            onClick={() => deleteConection()}
            text="Deconnexion"
          />
        </Dropdown.Menu>
      </Dropdown>

      <Container>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <h1
            style={{
              fontSize: "22px",
              fontStyle: "italic",
              fontWeight: "bold"
            }}
          >
            {userName}
          </h1>
        </div>

        <GridExampleColumns />
        {/* <TabExampleSecondary /> */}

        {/* <a href onClick={() => deleteConection()}>
          Deconnexion
        </a>
        <br /> */}

        {/* {localStoreNumber()} */}
        {/* <br />
        <Link to="/">Retour a l acceille</Link> */}
      </Container>
    </>
  );
}

export default mobileProfil;
