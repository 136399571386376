import React, { useEffect, useState } from "react";
import {
  Container,
  Responsive,
  Grid,
  Placeholder,
  Image,
  Divider,
  Header,
  Icon,
  Segment
} from "semantic-ui-react";
import { Helmet } from "react-helmet";

import BigMenu from "../components/headerMenu";
import FooterMenu from "../components/fotterMenu";
import Stats from "../components/stat";

import "firebase/firestore";
import firebase from "firebase";

const db = firebase.firestore();

function ClassRoom() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [data, setData] = useState(null);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const user = firebase.auth().currentUser;
    const email = user.email;

    db.collection("bages")
      .where("owner", "==", email)
      .get()
      .then((querySnapshot) => {
        const values = querySnapshot.docs.map((doc) => doc.data());
        setData(values);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const BageView = ({ Column }) => (
    <Grid columns={Column} padded="vertically">
      {data ? (
        data.map((bage) => (
          <Grid.Column key={bage.titre} textAlign="center">
            <Image
              style={{
                height: "220px",
                width: "100%",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
              }}
              src={bage.bage}
            />
            <h3>{bage.titre}</h3>
          </Grid.Column>
        ))
      ) : (
        <>
          <Grid.Column width={8} textAlign="center">
            <Placeholder style={{ height: 180, width: 150 }}>
              <Placeholder.Image />
            </Placeholder>
          </Grid.Column>
          <Grid.Column width={8} textAlign="center">
            <Placeholder style={{ height: 180, width: 150 }}>
              <Placeholder.Image />
            </Placeholder>
          </Grid.Column>
        </>
      )}
    </Grid>
  );

  const DividerView = () => (
    <Divider horizontal>
      <Header as="h4">
        <Icon name="shield alternate" color="orange" />
        Mes compétences obtenues
      </Header>
    </Divider>
  );

  return (
    <div>
      <Helmet>
        <title>ClassRoom | Ivoire-Book </title>
      </Helmet>
      <Responsive {...Responsive.onlyMobile}>
        <Container>
          <BigMenu />
          {/* <Segment placeholder>
              <Header icon>
                <Icon loading size="massive" name="cog" />
                page en developement ! bientôt disponible.
              </Header>
            </Segment> */}
          <Stats />
          <DividerView />
          {data ? (
            <Segment inverted>
              executer des quetes pur obtenir des competence
            </Segment>
          ) : (
            <BageView Column="2" />
          )}
        </Container>
        <FooterMenu />
      </Responsive>
      <Responsive minWidth={768}>
        <BigMenu />
        <Container textAlign="center">
          {/* <Segment style={{ marginTop: "40px" }} placeholder>
              <Header icon>
                <Icon loading size="massive" name="cog" />
                page en developement ! bientôt disponible.
              </Header>
            </Segment> */}
          <Stats />

          <DividerView />
          {data ? (
            <Segment inverted>
              executer des quetes pur obtenir des competence
            </Segment>
          ) : (
            <BageView Column="5" />
          )}
        </Container>
      </Responsive>
    </div>
  );
}

export default ClassRoom;
