const initialeState = [];

export const ADD_MARKET_ACTION = "ADD_MARKET_ACTION";
export const UPDATE_MARKET_ACTION = "UPDATE_MARKET_ACTION";
export const DELETE_MARKET_ACTION = "DELETE_MARKET_ACTION";
export const DELETE_ALL_MARKET_ACTION = "DELETE_ALL_MARKET_ACTION";

export function marketReducer(state = initialeState, action) {
  switch (action.type) {
    case ADD_MARKET_ACTION:
      return [...state, { ...action.payload }];
    case UPDATE_MARKET_ACTION:
      return state.map((market) => {
        if (market.id === action.payload.id) {
          return { ...market, ...action.payload };
        } else {
          return market;
        }
      });
    case DELETE_MARKET_ACTION:
      return state.filter((market) => market.ref !== action.payload);
    case DELETE_ALL_MARKET_ACTION:
      return initialeState;
    default:
      return state;
  }
}
