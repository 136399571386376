import React, { Component } from "react";
import {
  Container,
  Responsive,
  Icon,
  Segment,
  Header
} from "semantic-ui-react";
import { Helmet } from "react-helmet";

import BigMenu from "../components/headerMenu";
import FooterMenu from "../components/fotterMenu";

export default class Meetup extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Meetup | Ivoire-Book </title>
        </Helmet>
        <Responsive {...Responsive.onlyMobile}>
          <Container>
            <BigMenu />
            <Segment placeholder>
              <Header icon>
                <Icon loading size="massive" name="cog" />
                page en developement ! bientôt disponible.
              </Header>
            </Segment>
          </Container>
          <FooterMenu />
        </Responsive>
        <Responsive minWidth={768}>
          <BigMenu />
          <Container textAlign="center">
            <Segment style={{ marginTop: "40px" }} placeholder>
              <Header icon>
                <Icon loading size="massive" name="cog" />
                page en developement ! bientôt disponible.
              </Header>
            </Segment>
          </Container>
        </Responsive>
      </div>
    );
  }
}
