import React from "react";
import {
  Radar,
  PolarAngleAxis,
  PolarRadiusAxis,
  PolarGrid,
  RadarChart,
  ResponsiveContainer
} from "recharts";

const data = [
  {
    subject: "Dicipline",
    A: 50,
    fullMark: 150
  },
  {
    subject: "Leaderchip",
    A: 25,
    fullMark: 150
  },
  {
    subject: "communication",
    A: 25,
    fullMark: 150
  },
  {
    subject: "créativité",
    A: 30,
    fullMark: 150
  },
  {
    subject: "stratégie",
    A: 30,
    fullMark: 150
  },
  {
    subject: "philosophie",
    A: 25,
    fullMark: 150
  }
];

export default function App() {
  return (
    <div style={{ width: "100%", height: "250px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
          <PolarGrid />
          <PolarAngleAxis dataKey="subject" />
          <PolarRadiusAxis />
          <Radar
            name="Mike"
            dataKey="A"
            stroke="#8884d8"
            fill="#8884d8"
            fillOpacity={0.6}
          />
        </RadarChart>
      </ResponsiveContainer>
    </div>
  );
}
