import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Login from "./login";
import Home from "./home";
import Livre from "./book";
import Search from "./search";
import Catalogue from "./catalogue";
// import Test from "./test1"
import Profil from "./profil";
import bookGame from "./bookGame";
import BookGameHome from "./bookGameHome";
import ClassRoom from "./classRoom";
import Notes from "./ebook";
import Meetup from "./meetup";
import { marketStore } from "./market";

// import FooterMenu from "../components/fotterMenu";
import PrivateRoute from "../components/privateRoute";

export default () => (
  <BrowserRouter>
    <Switch>
      <Route path="/recherche-livres" exact component={Search} />
      <Route path="/inscription" exact component={Login} />
      <Route path="/gameBook/:game" exact component={bookGame} />
      <Route path="/livres/:livre/:ref" exact component={Livre} />
      {/* <Route path="/test" exact component={Test} /> */}
      {/* <Route path="/profil" exact component={Profil} /> */}
      <Route path="/mes_achats" exact component={marketStore} />
      <Route path="/catalogue/:cathegorie" exact component={Catalogue} />
      {/* <Route path="/mon_profile" exact component={Profil} /> */}
      <Route path="/" exact component={Home} />
      <PrivateRoute exact path="/profil" component={Profil} />
      <PrivateRoute exact path="/stats" component={ClassRoom} />
      <PrivateRoute exact path="/notes" component={Notes} />
      <PrivateRoute exact path="/meetup" component={Meetup} />
      <PrivateRoute exact path="/BookGameHome" component={BookGameHome} />
    </Switch>
    {/* <FooterMenu /> */}
  </BrowserRouter>
);
