import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Segment,
  Grid,
  Responsive,
  Progress,
  Image,
  Card,
  Icon,
  Loader,
  Dimmer,
  Header,
  Button,
  Divider,
  Modal
} from "semantic-ui-react";
import Markdown from "markdown-to-jsx";

import "firebase/firestore";
import firebase from "firebase";

import GameBook from "../components/quizz";
import BigMenu from "../components/headerMenu";
import Mobileheader from "../components/headerMenu/mobile";
import Note from "../components/note";
// import MobileSimpleHeader from "../components/headerMenu/";

// import FooterMenu from "../components/fotterMenu";

const db = firebase.firestore();

function bookGame() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let history = useHistory();

  const userName = firebase.auth().currentUser
    ? firebase.auth().currentUser.displayName
    : false;

  const userImage = firebase.auth().currentUser
    ? firebase.auth().currentUser.photoURL
    : false;

  const FetchQuiz = GameBook[0].lautoroute_du_milonnaire;
  const bookTitre = FetchQuiz.titre;

  // les variable a modifier pour sauvegarder la progression du joueur
  let Point_dentre = "A";
  let Point_dentre1 = "A1";
  let Point_dentre2 = "A2";
  let Point_dentre3 = "A3";
  let Niveau_provendeur = 1;

  let sauvegarde = localStorage.getItem("obj");
  if (sauvegarde) {
    let objJson = JSON.parse(sauvegarde);
    Point_dentre = objJson.Point_dentre;
    Point_dentre1 = objJson.Point_dentre1;
    Point_dentre2 = objJson.Point_dentre2;
    Point_dentre3 = objJson.Point_dentre3;
    Niveau_provendeur = objJson.Niveau_provendeur;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [responseKey, setResponseKey] = useState(Point_dentre);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [responseKey1, setResponseKey1] = useState(Point_dentre1);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [responseKey2, setResponseKey2] = useState(Point_dentre2);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [responseKey3, setResponseKey3] = useState(Point_dentre3);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [Question, setQuestion] = useState("");

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [Illustation, setIllustation] = useState("");

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [response1, setResponse1] = useState(
    FetchQuiz[responseKey][Point_dentre1]
  );
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [response2, setResponse2] = useState(
    FetchQuiz[responseKey][Point_dentre2]
  );
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [response3, setResponse3] = useState(
    FetchQuiz[responseKey][Point_dentre3]
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [gameOver, setGameOver] = useState(null);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [Pause, setPause] = useState(null);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [End, setEnd] = useState(false);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [EndLecon, setEndLecon] = useState(null);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [EndBageTitre, setEndBageTitre] = useState(null);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [EndBageImage, setEndBageImage] = useState(null);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [Active, setActive] = useState(true);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [visible, setVisible] = useState(true);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [profondeurActuel, setProfondeurActuel] = useState(Niveau_provendeur);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [HaveBageTitre, setHaveBageTitre] = useState(true);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [open, setOpen] = useState(true);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [userEmail, setUseremail] = useState(null);

  const illustation = FetchQuiz[responseKey].illustration;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const user = firebase.auth().currentUser;
    const email = user ? user.email : "";
    setUseremail(email);
    // console.log(userEmail);
    db.collection("bages")
      .where("owner", "==", email)
      .where("titre", "==", "Apprenti alchimist")
      .get()
      .then((doc) => {
        // const values = querySnapshot.docs.map((doc) => doc.data());
        if (doc.empty) {
          setHaveBageTitre(true);
        } else {
          setHaveBageTitre(false);
        }
      })
      .catch(function (error) {
        //
      });

    const question = FetchQuiz[responseKey].question;

    if (responseKey === FetchQuiz.end) {
      setEnd(true);
      setEndLecon(FetchQuiz[responseKey].endlecon);
      setEndBageTitre(FetchQuiz.bageTitre);
      setEndBageImage(FetchQuiz.BageImage);
    }

    // setData(FetchQuiz);
    setQuestion(question);

    setIllustation(illustation);
  }, [FetchQuiz, Question, illustation, responseKey, userEmail]);

  const SelectOnclick = async (key) => {
    // const Pause = ["A13111322"];
    setVisible(false);
    setTimeout(function () {
      var niveau = "";

      if (FetchQuiz.jointure.includes(key)) {
        niveau = "jointure";
      }
      if (FetchQuiz.exlusion.includes(key)) {
        niveau = "gameOver";
      }
      if (FetchQuiz.pause.includes(key)) {
        niveau = "pause";
      }

      if (FetchQuiz.end === key) {
        // sauvegarde dans une base de donner
        db.collection("bages")
          .add({
            titre: FetchQuiz.bageTitre,
            created: new Date(),
            owner: userEmail
          })
          .then(function (docRef) {
            // console.log("Document written with ID: ", docRef.id);
            // showMessages();
            // dispatch(deleteAllMarketAction());
            // history.push("/");
            // console.log(docRef);
          })
          .catch(function (error) {
            // console.error("Error adding document: ", error);
          });
      }

      switch (niveau) {
        case "gameOver":
          setGameOver(FetchQuiz[key].lecon);
          break;
        case "jointure":
          const retour = FetchQuiz.lien[key];
          // const retour = "A131";
          setProfondeurActuel(profondeurActuel + 1);
          setResponseKey(retour);
          const reponse11 = FetchQuiz[retour][`${retour}1`];
          setResponse1(reponse11);
          setResponseKey1(`${retour}1`);

          // console.log(FetchQuiz[key][`${key}1`])
          const reponse22 = FetchQuiz[retour][`${retour}2`];
          setResponse2(reponse22);
          setResponseKey2(`${retour}2`);

          const reponse33 = FetchQuiz[retour][`${retour}3`];
          setResponse3(reponse33);
          setResponseKey3(`${retour}3`);
          break;
        case "pause":
          let objJson = {
            Point_dentre: `${responseKey}`,
            Point_dentre1: `${responseKey}1`,
            Point_dentre2: `${responseKey}2`,
            Point_dentre3: `${responseKey}3`,
            Niveau_provendeur: profondeurActuel
          };
          setPause(true);
          setActive(true);
          let objLinea = JSON.stringify(objJson);
          localStorage.setItem("obj", objLinea);
          // console.log(responseKey);
          // console.log(key);
          // console.log("c est la pause");
          break;
        default:
          setProfondeurActuel(profondeurActuel + 1);
          setResponseKey(key);
          const reponse1 = FetchQuiz[key][`${key}1`];
          setResponse1(reponse1);
          setResponseKey1(`${key}1`);

          // console.log(FetchQuiz[key][`${key}1`])
          const reponse2 = FetchQuiz[key][`${key}2`];
          setResponse2(reponse2);
          setResponseKey2(`${key}2`);

          const reponse3 = FetchQuiz[key][`${key}3`];
          setResponse3(reponse3);
          setResponseKey3(`${key}3`);
      }
      setVisible(true);
    }, 3000);
  };

  const EndGrid = () => (
    <Grid columns={2} style={{ marginTop: "10%" }}>
      <div>
        <Markdown
          style={{
            fontWeight: "normal",
            fontStyle: "italic",
            fontSize: "1.3em"
          }}
        >
          {EndLecon}
        </Markdown>
      </div>
      <div>
        felicitation vous vener d obtenir une nouvelle competence :
        {EndBageTitre}
      </div>
      <div>
        <Image src={EndBageImage} />
      </div>
      <Button
        fluid
        style={{ marginTop: "10px" }}
        color="blue"
        onClick={() => history.push("/inscription")}
      >
        revenir dans votre confrerrie
      </Button>
    </Grid>
  );

  const EndGridMobile = () => (
    <Grid columns={2} style={{ marginTop: "10%", marginBottom: "60px" }}>
      <div>
        <Markdown
          style={{
            fontWeight: "normal",
            fontStyle: "italic",
            fontSize: "1.3em"
          }}
        >
          {EndLecon}
        </Markdown>
      </div>
      <div>
        felicitation vous vener d obtenir une nouvelle competence :
        {EndBageTitre}
      </div>
      <div>
        <Image src={EndBageImage} />
      </div>
      <Button
        fluid
        style={{ marginTop: "10px" }}
        color="blue"
        onClick={() => history.push("/inscription")}
      >
        jouer maintement
      </Button>
    </Grid>
  );

  const GridExampleCentered = () => (
    <Grid columns={2} style={{ marginTop: "10%" }}>
      {/* <Grid.Column> */}

      <h3 style={{ marginLeft: "50%", color: "red" }}>{responseKey}</h3>

      <div>
        <Markdown
          style={{
            fontWeight: "normal",
            fontStyle: "italic",
            fontSize: "1.3em"
          }}
        >
          {Question}
        </Markdown>
      </div>

      {/* </Grid.Column> */}
      {
        <Grid.Row style={{ marginTop: "50px" }} centered columns={2}>
          <Grid.Column>
            <Segment
              style={{ cursor: "pointer" }}
              onClick={() => SelectOnclick(responseKey1)}
              raised
              color="orange"
            >
              <span role="img" aria-label="New-Book">
                🇦
              </span>{" "}
              -- {response1}
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment
              style={{ cursor: "pointer" }}
              onClick={() => SelectOnclick(responseKey2)}
              raised
              color="green"
            >
              <span role="img" aria-label="New-Book">
                🇧
              </span>{" "}
              -- {response2}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      }

      {response3 ? (
        <Grid columns={2} centered>
          <Segment
            style={{ cursor: "pointer" }}
            onClick={() => SelectOnclick(responseKey3)}
            raised
            color="blue"
          >
            <span role="img" aria-label="New-Book">
              🇨
            </span>{" "}
            -- {response3}
          </Segment>
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );

  const MobileGridExampleCentered = () => (
    <Grid columns={2} style={{ marginTop: "10%", marginBottom: "50px" }}>
      {/* <Grid.Column> */}

      <div style={{ fontWeight: "normal", fontSize: "1.3em" }}>
        <Markdown>{Question}</Markdown>
      </div>

      <Grid.Row style={{ marginTop: "50px" }} centered columns={2}>
        <Grid.Column>
          <Segment
            style={{ cursor: "pointer" }}
            onClick={() => SelectOnclick(responseKey1)}
            raised
            color="orange"
          >
            <span role="img" aria-label="New-Book">
              🇦
            </span>
            -- {response1}
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Segment
            style={{ cursor: "pointer" }}
            onClick={() => SelectOnclick(responseKey2)}
            raised
            color="green"
          >
            <span role="img" aria-label="New-Book">
              🇧
            </span>
            -- {response2}
          </Segment>
        </Grid.Column>
      </Grid.Row>
      {response3 ? (
        <Grid columns={2} centered>
          <Segment
            style={{ cursor: "pointer" }}
            onClick={() => SelectOnclick(responseKey3)}
            raised
            color="blue"
          >
            <span role="img" aria-label="New-Book">
              🇨
            </span>{" "}
            -- {response3}
          </Segment>
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );

  const PlaceholderView = () => (
    <Dimmer active>
      <Loader size="massive">
        <span role="img" aria-label="Rapide">
          🤔🤔🤔
        </span>
      </Loader>
    </Dimmer>
  );

  const handleHide = () => setActive(false);

  const PauseView = () => (
    <Dimmer active={Active} verticalAlign="center">
      <Header as="h2" inverted>
        Votre progression a ete sauvegarder. <br />a bientot pour la suite de l
        aventure !
      </Header>
      <Button primary>Acceille</Button>
      <Button color="red" onClick={handleHide}>
        Poursuive laventure
      </Button>
    </Dimmer>
  );

  const ProgresValue = parseInt(
    (100 * profondeurActuel) / FetchQuiz.profondeur,
    10
  );

  const ProgressExampleProgress = () => (
    <Progress
      style={{ margin: "5px" }}
      size="medium"
      percent={ProgresValue}
      progress
      color="green"
      active
    />
  );

  const MobileProgress = () => (
    <Progress
      size="small"
      color="green"
      percent={ProgresValue}
      active
      progress
    />
  );

  const HaveBagemodal = () =>
    !HaveBageTitre ? (
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <Header color="blue" icon="gamepad" content="grand maitre alchimiste" />
        <Modal.Content>
          <p>
            salut vous posseder deja la competende de maitre alchimist. vous
            vouler la rejouer a nouveau ?
          </p>
        </Modal.Content>
        <Modal.Actions>
          {/* <Button color="red" onClick={() => setOpen(false)}>
            <Icon name="remove" /> No
          </Button> */}
          <Button
            color="green"
            onClick={() => {
              localStorage.removeItem("obj");
              setOpen(history.push("/gameBook/lautoroute_du_milonnaire"));
            }}
          >
            <Icon name="checkmark" /> rejouer
          </Button>
          <Button
            color="green"
            onClick={() => setOpen(history.push("/profil"))}
          >
            <Icon name="checkmark" /> non
          </Button>
        </Modal.Actions>
      </Modal>
    ) : (
      ""
    );

  return (
    <div>
      <Responsive {...Responsive.onlyMobile}>
        <Container>
          <Mobileheader />

          <Grid columns="equal">
            <Grid.Column width={14}>
              <MobileProgress />
            </Grid.Column>
            <Grid.Column>
              <span role="img" aria-label="New-Book">
                🎯
              </span>
            </Grid.Column>
          </Grid>
          <Image fluid style={{}} src={Illustation} />
          {visible ? (
            gameOver ? (
              <div>
                <Segment color="red">
                  <Grid columns={2} stackable textAlign="center">
                    <Icon color="red" name="thumbs down" size="large" />
                  </Grid>
                  <p
                    style={{
                      fontWeight: "normal",
                      fontSize: "1.3em",
                      marginTop: "20px"
                    }}
                  >
                    <Markdown>{gameOver}</Markdown>
                  </p>
                </Segment>

                <Button.Group
                  style={{
                    marginBottom: "60px",
                    marginLeft: "10%"
                  }}
                >
                  <Button positive onClick={() => history.go(0)}>
                    <Icon color="black" name="reply all" size="large" />
                    réessayer
                  </Button>
                  <Button.Or />
                  <Button color="red" onClick={() => history.push("/")}>
                    quiter le jeux{" "}
                    <Icon color="black" name="sign-out" size="large" />
                  </Button>
                </Button.Group>
              </div>
            ) : (
              <>
                {End ? <EndGridMobile /> : <MobileGridExampleCentered />}
                {/* <GridExampleCentered /> */}
                {Pause ? <PauseView /> : ""}
              </>
            )
          ) : (
            <PlaceholderView />
          )}

          <Note
            userEmail1={userEmail}
            userName={userName}
            userImage={userImage}
            Illustation={Illustation}
            dataBase={db}
            bookTitre={bookTitre}
            ProgresValue={ProgresValue}
            style={{
              position: "fixed",
              bottom: "30px",
              right: "10px",
              textDecoration: "none"
            }}
          />

          {/* <FooterMenu /> */}
        </Container>
      </Responsive>

      <Responsive minWidth={768}>
        <BigMenu game={true} />
        <Container>
          <HaveBagemodal />
          <Grid columns="equal">
            <Grid.Column style={{ marginTop: "5px" }} width={5}>
              <Card>
                <ProgressExampleProgress />
                <Image
                  fluid
                  style={{
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                  }}
                  src={Illustation}
                />
                <Card.Content>
                  <Card.Header>{FetchQuiz.titre}</Card.Header>
                  <Card.Meta>
                    <span className="date">{FetchQuiz.auteur}</span>
                  </Card.Meta>
                  <Card.Description>{FetchQuiz.resumer}</Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <Icon name="user" />
                  22 Friends
                </Card.Content>
              </Card>
            </Grid.Column>
            {/* <Grid.Column style={{ marginTop: "25px" }}> */}
            {visible ? (
              gameOver ? (
                <Grid columns={2} style={{ marginTop: "10%" }}>
                  <Segment color="red" placeholder>
                    <Grid columns={2} stackable textAlign="center">
                      <Divider vertical>
                        <Icon
                          color="red"
                          name="hand point right outline"
                          size="large"
                        />
                      </Divider>

                      <Grid.Row verticalAlign="middle">
                        <Grid.Column>
                          <Image
                            src="https://img.freepik.com/premium-vector/sketch-little-people-with-big-words-game_156892-977.jpg?w=996"
                            size="meduim"
                          />
                        </Grid.Column>

                        <Grid.Column
                          style={{
                            fontStyle: "oblique",
                            fontSize: "20px",
                            lineHeight: "130%"
                          }}
                        >
                          <Markdown>{gameOver}</Markdown>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                  <Grid style={{ marginTop: "30px" }} centered columns={2}>
                    <Grid.Column>
                      <Button.Group style={{ marginLeft: "-50%" }}>
                        <Button positive onClick={() => history.go(0)}>
                          <Icon color="black" name="reply all" size="large" />{" "}
                          réessayer
                        </Button>
                        <Button.Or />
                        <Button color="red" onClick={() => history.push("/")}>
                          quiter le jeux{" "}
                          <Icon color="black" name="sign-out" size="large" />
                        </Button>
                      </Button.Group>
                    </Grid.Column>
                  </Grid>
                </Grid>
              ) : (
                <>
                  {End ? <EndGrid /> : <GridExampleCentered />}
                  {/* <GridExampleCentered /> */}
                  {Pause ? <PauseView /> : ""}
                </>
              )
            ) : (
              <PlaceholderView />
            )}
            {/* </Grid.Column> */}
          </Grid>
          <Note
            userEmail1={userEmail}
            userName={userName}
            userImage={userImage}
            Illustation={Illustation}
            dataBase={db}
            bookTitre={bookTitre}
            ProgresValue={ProgresValue}
            style={{
              position: "fixed",
              bottom: "30px",
              right: "10px",
              textDecoration: "none"
            }}
          />
        </Container>
      </Responsive>
    </div>
  );
}

export default bookGame;
