import React from "react";
import { Grid, Placeholder } from "semantic-ui-react";

const PlaceholderExampleGrid = () => (
  <Grid columns={3}>
    <Grid.Column>
        <Placeholder style={{ height: 150, padding: "0px 2px" }}>
          <Placeholder.Image />
        </Placeholder>
    </Grid.Column>

    <Grid.Column>
        <Placeholder style={{ height: 150, padding: "0px 2px" }}>
          <Placeholder.Image />
        </Placeholder>
    </Grid.Column>

    <Grid.Column>
        <Placeholder  style={{ height: "150px", padding: "0px 2px" }}>
          <Placeholder.Image />
        </Placeholder>
    </Grid.Column>
  </Grid>
);

export default PlaceholderExampleGrid;
