import React, { useState, useEffect } from "react";
import "firebase/firestore";
import firebase from "firebase";

import Search from "./searchBar";
import CathegorieLink from "../components/linkCathegorie";
// import CathegorieView from "../components/cathegorieView"
import { Container } from "semantic-ui-react";

const db = firebase.firestore();

function getAlldata() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [data, setData] = useState(null);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    db.collection("livres")
      .get()
      .then((querySnapshot) => {
        const values = querySnapshot.docs.map((doc) => doc.data());
        setData(values);
      })
      .catch(function (error) {
        // console.log("Error getting documents: ", error);
      });
  }, []);

  return data ? (
    <Search allData={data} />
  ) : (
    <Container>
      <div style={{ padding: "0px", marginTop: "0px" }}>
        <CathegorieLink />
      </div>
    </Container>
  );
}

export default getAlldata;
