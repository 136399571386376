import React, { useState, useEffect } from "react";
import {
  Grid,
  Image,
  Placeholder,
  Responsive,
  Container,
  Label,
  Icon
} from "semantic-ui-react";
import firebase from "firebase";
import "firebase/firestore";
import slugify from "slugify";
import { useParams, useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";

import BigMenu from "../components/headerMenu";
import FooterMenu from "../components/fotterMenu";
import CathalogDescription from "../components/cathalogDescription";

const db = firebase.firestore();

function catalogue() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let { cathegorie } = useParams();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  let history = useHistory();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [data, setData] = useState(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    db.collection("livres")
      .where("cathegorie", "array-contains-any", [cathegorie])
      .get()
      .then((querySnapshot) => {
        const values = querySnapshot.docs.map((doc) => doc.data());
        setData(values);
      });
    console.log(cathegorie);
    // .catch(function (error) {
    //   console.log(error);
    // });
  }, [cathegorie]);

  const selected = (titre, ref) => {
    const slugifyUrl = slugify(titre);
    history.push(`/livres/${slugifyUrl}/${ref}`);
  };
  return (
    <div>
      <Responsive {...Responsive.onlyMobile}>
        <Container style={{ marginBottom: "70px" }}>
          <BigMenu />
          <CathalogDescription url={cathegorie} />
          <Grid columns={2} padded="vertically">
            {data ? (
              data.map((livre) => (
                <Grid.Column
                  key={livre.ref}
                  width={8}
                  textAlign="center"
                  onClick={() => selected(livre.titre, livre.ref)}
                >
                  {livre.rupture ? (
                    <Label
                      color="red"
                      basic
                      size="mini"
                      style={{
                        position: "absolute",
                        top: "8%",
                        right: "8%",
                        zIndex: 1
                      }}
                    >
                      en ruptute
                    </Label>
                  ) : (
                    ""
                  )}
                  <Image
                    style={{
                      height: "240px",
                      width: "100%",
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                    }}
                    src={livre.image}
                  />
                  {/* <p style={{ fontWeight: "bold" }}> {livre.price2} fcfa</p> */}
                  <NumberFormat
                    value={livre.price2}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    allowNegative={false}
                    suffix={" Fcfa"}
                    renderText={(value) => (
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                          marginLeft: "5%",
                          marginTop: "5px"
                        }}
                      >
                        {value}
                      </div>
                    )}
                  />
                </Grid.Column>
              ))
            ) : (
              <>
                <Grid.Column width={8} textAlign="center">
                  <Placeholder style={{ height: 240, width: 150 }}>
                    <Placeholder.Image />
                  </Placeholder>
                </Grid.Column>
                <Grid.Column width={8} textAlign="center">
                  <Placeholder style={{ height: 240, width: 150 }}>
                    <Placeholder.Image />
                  </Placeholder>
                </Grid.Column>
              </>
            )}
          </Grid>
        </Container>
        <FooterMenu />
      </Responsive>
      <Responsive minWidth={768}>
        <BigMenu />
        <Container style={{ marginTop: "50px" }}>
          <CathalogDescription url={cathegorie} />
          <Grid columns={4} padded="vertically">
            {data ? (
              data.map((livre) => (
                <Grid.Column
                  key={livre.ref}
                  width={4}
                  textAlign="center"
                  onClick={() => selected(livre.titre, livre.ref)}
                >
                  <h2 style={{ fontSize: "12px" }}>{livre.titre}</h2>
                  <Image
                    style={{
                      height: "350px",
                      width: "100%",
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                    }}
                    src={livre.image}
                  />
                  {livre.rupture ? (
                    <Label
                      color="red"
                      tag
                      style={{
                        position: "absolute",
                        top: "10%",
                        right: "10%",
                        zIndex: 1
                      }}
                    >
                      <Icon name="exclamation circle" /> ruptute
                    </Label>
                  ) : (
                    ""
                  )}
                  <NumberFormat
                    value={livre.price2}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    allowNegative={false}
                    suffix={" Fcfa"}
                    renderText={(value) => (
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: "22px",
                          marginLeft: "5%",
                          margin: "10px"
                        }}
                      >
                        {value}
                      </div>
                    )}
                  />
                </Grid.Column>
              ))
            ) : (
              <>
                <Grid.Column width={4} textAlign="center">
                  <Placeholder style={{ height: 350, width: "100%" }}>
                    <Placeholder.Image />
                  </Placeholder>
                </Grid.Column>
                <Grid.Column width={4} textAlign="center">
                  <Placeholder style={{ height: 350, width: "100%" }}>
                    <Placeholder.Image />
                  </Placeholder>
                </Grid.Column>
                <Grid.Column width={4} textAlign="center">
                  <Placeholder style={{ height: 350, width: "100%" }}>
                    <Placeholder.Image />
                  </Placeholder>
                </Grid.Column>
                <Grid.Column width={4} textAlign="center">
                  <Placeholder style={{ height: 350, width: "100%" }}>
                    <Placeholder.Image />
                  </Placeholder>
                </Grid.Column>
              </>
            )}
          </Grid>
        </Container>
      </Responsive>
    </div>
  );
}

export default catalogue;
