import React, { useState, useEffect } from "react";
import {
  Grid,
  Image,
  Placeholder,
  Responsive,
  Container
} from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import slugify from "slugify";
import "firebase/firestore";
import firebase from "firebase";
import NumberFormat from "react-number-format";

const db = firebase.firestore();

function getBookCathegorie(props) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let history = useHistory();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [data, setData] = useState(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    db.collection("livres")
      .where("cathegorie", "array-contains-any", props.cathego)
      .limit(4)
      .get()
      .then((querySnapshot) => {
        const values = querySnapshot.docs.map((doc) => doc.data());
        setData(values);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, [props.cathego, props.ref]);

  const selected = (titre, ref) => {
    const slugifyUrl = slugify(titre);
    history.push(`/livres/${slugifyUrl}/${ref}`);
  };
  return (
    <div>
      <Responsive {...Responsive.onlyMobile}>
        <Container>
          <Grid columns={2} padded="vertically">
            {data ? (
              data.map((livre) => (
                <Grid.Column
                  key={livre.ref}
                  width={8}
                  textAlign="center"
                  onClick={() => selected(livre.titre, livre.ref)}
                >
                  <Image
                    style={{
                      height: "180px",
                      width: "100%",
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                    }}
                    src={livre.image}
                  />

                  <NumberFormat
                    value={livre.price2}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    allowNegative={false}
                    suffix={" Fcfa"}
                    renderText={(value) => (
                      <div style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {value}
                      </div>
                    )}
                  />
                </Grid.Column>
              ))
            ) : (
              <>
                <Grid.Column width={8} textAlign="center">
                  <Placeholder style={{ height: 180, width: 150 }}>
                    <Placeholder.Image />
                  </Placeholder>
                </Grid.Column>
                <Grid.Column width={8} textAlign="center">
                  <Placeholder style={{ height: 180, width: 150 }}>
                    <Placeholder.Image />
                  </Placeholder>
                </Grid.Column>
              </>
            )}
          </Grid>
        </Container>
      </Responsive>
      <Responsive minWidth={768}>
        <Grid columns={4} padded="vertically">
          {data ? (
            data.map((livre) => (
              <Grid.Column
                key={livre.ref}
                width={4}
                textAlign="center"
                onClick={() => selected(livre.titre, livre.ref)}
              >
                <Image
                  style={{
                    height: "320px",
                    width: "100%",
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                  }}
                  src={livre.image}
                />
                <NumberFormat
                  value={livre.price2}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  allowNegative={false}
                  suffix={" Fcfa"}
                  renderText={(value) => (
                    <div style={{ fontWeight: "bold", fontSize: "20px" }}>
                      {value}
                    </div>
                  )}
                />
              </Grid.Column>
            ))
          ) : (
            <>
              <Grid.Column width={4} textAlign="center">
                <Placeholder style={{ height: 320, width: 250 }}>
                  <Placeholder.Image />
                </Placeholder>
              </Grid.Column>
              <Grid.Column width={4} textAlign="center">
                <Placeholder style={{ height: 320, width: 250 }}>
                  <Placeholder.Image />
                </Placeholder>
              </Grid.Column>
              <Grid.Column width={4} textAlign="center">
                <Placeholder style={{ height: 320, width: 250 }}>
                  <Placeholder.Image />
                </Placeholder>
              </Grid.Column>
              <Grid.Column width={4} textAlign="center">
                <Placeholder style={{ height: 320, width: 250 }}>
                  <Placeholder.Image />
                </Placeholder>
              </Grid.Column>
            </>
          )}
        </Grid>
      </Responsive>
    </div>
  );
}

export default getBookCathegorie;
