/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { Responsive, Label, Icon } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { marketSelector } from "../../store/marketSelector";

function marketButton() {
  const markets = useSelector(marketSelector);
  const BookLength = () => {
    if (markets.length >= 1) {
      return (
        <Label color="blue" circular size="medium" floating>
          {markets.length}
        </Label>
      );
    } else {
      return "";
    }
  };

  const IconRotation = () => {
    if (markets.length >= 1) {
      return (
        <Icon
          color="red"
          size="large"
          name="shopping bag"
          style={{
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
          }}
          circular
          loading
        />
      );
    } else {
      return (
        <Icon
          color="orange"
          style={{
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
          }}
          size="large"
          name="shopping bag"
          circular
        />
      );
    }
  };
  return (
    <div>
      <Responsive {...Responsive.onlyMobile}>
        <BookLength />
      </Responsive>
      <Responsive minWidth={768}>
        <Link
          style={{
            position: "fixed",
            bottom: "30px",
            right: "10px",
            textDecoration: "none"
          }}
          to="/mes_achats"
        >
          <BookLength />
          <IconRotation />
          {/* <Icon color="orange" size="large" name="shopping bag" circular loading  /> */}
        </Link>
      </Responsive>
    </div>
  );
}

export default marketButton;
