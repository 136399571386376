/* eslint-disable no-undef */
/* eslint-disable react-hooks/rules-of-hooks */
import { Button, Icon, Responsive, Grid } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";

import { addMarketAction } from "../store/marketAction";
import { marketSelector } from "../store/marketSelector";
import { useParams, Link, useHistory } from "react-router-dom";

export function AddMarketButton({ market }) {
  let history = useHistory();
  const [selected, setSelected] = useState(false);
  const [exite, setExite] = useState(false);
  let { ref } = useParams();
  const urlref = ref;
  const dispatch = useDispatch();
  const markets = useSelector(marketSelector);

  useEffect(() => {
    const toto = markets.find(({ ref }) => ref === urlref);
    if (toto) {
      return setExite(true);
    } else {
      return setExite(false);
    }
    // markets.map((market) => {
    //   if (market.id === ref) {
    //     return setExite(true);
    //   }
    //   return setExite(false);
    // });
  }, [markets, urlref]);

  // const DirectAchatModal = () => {
  //   const [open, setOpen] = React.useState(false);

  //   const handleClick = () => {
  //     const yourNumber = "+22507815201";
  //     const yourMessage = `je veux le livre ${livre}`;
  //     const number = yourNumber;
  //     const message = yourMessage.split(" ").join("%20");

  //     return (window.location.href = `https://api.whatsapp.com/send?phone= + ${number} + &text=%20 + ${message}`);
  //   };

  //   return (
  //     <Modal
  //       basic
  //       onClose={() => setOpen(false)}
  //       onOpen={() => setOpen(true)}
  //       open={open}
  //       size="small"
  //       trigger={
  //         <Button color="orange">
  //           <Icon name="shopping bag" />
  //           achater cet artcle
  //         </Button>
  //       }
  //     >
  //       <Header>
  //         acheter directement via whatsapp ou un appel telephonique
  //       </Header>
  //       <Modal.Content>
  //         {/* Identifiant: {id} <br />
  //         titre: {title} <br />
  //         prix: {price} Fcfa <br /> */}
  //         <br />
  //         {/* prix :
  //           <NumberFormat
  //             value={articleprice2}
  //             displayType={"text"}
  //             thousandSeparator=" "
  //             suffix={" Fcfa"}
  //           /> */}
  //         <br />
  //         <Segment inverted>
  //           NB: payer a la livraison et frais de livraison 1.000 fcfa a Abidjan
  //           et 1500 hors d Abidjan via la poste ou une societer de transport{" "}
  //           <Icon name="car" /> de votre choix.
  //         </Segment>
  //       </Modal.Content>
  //       <Modal.Actions>
  //         <div style={{ marginRight: "40px" }}>
  //           <Button.Group>
  //             <Button color="green" onClick={handleClick} inverted>
  //               <Icon name="checkmark" />
  //               whatsapp
  //             </Button>
  //             <Button.Or text="ou" />
  //             <Button color="blue" href="tel:+2250707815201" inverted>
  //               <Icon name="phone" />
  //               appeler
  //             </Button>
  //           </Button.Group>
  //         </div>

  //         {/* <Button basic color='red' inverted onClick={() => setOpen(false)}>
  //           <Icon name='remove' /> No
  //         </Button>
  //         <Button color='green' inverted onClick={() => setOpen(false)}>
  //           <Icon name='checkmark' /> Yes
  //         </Button> */}
  //       </Modal.Actions>
  //     </Modal>
  //   );
  // };

  // console.log(markets);

  const handleSubmit = (e) => {
    dispatch(addMarketAction(market));
    setSelected(false);
  };

  const handleSubmitDirect = (e) => {
    dispatch(addMarketAction(market));
    setSelected(false);
    history.push(`/mes_achats`);
  };

  return selected ? (
    <Button.Group tyle={{ marginTop: "5px" }} fluid>
      <Button>FINALISER VOTRE COMMANDE</Button>
      <Button.Or text="ou" />
      <Button positive>POURSUIVRE VOS ACHATS</Button>
    </Button.Group>
  ) : exite ? (
    <Grid textAlign="center">
      <Button.Group>
        <Link to="/">
          <Button positive style={{ borderRadius: "30px" }}>
            Poursuivre vos achats
          </Button>
        </Link>

        <Button.Or text="ou" />

        <Link to="/mes_achats">
          <Button color="blue"> finaliser</Button>
        </Link>
      </Button.Group>
    </Grid>
  ) : (
    <div>
      <Responsive {...Responsive.onlyMobile}>
        {!market.rupture ? (
          <>
            {/* <Button.Group style={{ marginTop: "5px" }} fluid>
              <DirectAchatModal />
              <Button.Or text="ou" />
              <Button color="olive" onClick={handleSubmit}>
                panier <Icon name="cart arrow down" />
              </Button>
            </Button.Group> */}
            <Button
              fluid
              color="orange"
              style={{
                marginBottom: "10px",
                marginTop: "10px",
                borderRadius: "15px"
              }}
              onClick={handleSubmitDirect}
            >
              acheter cet article
            </Button>
            <Button
              fluid
              style={{
                marginBottom: "10px",
                marginTop: "10px",
                backgroundColor: "#f7ca00",
                borderRadius: "15px"
              }}
              onClick={handleSubmit}
            >
              ajouter au panier <Icon name="cart arrow down" />
            </Button>
          </>
        ) : (
          <p style={{ margin: "10px" }}>
            le livre est en rupture de stock <br />
            contacter nous pour effectuer une reservation <br />
            07 07 81 52 01
          </p>
        )}
      </Responsive>
      <Responsive minWidth={768}>
        {!market.rupture ? (
          <>
            <Button
              style={{
                marginTop: "25px",
                backgroundColor: "#f7ca00",
                borderRadius: "15px",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
              }}
              size="large"
              fluid
              onClick={handleSubmitDirect}
            >
              acheter cet article <Icon name="cart arrow down" />
            </Button>
            <Button
              style={{
                marginTop: "15px",
                borderRadius: "15px",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
              }}
              size="large"
              fluid
              color="orange"
              onClick={handleSubmit}
            >
              Ajouter au panier <Icon name="cart arrow down" />
            </Button>
          </>
        ) : (
          <p>
            le livre est en rupture de stock <br />
            contacter nous pour effectuer une reservation <br /> 07 81 52 01
          </p>
        )}
      </Responsive>
    </div>
  );
}
