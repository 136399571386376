/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { Responsive } from "semantic-ui-react";
import { Helmet } from "react-helmet";

import BigMenu from "../components/headerMenu";

import Login from "../components/login";

function login() {
  return (
    <div>
      <Helmet>
        <title>Créer un compte </title>
      </Helmet>
      <Responsive {...Responsive.onlyMobile}>
        <Login />
      </Responsive>
      <Responsive minWidth={768}>
        <BigMenu />
        <Login />
      </Responsive>
    </div>
  );
}

export default login;
