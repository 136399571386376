/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Table,
  Responsive,
  Container,
  Grid,
  Label,
  Image
} from "semantic-ui-react";
import { Helmet } from "react-helmet";

import Mobileheader from "../components/headerMenu";
import ButtonValiderAchat from "../components/ButtonValiderAchat";
import BigMenu from "../components/headerMenu";
import CathegorieLink from "../components/linkCathegorie";
import FooterMenu from "../components/fotterMenu";

import { deleteMarketAction } from "../store/marketAction";
import { marketSelector } from "../store/marketSelector";
// import { MarketFilterStore } from "../components/test";
// import { AddMarketForm } from "../components/test2";
// import { filterSelector, filterMarketSElector } from "../store/filterSelector";

const TableExampleApprove = ({ markets, onDelete }) => {
  if (markets.length >= 1) {
    return (
      <Table color="green" unstackable definition>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>livre</Table.HeaderCell>
            <Table.HeaderCell>prix(Fcfa)</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {markets.map((market) => (
            <TodoItem
              market={market}
              // onToggle={onToggle}
              onDelete={onDelete}
              key={market.ref}
            />
          ))}
        </Table.Body>
      </Table>
    );
  } else {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          <Label color="red" size="large" pointing="below">
            Votre panier est vide pour le moment...
          </Label>
          <Image src="https://res.cloudinary.com/ivoire-book/image/upload/v1609167136/ivoire-book/design/logo11_12_14628_rilcvc.png" />
          <CathegorieLink />
        </Responsive>

        <Responsive minWidth={768}>
          <Grid columns="equal">
            <Grid.Column>
              <Image src="https://res.cloudinary.com/ivoire-book/image/upload/v1609167136/ivoire-book/design/logo11_12_14628_rilcvc.png" />
              <Label size="big" color="red" pointing>
                Votre panier est vide pour le moment!
              </Label>
            </Grid.Column>
            <Grid.Column width={8}>
              <CathegorieLink />
            </Grid.Column>
          </Grid>
          {/* <div style={{ height: "380px", width: "380px" }}>
            <Image src="https://res.cloudinary.com/ivoire-book/image/upload/v1609167136/ivoire-book/design/logo11_12_14628_rilcvc.png" />
          </div>
          <Label pointing>Votre pagnier est vide pour le Moment</Label> */}
        </Responsive>
      </div>
    );
  }
};

function TodoItem({ market, onDelete }) {
  return (
    <>
      <Table.Row>
        <Table.Cell>
          <Button
            basic
            color="red"
            compact
            size="mini"
            onClick={() => onDelete(market)}
          >
            retirer
          </Button>
        </Table.Cell>
        <Table.Cell>{market.titre}</Table.Cell>
        <Table.Cell>{market.price2}</Table.Cell>
      </Table.Row>
    </>
    // <Table.Row>
    //   <Table.Cell>
    //     <Button size="mini" onClick={() => onDelete(market)}>
    //       retirer
    //     </Button>
    //   </Table.Cell>
    //   <Table.Cell>{market.title}</Table.Cell>
    //   {/* <Table.Cell>{market.price}</Table.Cell> */}
    // </Table.Row>
    // <li>
    //   <label>
    //     <input
    //       type="checkbox"
    //       checked={market.completed}
    //       // onChange={() => onToggle(market)}
    //     />
    //     {market.title}  {market.price}
    //     <button onClick={() => onDelete(market)}>x</button>
    //   </label>
    // </li>
  );
}

export function Market({ markets, onToggle, onDelete }) {
  return (
    <ul>
      {markets.map((market) => (
        <TodoItem
          market={market}
          // onToggle={onToggle}
          onDelete={onDelete}
          key={market.id}
        />
      ))}
    </ul>
  );
}

const PriceBooks = ({ markets }) => {
  if (markets.length >= 1) {
    const allBookBuy = markets.reduce(function (acc, cur) {
      return acc + cur.price2;
    }, 0);
    return (
      <>
        <Grid>
          <Grid.Column verticalAlign="middle" textAlign="center" width={5}>
            totale:
          </Grid.Column>
          <Grid.Column floated="right" width={7}>
            <Label basic color="green" pointing>
              {`${allBookBuy} Fcfa`}
            </Label>
          </Grid.Column>
        </Grid>
        <Label style={{ marginTop: "10px" }} basic color="blue">
          NB: les livraisons ou expédition sont gratuites a partir de 3 livres.
        </Label>
        <ButtonValiderAchat markets={markets} facture={allBookBuy} />
      </>
    );
  } else {
    return "";
  }
};

export function marketStore() {
  const markets = useSelector(marketSelector);
  const dispatch = useDispatch();
  // const onToggle = useCallback(
  //   (market) => {
  //     dispatch(toggleMarketAction(market));
  //   },
  //   [dispatch]
  // );
  const onDelete = useCallback(
    (markets) => {
      dispatch(deleteMarketAction(markets));
    },
    [dispatch]
  );
  return (
    <div>
      <Helmet>
        <title> Panier d'achat</title>
      </Helmet>
      <Responsive {...Responsive.onlyMobile}>
        <Container>
          <div style={{ marginBottom: "55px" }}>
            <Mobileheader />

            <TableExampleApprove markets={markets} onDelete={onDelete} />
            {/* <MarketFilterStore /> */}
            {/* <AddMarketForm /> */}
            <PriceBooks markets={markets} />
          </div>
        </Container>
        <FooterMenu />
      </Responsive>
      <Responsive minWidth={768}>
        <Container>
          <div style={{ marginBottom: "10px", marginTop: "20px" }}>
            <BigMenu />
            <TableExampleApprove markets={markets} onDelete={onDelete} />
            {/* <MarketFilterStore /> */}
            {/* <AddMarketForm /> */}
            <PriceBooks markets={markets} />
          </div>
        </Container>
      </Responsive>
    </div>
  );
}

// export const marketStore = connect(
//   (state) => ({
//     markets: marketSelector(state),
//   }),
//   (dispatch) => ({
//     onToggle: market => dispatch(toggleMarketAction(market))
//   })
// )(market);
