import React from "react";
import Slider from "react-slick";
import { Image, Responsive } from "semantic-ui-react";

function SliderView() {
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 6000,
    autoplaySpeed: 15000
  };

  const settings2 = {
    infinite: true,
    autoplay: true,
    speed: 6000,
    autoplaySpeed: 15000
  };

  const MobileView = () => (
    <Slider {...settings}>
      <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <Image
          alt="ivoire-book"
          style={{ height: "200px", borderRadius: "25px", padding: "10px" }}
          src="https://res.cloudinary.com/ivoire-book/image/upload/v1609176157/ivoire-book/design/Sans_titre_4_ucgjej.png"
        />
      </div>
      <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <Image
          alt="ivoire-book"
          style={{ height: "200px", borderRadius: "25px", padding: "10px" }}
          src="https://res.cloudinary.com/ivoire-book/image/upload/v1609176159/ivoire-book/design/Sans_titre_5_gbofql.png"
        />
      </div>
      <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <Image
          alt="ivoire-book"
          rounded
          style={{ height: "200px", borderRadius: "25px", padding: "10px" }}
          src="https://res.cloudinary.com/ivoire-book/image/upload/v1609176158/ivoire-book/design/750-x-400-Salon-africain-Gen%C3%A8ve_yv6ama.png"
        />
      </div>
    </Slider>
  );

  const LargeScreenView = () => (
    <Slider {...settings2}>
      <div>
        <Image
          alt="sapologue_abidjan"
          style={{ height: "320px", width: "790px", borderRadius: "10px" }}
          src="https://res.cloudinary.com/ivoire-book/image/upload/v1609176157/ivoire-book/design/Sans_titre_4_ucgjej.png"
        />
      </div>
      <div>
        <Image
          alt="sapologue_abidjan"
          style={{ height: "320px", width: "790px", borderRadius: "10px" }}
          src="https://res.cloudinary.com/ivoire-book/image/upload/v1609176159/ivoire-book/design/Sans_titre_5_gbofql.png"
        />
      </div>
      <div>
        <Image
          alt="sapologue1"
          rounded
          style={{ height: "320px", width: "790px", borderRadius: "10px" }}
          src="https://res.cloudinary.com/ivoire-book/image/upload/v1609176158/ivoire-book/design/750-x-400-Salon-africain-Gen%C3%A8ve_yv6ama.png"
        />
      </div>
    </Slider>
  );

  return (
    <>
      <Responsive {...Responsive.onlyMobile}>
        <MobileView />
      </Responsive>

      <Responsive minWidth={768}>
        <LargeScreenView />
      </Responsive>
    </>
  );
}

export default SliderView;
