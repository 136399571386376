import React from "react";
import { Grid, Icon, Segment, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";

import firebase from "firebase";

// import Slider from "../slider";

function mobile() {
  return (
    <div style={{ marginTop: "10px", marginBottom: "15px" }}>
      <Grid columns="equal">
        <Grid.Column>
          <Link to="/">
            <Image
              circular
              alt="avatar ivoire-book"
              src="https://res.cloudinary.com/ivoire-book/image/upload/v1609167136/ivoire-book/design/logo11_12_14628_rilcvc.png"
              height="35px"
              width="35px"
            />
          </Link>
        </Grid.Column>
        <Grid.Column width={10} textAlign="center">
          <Segment secondary color="orange" raised style={{ padding: "5px" }}>
            Ivoire-Book
          </Segment>
        </Grid.Column>
        <Grid.Column>
          {/* <Icon name="shopping bag" size="large" /> */}
          {firebase.auth().currentUser ? (
            <Link to="/profil" style={{ textDecoration: "none" }}>
              <Image
                size="mini"
                circular
                alt="user avatar"
                src={firebase.auth().currentUser.photoURL}
              />
            </Link>
          ) : (
            <Link to="/inscription" style={{ textDecoration: "none" }}>
              <Icon name="user" size="big" />
            </Link>
          )}
        </Grid.Column>
      </Grid>
    </div>
  );
}

export default mobile;
