
const config = {
    apiKey: "AIzaSyCF-iL-Vr-2qpDHvXbJV6wvkcdbEiodkdw",
    authDomain: "ivoire-book.firebaseapp.com",
    databaseURL: "https://ivoire-book.firebaseio.com",
    projectId: "ivoire-book",
    storageBucket: "ivoire-book.appspot.com",
    messagingSenderId: "115146406016",
    appId: "1:115146406016:web:4c8ec27aecb6244a084e65",
    measurementId: "G-YQM3X8349L"
  };

// class firebase {
//     constructor() {
//         app.initializeApp(config)
//     }
// }

export default config;