import React from "react";
import { Icon, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";

import MarketButon from "../fotterMenu/marketButton";
import firebase from "firebase";

function MenuExampleInverted(props) {
  // state = { activeItem: "home" };

  // handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  // const DropdownExamplePointing = () => (
  //   <Button.Group color="orange">
  //     {/* <Button>Save</Button> */}
  //     <Dropdown text="Menu" floating labeled className="button icon">
  //       <Dropdown.Menu>
  //         <Dropdown.Header>
  //           <Link to="/">Acceille</Link>
  //         </Dropdown.Header>
  //         {/* <Dropdown.Item>
  //           <Dropdown text="Clothing">
  //             <Dropdown.Menu>
  //               <Dropdown.Header>Mens</Dropdown.Header>
  //               <Dropdown.Item>Shirts</Dropdown.Item>
  //               <Dropdown.Item>Pants</Dropdown.Item>
  //               <Dropdown.Item>Jeans</Dropdown.Item>
  //               <Dropdown.Item>Shoes</Dropdown.Item>
  //               <Dropdown.Divider />
  //               <Dropdown.Header>Womens</Dropdown.Header>
  //               <Dropdown.Item>Dresses</Dropdown.Item>
  //               <Dropdown.Item>Shoes</Dropdown.Item>
  //               <Dropdown.Item>Bags</Dropdown.Item>
  //             </Dropdown.Menu>
  //           </Dropdown>
  //         </Dropdown.Item> */}
  //         <Dropdown.Item>
  //           <Link to="/recherche-livres">Recherche</Link>
  //         </Dropdown.Item>
  //         <Dropdown.Divider />
  //         <Dropdown.Header>Apropos</Dropdown.Header>
  //       </Dropdown.Menu>
  //     </Dropdown>
  //   </Button.Group>
  // );

  return (
    <div style={{ position: "fixed", top: "10px", left: "10px" }}>
      <Link to="/">
        <Image
          circular
          alt="avatar ivoire-book"
          src="https://res.cloudinary.com/ivoire-book/image/upload/v1609167136/ivoire-book/design/logo11_12_14628_rilcvc.png"
          style={{
            position: "fixed",
            top: "10px",
            left: "12px",
            height: "60px",
            width: "60px",
            zIndex: 1
          }}
        />
      </Link>

      {!props.game ? (
        <Link to="/recherche-livres">
          <Icon
            style={{
              position: "fixed",
              bottom: "30px",
              left: "10px",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
            }}
            color="orange"
            size="large"
            name="search"
            circular
          />
        </Link>
      ) : (
        ""
      )}

      {/* <DropdownExamplePointing /> */}
      {/* <Menu.Item
          name='home'
          active={activeItem === 'home'}
          onClick={this.handleItemClick}
        />
        <Menu.Item
          name='messages'
          active={activeItem === 'messages'}
          onClick={this.handleItemClick}
        />
        <Menu.Item
          name='friends'
          active={activeItem === 'friends'}
          onClick={this.handleItemClick}
        /> */}
      {/* <Button
          
          circular
          size="huge"
          icon="user circle"
          color="orange"
        /> */}

      {firebase.auth().currentUser ? (
        <Link to="/inscription">
          <Image
            circular
            alt="user avatar"
            src={firebase.auth().currentUser.photoURL}
            style={{
              position: "fixed",
              top: "10px",
              right: "10px",
              height: "50px",
              width: "50px"
            }}
          />
        </Link>
      ) : (
        <Link to="/inscription">
          <Icon
            style={{
              position: "fixed",
              top: "10px",
              right: "10px",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
            }}
            color="orange"
            size="large"
            name="user circle"
            circular
          />
        </Link>
      )}

      {!props.game ? (
        <MarketButon
        // style={{ position: "fixed", bottom: "30px", right: "30px" }}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default MenuExampleInverted;
