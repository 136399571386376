import { combineReducers, createStore } from "redux"
import { marketReducer } from "./marketReducer"
import { filterReducer } from "./filterReducer"

export default createStore(
    combineReducers({
        markets: marketReducer,
        filter: filterReducer
    }),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
