import React from "react";
import { Responsive, Grid } from "semantic-ui-react";

import MobileFotter from "./mobile";

function index() {
  return (
    <>
      <Responsive {...Responsive.onlyMobile}>
        <Grid textAlign="center" columns={3}>
          <MobileFotter />
        </Grid>
      </Responsive>
      <Responsive minWidth={768}></Responsive>
    </>
  );
}

export default index;
