import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Image,
  Segment,
  Grid,
  Tab,
  Table,
  Rating,
  Placeholder,
  Card,
} from "semantic-ui-react";
import Markdown from "markdown-to-jsx";
import firebase from "firebase";
import "firebase/firestore";
import { useParams } from "react-router-dom";
import NumberFormat from "react-number-format";
import slugify from "slugify";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { FacebookIcon, TwitterIcon } from "react-share";
// import { FacebookProvider, Like } from "react-facebook";

import Mobileheader from "../headerMenu/mobile";
import { AddMarketButton } from "../ajouterAupanier";
import GetBookCathegorie from "../getBookCathegorie";

const db = firebase.firestore();

const PlaceholderExampleHeaderImage = () => (
  <Placeholder>
    <Placeholder>
      <Placeholder.Header image>
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Header>
      <Placeholder.Paragraph>
        <Placeholder.Line length="medium" />
        <Placeholder.Line length="short" />
      </Placeholder.Paragraph>
    </Placeholder>
  </Placeholder>
);

function book() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let { ref } = useParams();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [data, setData] = useState(null);
  const livre = (id) => db.doc(`livres/${id}`);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    livre(ref)
      .get()
      .then((doc) => {
        if (doc && doc.exists) {
          const myData = doc.data();
          setData(myData);
          document.title = `livre : ${myData.titre} | auteur : ${myData.auteur}`;
        }
      });
    // .catch((error) => console.log(error));
  }, [ref]);

  const titleF = data ? `#${slugify(data.titre, "_")}` : "#ivoirebook";

  const Share = () => (
    <div>
      <FacebookShareButton
        url={"https://ivoire-book.com"}
        quote={data ? data.resumer : "a lire absolument"}
        hashtag={titleF}
        description={"ivoire-book"}
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton
        title={"test"}
        url={"https://ivoire-book.com/"}
        hashtags={["ivoireBook", "titleF"]}
      >
        <TwitterIcon size={32} round />
      </TwitterShareButton>
    </div>
  );

  const Detail = () => (
    <Table unstackable>
      <Table.Body>
        <Table.Row>
          <Table.Cell collapsing>Parution </Table.Cell>
          <Table.Cell textAlign="right">{data.parution}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Editeur</Table.Cell>
          <Table.Cell textAlign="right">{data.editeur}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Dimensions</Table.Cell>
          <Table.Cell textAlign="right">{data.format}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell collapsing>Nombre de pages</Table.Cell>
          <Table.Cell textAlign="right">{data.pages}</Table.Cell>
        </Table.Row>
        {data.intro ? (
          <Table.Row>
            <Table.Cell collapsing>Extrait</Table.Cell>
            <Table.Cell textAlign="right">
              <a href={data.intro} target="_blank" rel="noopener noreferrer">
                telecharger le PDF
              </a>
            </Table.Cell>
          </Table.Row>
        ) : (
          ""
        )}
      </Table.Body>
    </Table>
  );
  const panes = [
    {
      menuItem: { key: "1", icon: "book", content: "Résumé" },
      render: () => (
        <Tab.Pane attached={false}>
          <Markdown>{`${data.resumer}`}</Markdown>
          <br /> <br />
          <div>
            {" "}
            <Share />{" "}
          </div>
          {/* {data ? <AddMarketButton market={data} /> : ""} */}
          {/* <Segment style={{ marginBottom: "40px" }}>Pellentesque habitant morbi tristique senectus.</Segment> */}
        </Tab.Pane>
      ),
    },
    {
      menuItem: { key: "2", icon: "list ul", content: "Détails" },
      render: () => (
        <Tab.Pane attached={false}>
          <Detail />
          {/* <Segment>Pellentesque habitant morbi tristique senectus.</Segment> */}
        </Tab.Pane>
      ),
    },
    {
      menuItem: { key: "3", icon: "users", content: "similaire" },
      render: () => (
        <Tab.Pane attached={false}>
          <GetBookCathegorie cathego={data.cathegorie} />
        </Tab.Pane>
      ),
    },
  ];

  const DetailTab = () => (
    <Tab
      menu={{ color: "blue", secondary: true, pointing: true }}
      panes={panes}
    />
  );

  return (
    <div>
       <Helmet>
        <meta charSet="utf-8" />
        <title>{data ? data.titre : ''}</title>
        <meta name="description" content={data ? data.resumer.substring(1, 500) : ''} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Mobileheader />

      {data ? (
        // <Image fluid style={{ height: "320px" }} rounded src={data.image} />
        <Grid>
          <Grid.Column floated="left" width={8}>
            {/* <Card raised style={{ height: "230px" }} image={data.image} /> */}
            <Image
              fluid
              alt={`couverture ${data.title} de ${data.auteur}`}
              style={{
                height: "250px",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
              src={data.image}
            />
          </Grid.Column>
          <Grid.Column floated="right" width={8}>
            <Card.Content>
              <Card.Header>
                <h1 style={{ fontSize: "15px" }}>{data.titre}</h1>
              </Card.Header>
              <Card.Meta>{data.auteur}</Card.Meta>
              <br />
              <Rating icon="star" defaultRating={4} maxRating={5} size="mini" />
              <Card.Meta>{data.cathegorie.join(" | ")}</Card.Meta>
              <Card.Meta>{data.pages} pages</Card.Meta>
              <Card.Meta> parution {data.parution}</Card.Meta>
            </Card.Content>
          </Grid.Column>
          {/* <FacebookProvider appId="320050232708180">
          <Like
            href="http://localhost:3000/"
            colorScheme="dark"
            showFaces
            share
          />
        </FacebookProvider> */}
        </Grid>
      ) : (
        <Placeholder style={{ height: "320px" }}>
          <Placeholder.Image />
        </Placeholder>
      )}

      <Segment style={{ marginTop: "35px" }} size="mini" raised secondary>
        {data ? (
          <Grid>
            <Grid.Column floated="left" width={3}>
              {data.auteurImage ? (
                <Image
                  alt={`auteur ${data.auteur}`}
                  avatar
                  src={data.auteurImage}
                  size="large"
                />
              ) : (
                ""
              )}
            </Grid.Column>
            <Grid.Column
              style={{ padding: "14px 0px" }}
              textAlign="center"
              verticalAlign="middle"
              width={9}
            >
              {data ? <h1 style={{ fontSize: "13px" }}>{data.titre}</h1> : ""}
              {data ? <h3 style={{ fontSize: "13px" }}>{data.auteur}</h3> : ""}
            </Grid.Column>
            <Grid.Column
              style={{ padding: "14px 5px" }}
              textAlign="center"
              verticalAlign="middle"
              floated="right"
              width={4}
            >
              {data ? (
                <NumberFormat
                  value={data.price2}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  allowNegative={false}
                  suffix={" Fcfa"}
                  renderText={(value) => (
                    <div style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {value}
                    </div>
                  )}
                />
              ) : (
                ""
              )}
            </Grid.Column>
          </Grid>
        ) : (
          <PlaceholderExampleHeaderImage />
        )}

        {data ? <AddMarketButton market={data} /> : ""}

        {/* title={data.titre} price={data.price2} id={data.ref} */}

        {/* <Button.Group style={{ marginTop: "5px" }} fluid>
          <Button color="red">acherter</Button>
          <Button.Or />
          <Button positive>Ajouter au panier</Button>
        </Button.Group> */}

        {/* <Button style={{ marginTop: "5px" }} basic color="red" fluid>
          <Icon name="shopping bag" /> Acheter
        </Button> */}
      </Segment>
      {/* <TabExampleSecondaryPointing /> */}
      {data ? <DetailTab /> : ""}
      <Segment style={{ paddingBottom: "40px" }}></Segment>

      {/* {data ? (
        <Button style={{ marginTop: "5px" }} basic color="red" fluid>
          <Icon name="shopping bag" />
          Ajouter au panier
        </Button>
      ) : (
        ""
      )} */}
    </div>
  );
}

export default book;
