import { UPDATE_MARKET_ACTION, DELETE_MARKET_ACTION, ADD_MARKET_ACTION, DELETE_ALL_MARKET_ACTION } from "./marketReducer";

export const toggleMarketAction = (market) => ({
    type: UPDATE_MARKET_ACTION,
    payload: {...market, completed: !market.completed}
})

export const deleteMarketAction = (market) => ({
    type: DELETE_MARKET_ACTION,
    payload: market.ref
})

export const addMarketAction = (market) => ({
    type: ADD_MARKET_ACTION,
    payload: {...market}
})

export const deleteAllMarketAction = (market) => ({
    type: DELETE_ALL_MARKET_ACTION,
    // payload: {...market}
})