import React, { useState, useEffect } from "react";
import {
  Container,
  Responsive,
  Grid,
  Placeholder,
  Image,
  Card,
  Feed,
  Label
} from "semantic-ui-react";
import { Helmet } from "react-helmet";

import firebase from "firebase";
import "firebase/firestore";

import BigMenu from "../components/headerMenu";
import FooterMenu from "../components/fotterMenu";

const db = firebase.firestore();

function Ebook() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [data, setData] = useState(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    // const user = firebase.auth().currentUser;
    // const email = user.email;

    db.collection("notes")
      .get()
      .then((querySnapshot) => {
        const values = querySnapshot.docs.map((doc) => doc.data());
        setData(values);
      });
    // .catch(function (error) {
    //   console.log(error);
    // });
  }, []);

  const PlaceholderView = (width1, width2) => (
    <>
      <Grid.Column width={width1} textAlign="center">
        <Placeholder style={{ height: 150, width: "100%" }}>
          <Placeholder.Image />
        </Placeholder>
      </Grid.Column>
      <Grid.Column width={width1} textAlign="center">
        <Placeholder style={{ height: 150, width: "100%" }}>
          <Placeholder.Image />
        </Placeholder>
      </Grid.Column>
      <Grid.Column width={width1} textAlign="center">
        <Placeholder style={{ height: 150, width: "100%" }}>
          <Placeholder.Image />
        </Placeholder>
      </Grid.Column>
      <Grid.Column width={width1} textAlign="center">
        <Placeholder style={{ height: 150, width: "100%" }}>
          <Placeholder.Image />
        </Placeholder>
      </Grid.Column>
    </>
  );

  return (
    <div>
      <Helmet>
        <title>Ebook | Ivoire-Book </title>
      </Helmet>
      <Responsive {...Responsive.onlyMobile}>
        <BigMenu />
        <Container>
          <Grid columns={2} padded="vertically">
            {data ? (
              data.map((notes) => (
                <Grid.Column
                  key={notes.created}
                  // onClick={() => selected(livre.titre, livre.ref)}
                >
                  <Card raised>
                    <Card.Content>
                      <Image
                        style={{
                          position: "absolute",
                          left: "35%",
                          top: "-15%"
                        }}
                        floated="right"
                        size="mini"
                        src={notes.userImage}
                        circular
                      />
                      <Card.Header>{notes.bookTitre}</Card.Header>
                      <Card.Meta>{notes.userName}</Card.Meta>
                      <Card.Description>{notes.note}</Card.Description>
                    </Card.Content>
                    <Label as="a" pointing>
                      commentaire
                    </Label>
                  </Card>

                  {/* <h2 style={{ fontSize: "12px" }}>{notes.note}</h2>
                  <Image
                    style={{
                      height: "350px",
                      width: "100%",
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                    }}
                    src={notes.illustation}
                  /> */}
                </Grid.Column>
              ))
            ) : (
              <PlaceholderView width1={"2"} />
            )}
          </Grid>
        </Container>
        <FooterMenu />
      </Responsive>
      <Responsive minWidth={768}>
        <BigMenu />
        <Container textAlign="center">
          {/* <Segment style={{ marginTop: "40px" }} placeholder>
            <Header icon>
              <Icon loading size="massive" name="cog" />
              page en developement ! bientôt disponible.
            </Header>
          </Segment> */}

          <Grid columns={4} padded="vertically">
            {data ? (
              data.map((notes) => (
                <Grid.Column
                  key={notes.created}
                  width={4}
                  textAlign="center"
                  // onClick={() => selected(livre.titre, livre.ref)}
                >
                  <Card
                    style={{
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                    }}
                  >
                    <Image
                      style={{
                        boxShadow:
                          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                      }}
                      src={notes.illustation}
                      wrapped
                      ui={false}
                    />
                    <Card.Content>
                      <Card.Description style={{ fontSize: "12px" }}>
                        {notes.note}
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                      <Feed>
                        <Feed.Event>
                          <Feed.Label image={notes.userImage} />
                          <Feed.Content>
                            {/* <Feed.Date content="1 day ago" /> */}
                            <Feed.Summary>{notes.userName}</Feed.Summary>
                          </Feed.Content>
                        </Feed.Event>
                      </Feed>
                    </Card.Content>
                  </Card>

                  {/* <h2 style={{ fontSize: "12px" }}>{notes.note}</h2>
                  <Image
                    style={{
                      height: "350px",
                      width: "100%",
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                    }}
                    src={notes.illustation}
                  /> */}
                </Grid.Column>
              ))
            ) : (
              <PlaceholderView width1="2" />
            )}
          </Grid>
        </Container>
      </Responsive>
    </div>
  );
}

export default Ebook;
