import React from "react";
import { Container, Responsive } from "semantic-ui-react";
import { Helmet } from "react-helmet";

import BigMenu from "../components/headerMenu";
import NewSearch from "../components/getAlldata";
import FooterMenu from "../components/fotterMenu";

function search() {
  return (
    <div>
      <Helmet>
        <title> Recherche de Livres </title>
      </Helmet>
      <Responsive {...Responsive.onlyMobile}>
        <Container>
          <BigMenu />
          <NewSearch />
          {/* <SearchBar /> */}
        </Container>
        <FooterMenu />
      </Responsive>
      <Responsive minWidth={768}>
        <BigMenu />
        <NewSearch />
      </Responsive>
    </div>
  );
}

export default search;
