import React from "react";
import { Responsive } from "semantic-ui-react";

import MobileMenu from "./mobile";
import ComputerMenu from "./computer";

const ResponsiveExampleBreakpoints = (props) => (
  <>
    <Responsive {...Responsive.onlyMobile}>
      <MobileMenu />
    </Responsive>
    <Responsive {...Responsive.onlyTablet}>
      <ComputerMenu game={props.game} />
    </Responsive>
    <Responsive {...Responsive.onlyComputer}>
      <ComputerMenu game={props.game} />
    </Responsive>
  </>
);

export default ResponsiveExampleBreakpoints;
