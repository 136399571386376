import React, { Component } from "react";
import { Grid} from "semantic-ui-react";

import SliderView from "../slider";
import CathegorieView from "../cathegorieView"

export default class MenuExampleTabularOnLeft extends Component {
  state = { activeItem: null };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const { activeItem } = this.state;

    const View = () => {
      if (activeItem === "politique") {
        return "politique";
      } else if (activeItem === "marketing") {
        return "marketing";
      } else if (activeItem === "dev") {
        return "developement personnel";
      }
      return <SliderView />;
    };

    return (
      <Grid style={{ marginTop: "20px" }}>
        <Grid.Column style={{ marginTop: "10px" }} width={4}>
          {/* <Menu inverted pointing vertical>
            <Menu.Item
              name="dev"
              active={activeItem === "dev"}
              onClick={this.handleItemClick}
            >
              developement personelle
            </Menu.Item>
            <Menu.Item
              name="messages"
              active={activeItem === "messages"}
              onClick={this.handleItemClick}
            >
              messages
            </Menu.Item>
            <Menu.Item
              name="science"
              active={activeItem === "science"}
              onClick={this.handleItemClick}
            >
              science
            </Menu.Item>
            <Menu.Item
              name="politique"
              active={activeItem === "politique"}
              onClick={this.handleItemClick}
            >
              politique
            </Menu.Item>
            <Menu.Item
              name="marketing"
              active={activeItem === "marketing"}
              onClick={this.handleItemClick}
            >
              marketing
            </Menu.Item>
          </Menu> */}
          <CathegorieView segmented={2} />
            {/* <Grid.Column style={{ padding: "5px" }}>
              <Link to="catalogue/développement">
                <Button color="red" fluid circular compact>
                  developement personel
                </Button>
              </Link>
            </Grid.Column>
            <Grid.Column style={{ padding: "5px" }}>
              <Link to="catalogue/marketing">
                <Button color="green" fluid circular compact>
                  Marketing, Vente
                </Button>
              </Link>
            </Grid.Column>
            <Grid.Column style={{ padding: "5px" }}>
              <Link to="catalogue/philosophie">
                <Button color="orange" fluid circular compact>
                  Sciences politiques et société
                </Button>
              </Link>
            </Grid.Column>
            <Grid.Column style={{ padding: "5px" }}>
              <Link to="catalogue/economie">
                <Button color="yellow" fluid circular compact>
                  Économie, finance et Comptabilité
                </Button>
              </Link>
            </Grid.Column>
            <Grid.Column style={{ padding: "5px" }}>
              <Link to="catalogue/leadership">
                <Button color="teal" fluid circular compact>
                  Leadership
                </Button>
              </Link>
            </Grid.Column>
            <Grid.Column style={{ padding: "5px" }}>
            <Link to="catalogue/communication">
              <Button color="purple" fluid circular compact>
                Communication
              </Button>
              </Link>
            </Grid.Column>
            <Grid.Column style={{ padding: "5px" }}>
            <Link to="catalogue/spiritualiter">
              <Button color="pink" fluid circular compact>
                Religions et spiritualiter
              </Button>
              </Link>
            </Grid.Column>
            <Grid.Column style={{ padding: "5px" }}>
            <Link to="catalogue/langue">
              <Button color="olive" fluid circular compact>
                Langue
              </Button>
              </Link>
            </Grid.Column>
            <Grid.Column style={{ padding: "5px" }}>
            <Link to="catalogue/roman">
              <Button color="black" fluid circular compact>
                Roman et literatures
              </Button>
              </Link>
            </Grid.Column> */}
        </Grid.Column>

        <Grid.Column style={{ padding: "8px" }} stretched width={12}>
          {/* <Segment> */}
          <View />
          {/* </Segment> */}
        </Grid.Column>
      </Grid>
    );
  }
}
