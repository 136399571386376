import React, { useState, useEffect } from "react";
import {
  Grid,
  Image,
  Container,
  Header,
  Icon,
  Responsive,
  Segment,
  Card,
  Modal,
  Button
} from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import "firebase/firestore";
// import firebase from "firebase";

import GameBook from "../components/quizz";
import Mobileheader from "../components/headerMenu/mobile";

// const db = firebase.firestore();

function bookGameHome() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const [data, setData] = useState(null);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [open, setOpen] = useState(false);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const [open, setOpen] = useState(false);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    // db.collection("bages")
    //   .where("owner", "==", "draboabdelkader28@gmail.com")
    //   .get()
    //   .then((querySnapshot) => {
    //     const values = querySnapshot.docs.map((doc) => doc.data());
    //     console.log(values);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }, []);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  let history = useHistory();

  const FetchQuiz = GameBook[0].lautoroute_du_milonnaire;
  const book = FetchQuiz.lienImage;
  const BageTitre = FetchQuiz.bageTitre;

  const dev = true;

  const CardExampleImageCard = () => (
    <Card
      color="blue"
      onClick={() => history.push("/gameBook/lautoroute_du_milonnaire")}
    >
      <Card.Content>
        <Image floated="right" size="mini" src={book} />
        <Card.Header>L'Alchimiste</Card.Header>
        <Card.Meta>Livre de Paulo Coelho</Card.Meta>
        <Card.Description>
          L'histoire est celle de Santiago, jeune berger andalou parti à la
          recherche d'un trésor enfoui au pied des pyramides. Dans le désert,
          initié par l'alchimiste, il apprendra à écouter son cour et à
          déchiffrer les signes du destin. la clef d'une quête spirituelle que
          chacun de nous peut entreprendre, l'invitation à suivre son rêve pour
          y trouver sa vérité. <br /> au bout de cette quête se trouvre la
          competence:
          <strong style={{ color: "red" }}> {BageTitre}</strong>
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button fluid>commencer la quête</Button>
      </Card.Content>
    </Card>
  );

  const MonileViews = () => (
    <Modal
      closeIcon
      open={open}
      trigger={
        <Segment raised style={{ margin: "10px" }}>
          <Grid>
            <Grid.Column floated="left" width={6}>
              <Image size="medium" src={book} />
            </Grid.Column>
            <Grid.Column floated="right" width={10}>
              <p>
                L'histoire est celle de Santiago, jeune berger andalou parti à
                la recherche d'un trésor enfoui au pied des pyramides.
                <br />
                <br /> au bout de cette quête se trouvre la competence:
                <strong style={{ color: "red" }}> {BageTitre}</strong>
              </p>
            </Grid.Column>
          </Grid>
        </Segment>
      }
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Modal.Content>
        <p>
          L'histoire est celle de Santiago, jeune berger andalou parti à la
          recherche d'un trésor enfoui au pied des pyramides. Dans le désert,
          initié par l'alchimiste, il apprendra à écouter son cour et à
          déchiffrer les signes du destin. la clef d'une quête spirituelle que
          chacun de nous peut entreprendre, l'invitation à suivre son rêve pour
          y trouver sa vérité. <br /> au bout de cette quête se trouvre la
          competence:
          <strong style={{ color: "red" }}> {BageTitre}</strong>
        </p>
      </Modal.Content>
      <Modal.Actions as="div">
        <Button color="red" onClick={() => setOpen(false)}>
          <Icon name="remove" /> No
        </Button>
        <Button
          color="green"
          onClick={() => history.push("/gameBook/lautoroute_du_milonnaire")}
        >
          <Icon name="checkmark" /> commencer la quête
        </Button>
      </Modal.Actions>
    </Modal>
  );

  return (
    <div>
      {!dev ? (
        <Container textAlign="center">
          <Segment style={{ marginTop: "40px" }} placeholder>
            <Header icon>
              <Icon loading size="massive" name="cog" />
              page en developement ! bientôt disponible.
            </Header>
          </Segment>
        </Container>
      ) : (
        <>
          <Responsive {...Responsive.onlyMobile}>
            <Container>
              <Mobileheader />
              <Grid columns={2}>
                <MonileViews />
              </Grid>
            </Container>
          </Responsive>

          <Responsive minWidth={768}>
            <Container>
              <Mobileheader />
              <Grid columns={3}>
                <Grid.Row>
                  <Grid.Column>
                    <CardExampleImageCard />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Responsive>
        </>
      )}
    </div>
  );
}

export default bookGameHome;
