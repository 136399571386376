export default [
  {
    fournisseur: "ivioire-book",
    sujet: "book-game",

    lautoroute_du_milonnaire: {
      titre: "L'Alchimiste",
      auteur: "Paulo Coelho",
      resumer:
        " « Quand tu veux quelque chose, tout l’Univers conspire à te permettre de réaliser ton désir » ploger dan la vie de santigo jeune berger parti à la recherche d'un trésor enfoui au pied des pyramides",
      lienAchat:
        "https://www.ivoire-book.com/livres/L'Alchimiste/aseTTM2dWuOFKnP1tBcL",
      lienImage:
        "https://res.cloudinary.com/ivoire-book/image/upload/v1615652569/ivoire-book/livre/LAlchimiste_ocn5kt.jpg",
      profondeur: 40,
      exlusion: [
        "A2",
        "A3",
        "A12",
        "A132",
        "A1312",
        "A131111",
        "A131112",
        "A1311131",
        "A1311133",
        "A131113211",
        "A1311132132",
        "A13111321312",
        "A131113213112",
        "A131113213111222",
        "A131113213111223",
        "A1311132131112211",
        "A1311132131112212",
        "A131113213111221311",
        "A13111321311122131212",
        "A13111321311122131222",
        "A131113213111221312211",
        "A131113213111221312212",
        "A1311132131112213122132",
        "A1311132131112213122133",
        "A13111321311122131221311",
        "A1311132131112213122131221",
        "A131113213111221312213122222",
        "A13111321311122131221312223",
        "A131113213111221312213122211",
        "A1311132131112213122131222121",
        "A13111321311122131221312221222",
        "A131113213111221312213122212212",
        "A1311132131112213122131222122111",
        "A13111321311122131221312221221121",
        "A131113213111221312213122212211221",
        "A13111321311122131221312221221122211",
        "A131113213111221312213122212211222122",
        "A1311132131112213122131222122112221212"
      ],
      jointure: [
        "A11",
        "A131121",
        "A131122",
        "A131113212",
        "A1311132131111",
        "A13111321311121",
        "A13111321311122132",
        "A13111321311122131211",
        "A131113213111221312213122221"
      ],
      pause: [
        "A13111322",
        "A131113213111221312213121",
        "A1311132131112213122131222122112222"
      ],
      lien: {
        A11: "A13",
        A131121: "A13111",
        A131122: "A13111",
        // A131113212: "A131113213",
        A131113212: "A1311132131",
        A1311132131111: "A1311132131112",
        A13111321311121: "A13111321311122",
        A13111321311122132: "A13111321311122131",
        A13111321311122131211: "A1311132131112213122",
        A131113213111221312213122221: "A13111321311122131221312221"
      },
      end: "A1311132131112213122131222122112221211",
      bageTitre: "Apprenti alchimiste",
      BageImage:
        "https://cdn-academywww.pressidium.com/wp-content/uploads/GameDesign_Best-Online-Bachelors-Degree-Badge.png",

      A: {
        question: `<p> Santiago est intriguer car cela fais plusieurs nuit que le jeune berger fait le même rêve ; ou un petit garçon lui prenait par la main et le conduisait jusqu’aux pyramide d’Egypte et dit ceci : « si tu viens jusqu’ ici tu trouveras un trésor cache ». Pour élucidé ce mystère il décide de consulter une vielle femme qui savait interpréter les rêves.</p>👵🏾***- la vielle :*** ton rêve est difficile à interprétez ; avant jure moi que tu me donneras la dixième partie de ton trésor en échange de ce que je te dirai ?`,
        lecon: "une lecon tires du livre lautoroute du milionnaire",
        illustration:
          "https://s3.eu-west-3.amazonaws.com/pop-phototeque/joconde/07480002658/0014106.jpg",
        A1: "j’accepte la condition.",
        A2: "je refuse, pas besoin de vos interprétation ; je vais aller directement en Egypte.",
        A3: "ne vous fatiguer, pas de toutes les façons je préfère cette vie que j’ai actuellement pas besoin de trésor."
      },

      A2: {
        lecon: `« Le succès dépend de la préparation, et sans une telle préparation, il y aura certainement un échec. » Confucius.`
      },

      A3: {
        lecon: `« Le fer se rouille, faute de s’en servir, l’eau stagnante perd de sa pureté et se glace par le froid. De la même, l’inaction sape la vigueur de l’esprit » Léonard De Vinci.<br/><br/>Si vous été satisfait de votre situation actuelle alors temps mieux pour vous.`
      },

      A1: {
        question: `👵🏾***-la vielle :*** le songe signifie que tu dois te rendre en Egypte ou un trésor t attend. <br />
        👨🏾‍🦱***- le berger :*** si c’est simplement ca je n’avais pas besoin de perdre mon temps avec vous.<br />
        👵🏾***- la vielle :*** tu vois ! Je t’avais bien dit que ton rêve était difficile à interpréter. Les choses simples sont les plus extraordinaires, et seuls les savants parviennent à les voir.<br />
        👨🏾‍🦱***- le berger :*** et comment vais-je faire pour aller jusqu’en Egypte ?<br />
        👵🏾***- la vielle :*** je ne fais qu’interpréter les songes. Il n’est pas de mon pouvoir de les transformer en réalité.<br /><br />
       <p> Le berger s’en alla, déçu, et bien décide à ne plus jamais croire aux songes.<br /><br /> Un jour un Veil homme vint s’assoir à cote de lui et écrire sur le sol, le berger arrive à lire le nom de son père, de sa mère ; il lut l’histoire de sa vie jusqu’à ce instant.</p>
       🧙‍♂***-Vieillard :*** demain, à cette même heure, tu m’amèneras un dixième de ton troupeau. Je t’indiquerai comment réussir à trouver le trésor cache.`,
        lecon: "",
        illustration:
          "https://previews.123rf.com/images/samakar/samakar1706/samakar170600505/80414326-dessin-d-une-vieille-femme-de-d%C3%A9tente-dans-le-parc-vectoriel-.jpg",
        A11: "Vous etes surement le marie de la vielle femme et vous voulez m escroquer",
        A12: "Si ce trésor fait partie de ma destiner alors tôt ou tard il sera à moi, et sans votre aide.",
        A13: "Qui êtes-vous au juste ?"
      },

      A12: {
        lecon: `« Sème un acte, tu récolteras une habitude, sème une habitude, tu récolteras un caractère, sème un caractère, tu récolteras une destinée. »  Dalaï lama`
      },

      A13: {
        question: `🧙‍♂*** Vieillard :*** Je m’appelle Melchisédech ; roi de Salem.<br />
        👨🏾‍🦱*** Berger :*** Pourquoi un roi bavarde-t-il avec un berger ?<br />
        🧙‍♂*** Vieillard :*** il y a plusieurs raison à cela, mais la plus importante est que tu as été capable d’accomplir ta légende personnelle.<br />
        👨🏾‍🦱*** Berger :*** qu’est-ce que la légende personnelle ?<br />
        🧙‍♂*** Vieillard :*** c’est ce que tu as toujours souhaité faire. Chacun de nous, en sa prime jeunesse, sait quelle est sa Légende Personnelle. A cette époque de la vie, tout est claire, tout est possible, et l’on n’a pas peur de rêver et de souhaiter tout ce qu’on aimerait faire de sa vie. Cependant, à mesure que le temps s’écoule, une force mystérieuse commence à essayer de prouver qu’il est impossible de réaliser sa Légende Personnelle. Acceptes-tu mon offre ?`,
        lecon: "",
        illustration:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQclWKkcAMSjT-bXqOoP96KGzDdn-munxabtw&usqp=CAU",
        A131: "oui, je vous fais confiance.",
        A132: "Non, pas question d’abandonner ce que j’ai pour quelle que chose que je ne  possède pas encore !"
      },

      A132: {
        lecon:
          "« pour obtenir quelque chose que vous n’avez jamais obtenu, vous devez absolument faire des choses que vous n’avez jamais faites auparavant »"
      },

      A131: {
        question: `🧙‍♂***Vieillard :*** Le trésor ce trouve en Egypte, près des pyramides ; et cela tu le savais déjà, pour arriver jusqu’au trésor, il faudra que tu sois attentif au signes. Dieu a écrit dans le monde le chemin que chacun de nous doit suivre ; il n’y a qua lire ce qu’il a écrit pour toi. 
        Tiens ces deux pierre elles se nomment Ourim et Toumim, la noire veut dire Oui et la blanche signifie Non ; quand tu ne parviendras pas à repérer les signe elles te serviront ; d une façon générale cherche à prendre tes décisions par toi-même. Le trésor se trouver près des pyramides, et cela tu le savais déjà ; mais tu as dû payer le prix de six mouton parce que c’est moi qui t’ai aidé à prendre une décision. Dorénavant tu prendras tes décisions toi-même ; Tu as ma bénédiction et n oubli pas que lorsqu’on veut vraiment quelque chose, tout l’univers conspire en votre faveur.`,
        lecon: "",
        illustration:
          "https://www.coloriageetdessins.com/images/autres/egypte/egypte-pyramide-maternelle-27862-660x400.jpg",
        A1311:
          "vendre le reste de son troupeau et prendre un bateau pour l’Egypte.",
        A1312: "retourner dans votre pays avec le reste du troupeau."
      },

      A1312: {
        lecon:
          "« Bonne est l’action qui n’amène aucun regret et dont le fruit est accueilli avec joie et sérénité. » Bouddha<br/>Il est plus facile de vivre avec une déception qu’avec du regret."
      },

      A1311: {
        question: `<p>Arriver en Afrique le berger se retrouver dans un pays bien différent du sien ou tout le monde parle l’arabe. Un jeune homme  s’approche et parler en espagnole.</p>👳🏽***- jeune homme :*** qui est tu et que fais-tu ici ?<br />
        👨🏾‍🦱*** Berger :*** je viens d’Espagne et je veux me rendre au pyramide, je voudrai que tu m’emmène là-bas, si c’est possible je peux te payer comme guide.<br />
        👳🏽*** jeune homme :*** il faut traverser tout le désert du Sahara et pour cela il faut de l’argent ; je veux d’abord savoir si tu en a suffisamment.`,
        lecon: "",
        illustration:
          "https://i.pinimg.com/originals/5a/a6/03/5aa6030e593ff21b3f488b51de194bd9.jpg",
        A13111: "oui ; j’ai de l’argent.",
        A13112: "non ; je n’ai pas d’argent."
      },

      A13112: {
        question: `👳🏽*** jeune homme :*** alors il sera impossible pour vous de vous rendre au pyramide.`,
        A131121:
          "j’ai obtenu un peu d’argent suite à la vente de mon troupeau.",
        A131122: "ok j ai de l'argent"
      },

      A13111: {
        question: `👳🏽*** jeune homme :*** tout d’abord nous devons acheter deux chameaux, et pour cela il faudra que tu me confie ton argent et me laisse faire pour éviter de te faire escroquer.`,
        lecon: "",
        illustration:
          "https://www.antiques-delaval.com/7263-57713-thickbox/aquarelle-orientaliste-delacroix-bedouins-chameaux-desert-maghreb-xixeme.jpg",
        A131111: "pas question je vais me débrouiller seul.",
        A131112: "j’accepte.",
        A131113:
          "j’accepte, mais à condition que je t’accompagne pour plus de sécurité."
      },

      A131111: {
        lecon:
          "Le berger se rend seul au marcher et par ignorance il achète un chameau malade au prix de deux.<br/>Le reste de son argent ne lui permet plus d’acheter un autre chameau.<br/>« Le plus grand ennemi de la connaissance n’est pas l’ignorance mais plutôt l’illusion de la connaissance » STEPHEN HAWKING"
      },

      A131112: {
        lecon:
          "Le berger remet tout son argent au jeune homme, et ce dernier promet au berger de se rendre au marché pour acheter des chameaux pour le voyage. Mais il ne revient jamais.<br/>« Ce qui est laid, c’est que sur cette terre il ne suffise pas d’être tendre et naïf pour être accueille à bras ouverts. » ALBERT COHEN"
      },

      A131113: {
        question: `<p>Ils s’en furent, ensemble, par les rues étroite de Tanger. Dans tous les coins et recoins, il y avait des étalages de marchandises à vendre. Ils arrivèrent finalement au millier d’une grande place ; ou se tenait le marché. Des milliers de personnes étaient la, qui discutaient, vendaient, achetaient.</p><p>Les yeux du berger tombèrent sur la plus belle épée qu’il eut jamais vu, après deux seconde de distraction il se rendit compte que son nouveau compagnon a disparu avec tout son argent.</p>👨🏾‍🦱***- berger :*** je ne suis plus berger et je ne suis plus rien ; pas même l’argent nécessaire pour revenir sur mes pas et tout cela entre le lever et le coucher du même soleil. Je pleure parce que Dieu est injuste et qu’il récompense de cette façon les gens qui croyait à leur propre rêve.Que vais-je faire maintenant ?`,
        lecon: "",
        illustration:
          "https://img.freepik.com/vecteurs-premium/homme-tres-triste-assis-seul-fond-blanc-deprime-jeune-homme-assis_498048-533.jpg",
        A1311131: "continuer à s’apitoyer sur son sort.",
        A1311132:
          "utiliser les pierres Tourin et Ourin pour trouver les signes de cet évènement.",
        A1311133:
          "vendre les pierres précieuses pour pouvoir retourner en Espagne et ne plus pense à réaliser ce rêve."
      },

      A1311131: {
        lecon:
          " Le berger continua  à s’apitoyer sur son sort pendant  des jours et fini par se donner à la mendicité pour ne pas mourir de faim.<br/><<Je n'ai jamais vu une bête sauvage s'apitoyer sur son sort, un oiseau préférera mourir gelé et tomber de sa branche plutôt que de se plaindre. >> Citation"
      },

      A1311133: {
        lecon:
          "Le berger réussir  à vendre les pierres facilement car elle était de très grande valeur. Avec la somme récupéré il retourna dans pays où il acheta des brebis pour recommence son activité.<br/>Mais au fil des temps il était hanté par le faite d’avoir abandonné sa quête  à la première épreuve.<br/><< Psychologiquement, le pire des sentiments est de ne pas savoir s’il faut attendre ou abandonner. >> Citation"
      },

      A1311132: {
        question: `<p>Le jeune homme, alors, demanda si la bénédiction du vieillard était toujours sur lui. Il retira l’une des pierres, c’était « oui ». A cet instant il ne ressenti plus le désespoir qu’il avait éprouvé auparavant, il eut soudain le sentiment qu’il pouvait regarder le monde soit comme la malheureuse victime d’un voleur soit comme un aventurier en quête d’un trésor.<p>`,
        lecon: "",
        A13111321: "poursuivre l’aventure !  ▶️",
        A13111322: "faire une pause !  ⏸️"
      },

      A13111321: {
        question: `<p>Le marchand de cristaux vit le jour se lever et ressentir la même impression d’angoisse qu’il éprouvait chaque matin, il était depuis plus de trente ans dans ce même endroit ; une boutique situer au sommet d’une rue en pente. Et il ne  resta bientôt plus que quelques rares boutiques dans la monte. Personne n’allait gravir une rue en pente pour quelque malheureuse boutique, et maintenant trop tard pour s’engager dans une nouvelle direction. En se même moment le vieillard vit apparaitre un jeune homme(le berger) devant le comptoir.</p>👨🏾‍🦱***- Berger :*** si vous voulez ; je peux nettoyer ces vases. Dans l’état ou ils sont personne ne voudra jamais les acheter.<br/><br/><p>Le marchand de cristaux le regarda sans rien dire.</p>
        👨🏾‍🦱*** Berger :*** En échange, vous me payer quelque chose a mangé, d’accord ?<br /><br /><p>L’homme restait muet, le berger comprit que c’était à lui de prendre une décision. Durant une demi-heure, il put nettoyer tous les cristaux, pendant ce lapse de temps, deux client entraient et en achetèrent plusieurs</p>
        🧔🏽*** Marchand de cristaux :*** ce n’était pas la peine de nettoyer quoi que ce soit ; la loi coranique oblige à donner à manger à quiconque a faim.<br />
        👨🏾‍🦱 *** Berger :*** Mais alors pourquoi m’avez-vous laissé faire ce travail ?<br />
        🧔🏽*** Marchand de cristaux :*** Parce que les cristaux était sales et toi comme moi avait besoin de nettoyer nos tête de mauvaises pense.<p>Apres avoir fini de manger le vieillard lui demanda s’il voulait travailler pour lui ?</p>`,
        lecon: "",
        A131113211:
          "non, je voulais juste avoir de quoi à manger et ensuite continuer la route.",
        A131113212: "oui, j’accepte  de travailler avec vous.",
        A131113213:
          "Je vais travailler cher vous, il me faut de l’argent pour me rendre au Pyramide."
      },

      A131113211: {
        lecon:
          "Le berger  après avoir fini de manger fait ces adieu au Marchand de cristaux, mais rapidement il se rendit compte qu’il aura besoin d’argent pour pouvoir rentre chez lui ou continuer l’aventure."
      },

      A131113213: {
        question: `<p>Du coup le vieux se mit à rire et dit.</p>🧔🏽***Marchand de cristaux :*** c’est impossible ; même si tu nettoyais mes cristaux pendant toute une année, même si tu gagnais une bonne commission sur la vente de  chacun d’entre eux ; il te faudrait encore beaucoup d’argent pour aller jusqu’en Egypte et en plus traverser des milliers de kilomètre de désert entre Tanger et les pyramide.<br /><p>Il y eut alors un intervalle de silence ; c’était comme si le monde tout entier était devenu muet parce que l’âme du jeune garçon faisait silence.</p>
        🧔🏽***Marchand de cristaux :*** mon fils ; je peux te donner de l’argent pour que tu te retournes dans ton pays.`,
        lecon: "",
        A1311132131:
          " j’ai plus d’autre choix, je vais travailler cher vous, il me faut de l’argent pour acheter quelque mouton une fois rentré au pays.",
        A1311132132: "ok donner moi l'argent pour retourner au pays"
      },

      A1311132132: {
        lecon: `« Bonne est l’action qui n’amène aucun regret et dont le fruit est accueilli avec joie et sérénité. » Bouddha<br/>Il est plus facile de vivre avec une déception qu’avec du regret.`
      },

      A1311132131: {
        question: `<p>Il n’y avait pas loin d’un mois que le jeune homme travaillait chez le marchand et ce n’était pas un emploi de nature à le satisfaire vraiment ; il restait la parce que si le marchant était sans doute un vieux grognon ; il était aussi juste.</p><p>Ce matin il avait fait ses calculs ; en continuant à travailler tous les jours dans les mêmes conditions il lui faudrait une année entière pour pouvoirs acheter quelques mouton.</p>`,
        A13111321311: `réfléchissez à une stratégie pour atteindre l’objectif le plus rapidement.`,
        A13111321312: `continuer a travaillé ainsi avec courage tous en évitant des risques inutiles.`
      },

      A13111321312: {
        lecon:
          "Le chemin des idées conduit à l'autoroute du progrès. (Gael Crutzen , Expérience de vie).<br/>En un an le berger réussi à obtenir la somme d’argent qu’il lui faut pour retourner dans son pays et acheter des moutons, mais il passa à coter de sa légende personnelle car il refusa  d’observer les signes."
      },

      A13111321311: {
        question: ` 👨🏾‍🦱*** Berger :***  j’aimerais bien faire un éventaire pour les cristaux dit il a son patron ; on pourrait installer une étagère à l’extérieur qui attirerai les passant depuis le pied de la monte la en bas.<br/>
        🧔🏽***Marchand de cristaux :*** je n’ai jamais fait une chose pareille ; une étagère ! Les gens l accrocheront au passage et les cristaux se briseront.<br/>
        👨🏾‍🦱*** Berger :***  quand je parcourais la compagne avec mes brebis ; elles pouvaient toujours être victimes de la morsure d’un serpent ; mais ce risque fait partie de la vie des mouton et des bergers.<br/><br/>
        <p>----------</p>
        <p>Deux mois encore passèrent l éventaire attira de nombreux client ; le jeune homme calcule qu’en travaillant six mois de plus il pourrait retourner en Espagne et acheter soixante mouton et même soixante de plus.</p>
        <p>Il était fier de lui ; il avait appris des choses important ; comme le langage sans parole et les signes.<p/>
        <p>Un après midi, il vit un homme en haut de la monte qui se plaignait qu’on ne put trouver un endroit convenable pour boire quelque chose après avoir gravir cette rampe.</p>`,
        A131113213111: `Utiliser cette situation pour crée une opportunité d’affaire.`,
        A131113213112: `je ne peux plus me permettre de prendre aucun risque ; de toutes les façons il ne me reste plus que quelque mois.`
      },

      A131113213112: {
        lecon:
          "Le chemin des idées conduit à l'autoroute du progrès. (Gael Crutzen , Expérience de vie).<br/>En quelque mois le berger réussi à obtenir la somme d’argent qu’il lui faut pour retourner dans son pays et acheter des moutons, mais il passa à coter de sa légende personnelle car il refusa  d’observer les signes."
      },

      A131113213111: {
        question: `<p>Le jeune homme connaissait maintenant le langage des signes ; et alla trouver son patron pour lui parler.</p><p>👨🏾‍🦱*** Berger :***  nous devions offrir du thé aux gens qui montent la rampe.<br />
        🧔🏽***Marchand de cristaux :*** il Ya déjà beaucoup d’endroit par ici ; ou l’on peut prendre le thé.<br/>
        👨🏾‍🦱*** Berger :***  nous pourrions le servir dans des verres en cristal. De cette façon, les gens apprécieront le thé ; et voudront acheter les cristaux, car ce qui séduit le plus les hommes c’est la beauté.<br />
        🧔🏽***Marchand de cristaux :*** après quoi cours-tu ?</p>`,
        A1311132131111: `je ne sais pas pour le moment.`,
        A1311132131112: `Je vous l’ai dit ; j’ai besoin de racheter mes brebis et pour cela il faut de l’argent.`
      },

      A1311132131112: {
        question: `🧔🏽***Marchand de cristaux :*** tu as été pour moi une bénédiction et voici qu’aujourd’hui je comprends une chose ; c’est que toute bénédiction qui, ne n’est pas accepter se transforme en malédiction ; je n’attends plus rien de la vie, et toi tu m’oblige à entrevoir des richesses et des horizons dont je n’avais jamais eu idée. « Mektoub (c’est écrit)» ; certaines fois il est impossible de contenir le fleuve de la vie.<br/>Tu peux commencer à proposer du thé au client dans des verres en cristal.<br/><br/>
        <p>En peu de temps la nouvelle se rependit, et beaucoup de gens se mirent à monter jusqu’au sommet de la rampe pour connaitre la boutique qui avait inauguré cette nouveauté.<br/>Très vite, le marchand fut amener à embaucher deux autre employer et dut bientôt importer en même temps que les cristaux, d’énormes quantité de thé. <br />Ainsi passèrent six mois.</p>
        <p>-----------</p>
        👨🏾‍🦱*** Berger :***  c’est aujourd’hui que je m’en vais, j’ai l’argent qu’il ne faut pour acheter plus de 120 moutons et j’ai aussi une licence d’importation et d’exportation entre mon pays et l’Afrique, et vous en avez assez pour aller à la Mecque comme vous l’aviez toujours rêvé.<br/>
        🧔🏽***Marchand de cristaux :*** je suis fier de toi ; tu as donné une âme à ma boutique de cristaux ; mais je n’irais pas à la Mecque, tu le sais bien comme tu sais aussi que tu ne rachèteras pas de moutons.`,
        A13111321311121: `si je vais acheter des mouton et reprendre ma vie de berger ; c’est plus simple.`,
        A13111321311122: `qu’est-ce qui vous faire dire cela.`
      },

      A13111321311122: {
        question: `-🧔🏽***Marchand de cristaux :***  « Mektoub (c’est écrit)».<br/>
        -👨🏾‍🦱*** Berger :***  je sais bien pourquoi je veux retourner à mes  brebis, elle ne demande pas beaucoup de travail et on peut les aimer ; je ne sais pas si le désert peut être aimé ; mais c’est le désert qui recèle mon trésor, si je n’arrive pas à le trouver, je pourrai toujours rentre chez moi, pourtant la vie ma donner tout d’un coup l’argent suffisant et j’ai tous les temps qu’il me faut.<br/><br/>
        <p>Il ressentir à cet instant une immense allégresse. Il rejoint une caravane qui se préparait à traverser le désert pour l’Egypte.</p>
        <p>-------------</p>
        -🧔🏻*** Chef de la caravane :*** je suis le chef de la caravane dit un homme, j’ai le droit de vie et de mort sur tous ceux que je conduis, car le désert est une femme capricieuse, qui parfois rend les hommes fous.<br/>Il n’est pas trop tard pour faire demi-tour.`,
        A131113213111221: `je décide de suivre la caravane.`,
        A131113213111222: `je décide de rentré cher moi en Espagne qui est à quelque heure en bateau.`,
        A131113213111223: `je réfléchir a un autre moyen de traverser le désert.`
      },

      A131113213111222: {
        lecon:
          "L'herbe ne pousse jamais sur la route où tout le monde passe. (Proverbe africain)<br/>Le berger retourna dans son pays et repris son activité, il fonda une famille, mais il vécut dans le regret de n’avoir pas poursuivi sa légende personnelle. "
      },

      A131113213111223: {
        lecon:
          "Le berger  décide de se donner du temps pour réfléchir à une solution pour traverser le désert. A un moment il se rendit compte qu’il n’y a pas autre moyen de traverser le désert, il était déjà trop tard la caravane est partir.<br/>L'unité de la sagesse réside dans son juste emploi. Un sage pourra échouer à force d'hésitation, tandis qu'un insensé réussira en agissant correctement une seule fois au moment opportun.(Feng Menglong)"
      },

      A131113213111221: {
        question: `La caravane se mit en marche en direction du levant. On avançait durant la matinée, on faisait halte quand le soleil devenait plus brulant, et l’on reprenait la progression quand il commençait à baiser.<br/><br/>
        -👳🏽‍♀️*** le chamelier :*** j’ai déjà traversé bien des fois ces étendues de sable, mais le désert est si vaste, les horizons si lointains, qu’on se sent tout petit, et qu’on garde le silence.<br/>
        -👨🏾‍🦱*** Berger :***  j’ai appris avec des brebis et j’ai appris avec des cristaux, je peux aussi bien apprendre avec le désert ; il ne semble encore plus vieux et plus sage.<br/>
        -👳🏽‍♀️*** le chamelier :*** personne ne doit avoir peur de conquérir ce qu’il veut et qui lui est nécessaire. Tous ce que nous craignons c est de perdre ce que nous possédons, qu’il s’agisse de notre vie ou de notre culture, mais cette crainte cesse lorsque nous comprenons que notre histoire et l’histoire du monde ont été écrites par la même main.<br/><br/>
        Il y avait des rumeurs de guerre entre les clans du dessert et cela pourrait être un grand danger pour la caravane.`,
        A1311132131112211: `rebrousser chemin.`,
        A1311132131112212: `rester sur place et attendre la fin de la guerre des clans.`,
        A1311132131112213: `continuer malgré les rumeurs.`
      },

      A1311132131112211: {
        lecon:
          "-Chef de la caravane : cette décision ne vous appartient pas ! Si vous voulez que l’on rebrousser chenin vous aller devoir le faire seul. Pour ma part je décide de conduire la caravane a destination.<br/><br/>Le berger rebroussa chemin seul, mais il n’arrivait plus à retrouver le chemin de retour et se perd dans le désert.<br/><br/>L'émotion est plus forte que la réflexion, mais moins que la décision. (Alain Laugier)"
      },

      A1311132131112212: {
        lecon:
          "-Chef de la caravane : cette décision ne vous appartient pas ! Si vous voulez rester sur place et attendre la fin de la guerre des clans, cela est votre décision. Pour ma part je décide de conduire la caravane a destination.<br/><br/>Le berger resta sur place en attendre la fin de la guerre des clans. Mais il oublia qu’il ne savait pas à quel moment cette guerre prendra fin.<br/><br/> L'émotion est plus forte que la réflexion, mais moins que la décision. (Alain Laugier)"
      },

      A1311132131112213: {
        question: `Lors de la traverser du désert le jeune berger fait la connaissance d’un anglais qui avait tout abandonné et utiliser tous son héritage dans la recherche d’un Alchimiste, l’anglais était tout le temps dans ces livres.<br/><br/>
        -👨🏾‍🦱*** Berger :*** vous devriez accorder d’avantage d’attention aux caravanes.<br/>
        -🧑🏻*** l’anglais :*** et vous, vous devriez lire d’avantage sur le monde. Les livres sont tout à fait comme les caravanes.<br/>
        -👨🏾‍🦱*** Berger :*** j’ai pu observer la caravane qui chemine à travers le désert, elle et le désert parlent le même langage, et c’est la raison pour laquelle il permet quelle la traverse. Il ne cesse d’éprouver chacun de ses pas ; pour vérifier si elle est en parfaite syntonie avec lui, et si c’est bien le cas, elle arrivera jusqu’à l’Oasis, mais si l’un de nous, en dépit de tout le courage qu’il pourrait avoir, ne comprenait pas ce langage, alors il mourrait dès le premier jour.<br/>
        -🧑🏻*** L’anglais :*** il faut en effet que j’accorde un peu plus d’attention à la caravane.<br />
        -👨🏾‍🦱*** Berger :*** et moi, il faut que je lise vos livres.`,
        A13111321311122131: `lire les livres de l’anglais.`,
        A13111321311122132: `lire rapidement`
      },
      A13111321311122131: {
        question: `Un beau jour ; le jeune homme rendit ses livres à l’anglais.<br/><br/>
        -🧑🏻*** l’anglais :*** eh bien avez-vous beaucoup appris ?<br />
        -👨🏾‍🦱*** Berger :*** j’ai appris que le monde possède une âme, et celui qui pourra comprendre cette âme, comprendra le langage des choses. J’ai appris que de nombreux Alchimistes ont vécu leur légende personnelle et qu’ils ont fini par découvrir l’âme du monde, la pierre philosophale et l’élixir de longue vie. Mais j’ai appris, surtout que ces choses sont si simples qu’elles peuvent être gravées sur une Émeraude.<br/><br/>
        L’anglais fut déçu, les années d’étude, les symboles magiques les mots difficiles à comprendre les appareils de laboratoire, rien de tout cela n’avait impressionné le jeune garçon.<br/><br/>
        -🧑🏻*** l’anglais :*** retourner  votre caravane, elle non plus ne m’a pas appris grand-chose.<br/>
        -👨🏾‍🦱*** Berger :*** à chacun sa manière d’apprendre ce répétait-il, sa manière à lui n’est pas la mienne, et ma manière n’est pas la sienne. Mais nous sommes l’un et l’autre à la recherche de notre légende personnelle et c’est pourquoi je le respect.<br/>
        -🧑🏻*** l’anglais :*** Nous sommes arrivés à Fayoum !<br/><br/>
        S’exclama l’anglais ! Le jeune homme, cependant resta muet ; il avait appris le silence du désert et se contentait de regarder les palmiers en face de lui, il avait encore un long chemin à parcourir pour arriver jusqu’aux pyramide.`,
        A131113213111221311: `Continuer directement jusqu’aux pyramide.`,
        A131113213111221312: `faire une pause dans l’Oasis.`
      },

      A131113213111221311: {
        lecon:
          "Se presser ne fait pas se lever le soleil plus tôt. (Proverbe guadeloupéen).<br/>Le berger continua directement sa route mais malheureusement il n’arrive pas à atteindre les pyramide car il fut capturer par des soldats."
      },

      A131113213111221312: {
        question: `Le jeune homme avait du mal à en croire ses yeux ; aux lieux d’un puits entoure de quelques palmiers (selon la description qu’il avait lue une fois dans un livre d’histoire), il s’apercevait que l’Oasis était beaucoup plus grande que bien des villes d’Espagne.<br /><br />
        Les nouveaux arrivants furent amenés immédiatement en présence des chefs tribaux de Fayoum.<br /><br />
        Le chef de la caravane rassemble tout son monde et commence à donner ses instructions. On allait rester là tant que durerait la guerre.<br/><br/>
        En tant que visiteur, les gens de la caravane seraient hébergés sous les tentes des habitants de l’Oasis. Puis il demanda à tous, y compris ses propre sentinelles de remettre leur armes aux hommes désigné par le chef de tribus ; de cette façon les oasis ne peuvent servir de refuge a des combattant car se sont de terrain neutre ou la majeure partie de ceux qui y vivaient était des femmes et des enfants.<br/><br/>
        Le jeune homme, pour sa part, songeait à son trésor ; plus il se rapprochait de son trésor, plus les choses devenaient difficiles. Ce que le vieux roi avait appelé la chance du débutant ne se manifestait plus. C’était maintenant, il le savait, l’épreuve de l’obstination et du courage pour qui est à la recherche de sa Légende Personnelle.<br/><br/>
        -🧑🏻*** l’anglais :***  je vous ai cherché toute la matinée ; il faut que vous m aidiez à découvrir ou loge l’alchimiste, l’oasis est tellement vaste ; je ne sais comment le trouver.`,
        A1311132131112213121: `vérifier dans toutes les tentes.`,
        A1311132131112213122: `demander simplement aux habitants de l’oasis.`
      },

      A1311132131112213121: {
        question: `L’oasis est immense et compte plus de milles tentes.<br/>Vous voulez vraiment vérifier chaque tente ?`,
        A13111321311122131211: `je crois qu’il est préférable demander aux habitants de l’oasis.`,
        A13111321311122131212: `je crois qu’il est préférable de laisser tomber`
      },

      A13111321311122131212: {
        lecon: `« Bonne est l’action qui n’amène aucun regret et dont le fruit est accueilli avec joie et sérénité. » Bouddha<br/>Il est plus facile de vivre avec une déception qu’avec du regret.`
      },

      A1311132131112213122: {
        question: `-👨🏾‍🦱*** Berger :*** Madame bonsoir, je voudrais savoir ou demeure un alchimiste ?<br/><br/>
        La femme répondit quelle n’en avait jamais entendu parler et s en fut aussitôt.<br/><br/>
        -🧑🏻*** l’anglais :*** mais bien sûr ! Peut-être bien que personne ici ne sait ce qu’est un alchimiste ; Informer vous dons plutôt de l’homme qui soigne toute les maladies.<br/><br/>
        Cet alors qu’apparut une jeune fille elle portait une jarre qui reposait sur son épaule et avait une voile autour de la tête ; quant il vit ses yeux noire, ses lèvres qui hésitaient entre le sourire et le silence, il comprit la partie la plus essentielle et la plus savante du langage que parlait le monde et que tous les être de la terre était capable d’entendre en leur cœur, quelle que chose de plus vieux que les hommes et que le désert même ; les lèvres enfin se décidèrent pour un sourire, et c’était là un signe, le signe qu’il avait attendu sans le savoir pendant un si long temps de sa vie, qu’il avait chercher dans les livres et auprès de ses brebis, dans les cristaux et dans le silence du désert. Voilà, c’était le pur langage du monde, sans aucune explication, parce que l’univers  n avait besoin d’aucune explication pour continuer sa route dans l’espace infini, et cela s’appelait l’amour.<br/><br/>
        -🧑🏻*** l’anglais :*** allez ! Demandez-lui.<br/>
        -👨🏾‍🦱*** Berger :*** comment t appelle tu ?<br/>
        -🧕🏽*** Fatima :***  mon nom est Fatima.<br/><br/>
        L’anglais à côté insistait, et le jeune homme demanda si elle savait quelque chose de l’homme qui guérissait toute les maladies ; la jeune fille montra d’un geste la direction du sud ; puis elle emplit sa cruche et partir.<br/><br/>
        Le lendemain, il retourna au puits pour y attendre la jeune fille ; il fut surpris d’y trouver l’anglais qui, pour la première fois contemplais le désert. <br/><br/>
        -🧑🏻*** l’anglais :*** j’ai attendu tout l’après-midi et toute la soirée, il est arrivé au moment au apparaissaient les premières étoiles, je lui ai dit ce que je cherchais, et il m’a demandé si j’avais déjà transformé du plomb en or. J’ai répondu que c’était précisément ce que je souhaitais apprendre alors, il ne m’a rien dit d’autre que c’est mot : « vas essayer »<br/><br/>
        Le jeune homme demeure silencieux. Ainsi, l’anglais avait fait tout ce trajet pour s’entendre dire ce qui savait déjà, et il se souvient que lui-même avait donné six moutons aux vieux rois pour un résultat semblable.<br/><br/>
        -👨🏾‍🦱*** Berger :***...`,
        A13111321311122131221: `Eh bien ! Essayer !`,
        A13111321311122131222: `je pense que vous devriez maintenant rentre cher vous !`
      },

      A13111321311122131222: {
        lecon:
          "Vous auriez dû l’encourager à poursuivre sa légende personnelle, de la même manier  le vielleur la fait aussi avec vous."
      },

      A13111321311122131221: {
        question: `-🧑🏻*** l’anglais :***  c’est bien ce que je vais faire, et je vais m’y mettre tout de suite.<br/><br/>
        Peu après le départ de l’anglai Fatima arriva au puis pour remplir sa cruche.<br/><br/>
        -👨🏾‍🦱*** Berger :*** je suis venu te dire une chose toute simple, je veux que tu sois ma femme, je t’aime.<br/><br/>
        La jeune fille laissa déborder le récipient<br/><br/>
        -👨🏾‍🦱*** Berger :*** j’ai traversé le désert pour venir chercher un trésor qui se trouve à proximité des pyramides. La guerre était pour moi une malédiction ; c’est maintenant une bénédiction puisqu’elle m’immobilise ici près de toi.<br/>
        -🧕🏽*** Fatima :*** toute petite, je rêvais que le désert m’apporterait un jour le plus beau présent de mon existence, et ce présent m’est enfin offert, c’est toi. Tu m’as parlé de tes rêves, du vieux roi, du trésor, tu m’as parlé des signes ; voilà pourquoi je ne crains rien ; parce que ce sont ces signes qui ton amené a moi ; et je fais partie de ton rêve, de ta Légende Personnelle comme tu le dit si souvent. Pour cette raison même, je veux que tu poursuives ta route en direction de ce que tu es venu chercher. S’il te faut attendre la fin de la guerre, c’est très bien ; mais si tu dois partir plutôt, alors pars vers ta Légende. Les dunes changent sous l’action du vent mais le désert reste toujours le même. Ainsi en sera-t-il de notre amour. « Mektoub  » si je fais partie de ta Légende, tu reviendras un jour.<br/>
        -👨🏾‍🦱*** Berger :***
        `,
        A131113213111221312211: `En toi j’ai trouvé l’amour et je ne veux plus continue ma quête au trésor.`,
        A131113213111221312212: `l’amour exige la présence auprès de l’être aimée, par conséquent je resterais près de toi pour toujours.`,
        A131113213111221312213: `« Mektoub » qu’il en soit ainsi.`
      },

      A131113213111221312211: {
        lecon:
          "Le berger décida de rester avec Fatima et d’oublier le trésor.<br/>Mais Fatima refusa car elle ne voulait pas d’un homme qui abandonne sa légende personnel à cause d’elle."
      },

      A131113213111221312212: {
        lecon:
          "Le berger décida de rester avec Fatima et d’oublier le trésor.<br/>Mais Fatima refusa car elle ne voulait pas d’un homme qui abandonne sa légende personnel à cause d’elle. "
      },

      A131113213111221312213: {
        question: `Le jeune homme se sentit triste, il alla retrouver l’anglais. Il voulait lui parler de Fatima. Non sans surprise, il constata que l’Anglais avait construit un petit four à côté de sa tente. C’était un four curieux, sur lequel était posé un flacon transparent. L’Anglais alimentait le feu avec du bois, et contemplait le désert. Ses yeux semblaient plus brillants que lorsqu’il passait tout son temps plongé dans les livres.<br/><br/>
        -🧑🏻*** l’anglais :*** C’est la première phase du travail, dit-il. Je dois séparer le soufre impur. Et, pour y parvenir, il faut que je ne craigne pas d’échouer. Ma crainte d’échouer est ce qui m’a empêché jusqu’ici de tenter le Grand Œuvre. C’est maintenant que je commence ce que j’aurais pu commencer il y a déjà dix ans. Mais je suis heureux de n’avoir pas attendu encore vingt ans.<br/><br/>
        Le jeune homme resta auprès de lui pendant un moment. Il ressentit alors une envie impérieuse d’aller dans le désert. Il marcha sans but pendant un certain temps, sans perdre de vue les palmiers de l’Oasis. Il s’assit sur une grosse pierre et se laissa hypnotiser par l’horizon qu’il avait en face de lui. Il ne pouvait concevoir l’Amour sans y mêler l’idée de possession. Mais Fatima était une femme du désert. Si quelque chose pouvait l’aider à comprendre, c’était bien le désert. Il demeura ainsi, sans penser à rien, jusqu’au moment où il eut l’impression que quelque chose bougeait au-dessus de sa tête. En regardant en l’air, il vit deux éperviers qui volaient très haut dans le ciel. Subitement, l’un des éperviers descendit en piqué pour attaquer l’autre. A ce moment précis, le jeune homme eut une soudaine et brève vision : une troupe armée envahissait l’Oasis, l’épée au poing. La vision s’effaça tout aussitôt, mais lui laissa une vive impression. Il parvint à surmonter l’angoisse qui l’avait étreint. Il se releva et se mit en marche dans la direction des palmiers. Maintenant, c’était le désert qui était la sécurité, tandis que l’Oasis était devenue un péril.<br/><br/>
        Le chamelier était assis auprès d’un palmier dattier, à regarder lui aussi le coucher de soleil. Il vit le jeune homme arriver de derrière une dune.<br/><br/>
        -👨🏾‍🦱*** Berger :*** Il y a une armée qui approche, J’ai eu une vision.<br/>
        -*** Chamelier :***  Le désert emplit de visions le cœur des hommes.<br/><br/>
        Mais le jeune homme lui parla des éperviers. Dieu avait montré un futur au jeune homme, pensa le chamelier. Parce qu’il voulait que le jeune homme fût son instrument.<br/><br/>
        -*** Chamelier :***  Va trouver les chefs de tribus. Parle-leur des guerriers qui approchent.`,
        A1311132131112213122131: `si cela peut sauver des vies je vais le faire.`,
        A1311132131112213122132: ` pas question ils vont se moquer de moi.`,
        A1311132131112213122133: ` pas le temps je vais quitter l’oasis a plus vite avec  Fatima.`
      },

      A1311132131112213122132: {
        lecon:
          "Le berger demeura dans le silence jusqu’au jour ou l’armer arrive au porte de l’oasis et ravage tout sur son passage, Fatima n’y survie pas ! Le berger fut hanter par ce désastre car il a vu les signe mais n’a pas agir."
      },

      A1311132131112213122133: {
        lecon:
          "Le berger informa Fatima de la situation à venir.<br/>Fatima : je pense que si dieu ta permit de voire ce signe c’est pour pouvoir sauver les habitant de l’Oasis, et toi tu veux que l’on s’enfuit a deux.  Je ne peux pas te suivre, enfuit toi seul, je décide d’affronter cette situation avec les miens. Adieu !"
      },

      A1311132131112213122131: {
        question: `Le jeune homme pensa à Fatima. Et il décida d’aller trouver les chefs de tribus.<br/><br/>
        -👨🏾‍🦱*** Berger :***  J’apporte un message du désert, Je veux parler aux chefs.<br/><br/>
        dit-il au garde qui était en faction à la porte de l’immense tente blanche dressée au centre de l’Oasis. Le garde ne répondit rien. Il entra sous la tente et y demeura un long moment. Puis il ressortit en compagnie d’un Arabe. Le jeune homme lui raconta ce qu’il avait vu. L’Arabe lui demanda d’attendre un peu. Finalement, au terme de plusieurs heures d’attente, le garde le fit entrer.<br/><br/>
        Il y avait là huit chefs, mais il comprit tout de suite lequel était le plus haut placé : un Arabe vêtu de blanc et d’or, assis au centre du demi-cercle. A côté de lui se trouvait le jeune homme avec qui il avait parlé peu auparavant.<br/><br/>
        -***Chef  1 :*** Qui est l’étranger qui parle de message ?<br/>
        -👨🏾‍🦱*** Berger :***  C’est moi.<br/><br/>
        Et il raconta ce qu’il avait vu.<br/><br/>
        -***Chef  2 :*** Pourquoi le désert dirait-il donc ces choses à un homme venu d’ailleurs, quand il sait que nous sommes ici depuis plusieurs générations ?<br/>
        -👨🏾‍🦱*** Berger :***  Parce que mes yeux ne sont pas encore habitués au désert, de sorte que je peux voir des choses que les yeux trop habitués n’arrivent plus à voir.<br/>
        -***Chef  3 :*** L’Oasis est un terrain neutre. Personne ne va attaquer une oasis !<br/>
        -👨🏾‍🦱*** Berger :***  Je raconte seulement ce que j’ai vu. Si vous ne voulez pas y croire, ne faites rien.<br/><br/>
        Un silence total s’abattit sur la tente, suivi d’un conciliabule animé entre les chefs de tribus.<br/><br/>
        -***Grand chef :*** Demain, nous allons rompre l’accord qui dit que nul ne doit porter une arme à l’intérieur de l’Oasis. Durant la journée, nous attendrons l’ennemi. Quand le soleil déclinera sur l’horizon, les hommes me rendront leurs armes. Pour chaque dizaine d’ennemis tués, tu recevras une pièce d’or.  Toutefois, les armes ne peuvent être sorties sans aller au combat. Elles sont capricieuses comme le désert et, si nous les sortons pour rien, elles peuvent ensuite refuser de faire feu. Si aucune d’elles n’a été utilisée demain, il y en aura au moins une qui servira : contre toi.`,
        A13111321311122131221311: `quitter l’oasis car le prix à payer pourrait être ma propre vie.`,
        A13111321311122131221312: `rester et observer la suite des évènements !`
      },

      A13111321311122131221311: {
        lecon:
          "Une nuit le berger profite de l’obscurité pour s’enfuir de l’oasis car il ne croyait pas totalement aux signes. Quelque jour plus tard l’oasis fut attaque, mais les habitants se sont déjà préparer à repousser l’attaque. Le chef de la tribu décide de remercier le berger avec une grande quantité d’Or, mais le berger avait disparu.<br/>Le courage est la vertu majeure pour qui cherche le Langage du Monde. (L’alchimiste)"
      },

      A13111321311122131221312: {
        question: `Lorsqu’il ressortit, l’oasis n’était éclairée que par la pleine lune. Il y avait vingt minutes de marche jusqu’à sa tente, et il se mit en route. Tout ne dépendait que d’un mot : « Mektoub. » Il chemina en silence. Il ne regrettait rien. S’il devait mourir le lendemain, ses yeux auraient vu beaucoup plus de choses que les yeux des autres bergers, et il en était fier.<br/><br/>
        Soudain, il entendit comme un grondement et fut jeté brusquement à terre, sous le choc d’une rafale de vent d’une violence inouïe.  Devant lui, un cheval blanc de taille gigantesque se cabra, avec un hennissement effrayant.  L’étrange cavalier tira hors du fourreau la grande épée à lame courbe qui était accrochée à sa selle.<br/><br/>
        -*** Cavalier :*** Qui a osé lire dans le vol des éperviers ?<br/>
        👨🏾‍🦱*** Berger :*** Moi, j’ai osé !<br/><br/>
        Et il baissa la tête pour recevoir le coup de sabre. Le cavalier cependant, se contentait de maintenir la pointe de l’épée sur son front.<br/><br/>
        -***Cavalier :*** Pourquoi as-tu lu le vol des oiseaux?<br/>
        👨🏾‍🦱*** Berger :*** J’ai seulement lu ce que les oiseaux voulaient conter.<br/>
        -*** Cavalier :***  Qui es-tu, pour changer le destin tracé par Allah?<br/>
        👨🏾‍🦱*** Berger :*** Allah a fait les armées, et Il a fait aussi les oiseaux. Allah m’a montré le langage des oiseaux. Tout a été écrit par la même Main.<br/>
        -*** Cavalier :***  Prends garde aux divinations. Quand les choses sont écrites, il ne peut être question de les éviter.<br/>
        👨🏾‍🦱*** Berger :*** J’ai seulement vu une armée, dit le jeune homme. Je n’ai pas vu l’issue d’une bataille.<br/><br/>
        Le cavalier sembla satisfait de la réponse. Mais il gardait son épée à la main.<br/><br/>
        -***Cavalier :***  Que fait un étranger sur une terre étrangère?<br/>
        👨🏾‍🦱*** Berger :*** Je cherche ma Légende Personnelle.<br/>
        -***Cavalier :***  Je devais éprouver ton courage, Le courage est la vertu majeure pour qui cherche le Langage du Monde.<br/><br/>
        Le jeune homme fut surpris. Cet homme parlait de choses que peu de gens connaissaient.<br/><br/>
        -***Cavalier :*** « Il ne faut jamais se relâcher, même quand on est parvenu aussi loin, Il faut aimer le désert, mais ne jamais s’y fier entièrement.  Si les guerriers arrivent, et si ta tête est encore sur tes épaules demain après le coucher du soleil, viens me voir.<br/>
        👨🏾‍🦱*** Berger :*** Où habitez-vous?<br/><br/>
        Tandis que le cavalier s’éloignait. La main qui tenait la cravache désigna la direction du sud. Le jeune homme venait de rencontrer l’Alchimiste.`,
        A131113213111221312213121: `faire une pause !  `,
        A131113213111221312213122: `poursuivre l’aventure !  ▶️`
      },

      A131113213111221312213122: {
        question: `Le lendemain matin, il y avait deux mille hommes sous les armes au milieu des palmiers dattiers de Fayoum. Avant que le soleil ne parvînt au zénith, cinq cents guerriers apparurent à l’horizon. Les cavaliers entrèrent dans l’Oasis par le nord.<br/><br/>
        Les hommes de l’Oasis encerclèrent les cavaliers du désert. En l’espace d’une demi-heure, il y avait quatre cent quatre-vingt-dix-neuf cadavres disséminés sur le sol.<br/><br/>
        Un seul guerrier fut épargné, celui qui commandait la troupe des assaillants. Au soir, il fut amené devant les chefs de tribus, qui lui demandèrent pourquoi il avait violé la Tradition. Il répondit que ses hommes souffraient de la faim et de la soif, épuisés par tant de jours de combat, et qu’ils avaient donc résolu de s’emparer d’une oasis pour pouvoir reprendre la lutte.<br/><br/>
        Le chef suprême de l’Oasis dit qu’il regrettait pour les guerriers, mais que la Tradition devait être respectée en toute circonstance. La seule chose qui change dans le désert, ce sont les dunes, quand souffle le vent.<br/><br/>
        Puis il condamna le chef adverse à une mort déshonorante.<br/><br/>
        Le chef de tribu convoqua le jeune étranger et lui remit cinquante pièces d’or. Puis il rappela de nouveau l’histoire de Joseph en Egypte et demanda au jeune homme d’être désormais le Conseiller de l’Oasis.`,
        A1311132131112213122131221: `accepter le poste de Conseiller de l’Oasis, afin de vivre heureux avec Fatima.`,
        A1311132131112213122131222: `aller à la rencontre de l’Alchimiste.`
      },

      A1311132131112213122131221: {
        lecon:
          "Le berger décida de rester avec Fatima et d’oublier le trésor.<br/>Mais Fatima refusa car elle ne voulait pas d’un homme qui abandonne sa légende personnel à cause d’elle et quelque pièce d’or."
      },

      A1311132131112213122131222: {
        question: `👨🏾‍🦱*** Berger :*** Me voici !<br/>
        -***l’Alchimiste :*** Tu ne devrais pas être ici, Ou est-ce que ta Légende Personnelle voulait que tu viennes jusqu’en ce lieu?<br/>
        👨🏾‍🦱*** Berger :*** Il y a une guerre entre les clans. Il n’est pas possible de traverser le désert.<br/><br/>
        L’Alchimiste descendit de cheval et fit un signe pour inviter le jeune homme à entrer avec lui. C’était une tente semblable à toutes celles qu’il avait pu voir dans l’Oasis. Il chercha du regard des appareils et des fours d’alchimie, mais ne vit rien de semblable. Il y avait seulement quelques piles de livres, un fourneau pour faire la cuisine, et des tapis ornés de dessins mystérieux.<br/><br/>
        👨🏾‍🦱*** Berger :*** Pourquoi voulez-vous me voir?<br/>
        -***l’Alchimiste :*** A cause des signes. Le vent m’a conté que tu allais venir. Et que tu aurais besoin d’aide.`,
        A13111321311122131221312221: `Ce n’est pas moi. C’est l’autre étranger, l’Anglais. C’est lui qui était à votre recherche.`,
        A13111321311122131221312222: `je n’ai pas besoin d’aide, je dois simplement me rendre au pied des pyramides.`,
        A13111321311122131221312223: `j’ai décidé de rester ici avec Fatima.`
      },

      A13111321311122131221312222: {
        question: `-***l’Alchimiste :*** les affrontements font rage dans le désert, sans oublie que tu ne connais pas le chemin des pyramide. Penses-tu pouvoir atteindre ton objectif sans une aide ?`,
        A131113213111221312213122221: ` Vous aviez raison mais vous devriez aussi venir en aide à, l’Anglais il a aussi besoin de vous.`,
        A131113213111221312213122222: `Si jai pue arriver jusqu ici alors seul alors je suis capable de continuer seul`
      },

      A131113213111221312213122222: {
        lecon: `Le plus grand ennemi de la connaissance n'est pas l'ignorance mais plutôt l'illusion de la connaissance <br/><br/> Stephen Hawking`
      },

      A13111321311122131221312223: {
        lecon: "-***l’Alchimiste :*** Mektoub."
      },

      A13111321311122131221312221: {
        question: `-***l’Alchimiste :***  Il devra trouver d’autres choses avant de me trouver, moi. Mais il est sur la bonne voie. Il s’est mis à regarder le désert.<br/><br/>
        👨🏾‍🦱*** Berger :*** Et moi ?<br/>
        -***l’Alchimiste :***  Quand on veut une chose, tout l’univers conspire à nous permettre de réaliser notre rêve.<br/><br/>
        Reprenant les mots du vieux roi. Le jeune homme comprit. Ainsi, un autre homme était là, sur sa route, pour le conduire jusqu’à sa Légende Personnelle.<br/><br/>
        👨🏾‍🦱*** Berger :*** Vous allez donc m’apprendre?<br/>
        -***l’Alchimiste :***  Non. Tu sais déjà tout ce qu’il y a à savoir. Je vais simplement te mettre sur la voie, dans la direction de ton trésor.<br/>
        👨🏾‍🦱*** Berger :*** Il y a la guerre entre les clans !<br/>
        -***l’Alchimiste :*** Mais je connais le désert.<br/>
        👨🏾‍🦱*** Berger :*** J’ai déjà trouvé mon trésor. J’ai un chameau, l’argent de la boutique de cristaux, et cinquante pièces d’or. Je peux être un homme riche dans mon pays.<br/>
        -***l’Alchimiste :***  Mais rien de tout cela ne se trouve près des Pyramides.<br/>
        👨🏾‍🦱*** Berger :*** J’ai Fatima. C’est un plus grand trésor que tout ce que j’ai réussi à acquérir.<br/>
        -***l’Alchimiste :***  Elle non plus n’est pas près des Pyramides.<br/><br/>
        Ils mangèrent  en silence.<br/><br/>
        -***l’Alchimiste :***  demain, vends ton chameau et achète un cheval.`,
        A131113213111221312213122211: `Non, un cheval ne peut pas traverser le désert. `,
        A131113213111221312213122212: ` pourquoi ?`
      },

      A131113213111221312213122211: {
        lecon: `-l’Alchimiste : Puisque tu connais mieux le désert que moi. Alors bon voyage<br/>“La connaissance parle, mais la sagesse écoute.”  De Gaston Bachelard`
      },

      A131113213111221312213122212: {
        question: `-***l’Alchimiste :*** Les chameaux sont traîtres ; ils font des milliers de pas sans laisser voir aucun signe de fatigue. Et puis, tout d’un coup, ils tombent à genoux et meurent. Les chevaux, eux, se fatiguent peu à peu. Et tu sauras toujours combien tu peux encore leur demander, et le moment où ils vont mourir.<br/><br/>
        Le jeune homme n’avait pas envie d’entendre parler des Pyramides. Son cœur était lourd et triste depuis la veille au soir. Poursuivre sa quête du trésor signifiait en effet devoir abandonner Fatima.<br/><br/>
        -***l’Alchimiste :*** Je vais te guider à travers le désert.<br/>
        -👨🏾‍🦱*** Berger :***  Je veux rester dans l’Oasis, J’ai rencontré Fatima. Et pour moi elle vaut plus que le trésor.<br/>
        -***l’Alchimiste :*** Fatima est une fille du désert. Elle sait que les hommes doivent partir, pour pouvoir revenir. Elle a déjà trouvé son trésor : c’est toi. Maintenant, elle attend de toi que tu trouves ce que tu cherches.<br/>
        -👨🏾‍🦱*** Berger :***  Et si je décide de rester?<br/>
        -***l’Alchimiste :*** Tu seras le Conseiller de l’Oasis. Tu possèdes assez d’or pour acheter un bon nombre de moutons et de chameaux. Tu épouseras Fatima et vous vivrez heureux pendant la première année.<br/><br/>
        « La deuxième année, tu te rappelleras l’existence d’un trésor. Les signes commenceront à t’en parler avec insistance, et tu essaieras de ne pas en tenir compte. Tu te serviras de tes connaissances uniquement pour le bien de l’Oasis et de ses habitants. Les chefs de tribus t’en sauront gré. Tes chameaux t’apporteront richesse et pouvoir.<br/><br/>
        « La troisième année, les signes continueront à parler de ton trésor et de ta Légende Personnelle. Tu passeras des nuits et des nuits à errer dans l’Oasis, et Fatima sera une femme triste parce que ton parcours, à cause d’elle, aura été interrompu. Mais tu continueras à l’aimer, et cet amour sera partagé.<br/><br/>
        « La quatrième année, les signes t’abandonneront, parce que tu n’auras pas voulu les entendre. Les chefs de tribus le comprendront, et tu seras destitué de ta charge au Conseil. Tu seras alors un riche commerçant, possesseur de nombreux chameaux et de marchandises en abondance. Mais tu passeras le reste de tes jours à errer au milieu des palmiers et du désert, en sachant que tu n’auras pas accompli ta Légende Personnelle et qu’il sera désormais trop tard pour le faire.<br/><br/>
        « Sans avoir jamais compris que l’Amour, en aucun cas, n’empêche un homme de suivre sa Légende Personnelle. Quand cela arrive, c’est que ce n’était pas le véritable Amour, celui qui parle le Langage du Monde.<br/><br/> 
        « Quel est ta décision au finale ? »`,
        A1311132131112213122131222121: `je préfère cet avenir dans l’oasis qu’un avenir incertain dans le désert ; je reste ici.`,
        A1311132131112213122131222122: `Je vous suit.`
      },

      A1311132131112213122131222121: {
        lecon: `-***l’Alchimiste :*** Mektoub.<br/>«  Les choix que vous faites doivent être portés par vos espoirs et non vos peurs. » Nelson Mandela`
      },

      A1311132131112213122131222122: {
        question: `-***l’Alchimiste :*** Nous partirons demain avant le lever du soleil.<br/><br/>
        Le jeune homme ne put dormir cette nuit-là. Deux heures avant l’aube, il réveilla l’un des garçons qui dormaient dans la même tente et lui demanda de lui indiquer où habitait Fatima. En échange, il donna à son guide de quoi acheter une brebis.<br/><br/>
        Fatima apparut à la porte de la tente. Ils partirent ensemble marcher au milieu des palmiers.<br/><br/>
        -👨🏾‍🦱*** Berger :*** Je vais partir. Et je veux que tu saches que je reviendrai. Je t’aime parce que...<br/>
        -🧕🏽*** Fatima :*** Ne dis rien. On aime parce qu’on aime. Il n’y a aucune raison pour aimer.<br/>
        -👨🏾‍🦱*** Berger :*** Je t’aime parce que j’avais fait un rêve, puis j’ai rencontré un roi, j’ai vendu des cristaux, j’ai traversé le désert, les clans sont entrés en guerre, et je suis venu près d’un puits pour savoir où habitait un Alchimiste. Je t’aime parce que tout l’Univers a conspiré à me faire arriver jusqu’à toi.<br/><br/>
        Ils s’étreignirent. C’était la première fois que leurs corps se touchaient.<br/><br/>
        -👨🏾‍🦱*** Berger :*** Je reviendrai.<br/><br/>
        Il s’aperçut que les yeux de Fatima étaient pleins de larmes.<br/><br/>
        -👨🏾‍🦱*** Berger :*** Tu pleures?<br/>
        -🧕🏽*** Fatima :*** Je suis une femme du désert. Mais, avant tout, je suis une femme.<br/><br/>
        Fatima rentra dans sa tente. D’ici peu, le soleil allait paraître. Au lever du jour, elle sortirait faire ce qu’elle faisait depuis des années; mais tout avait changé.<br/><br/>
        De ce jour, le désert serait plus important que l’Oasis. Elle passerait son temps à regarder le désert, en se demandant sur quelle étoile le garçon se guidait, à la recherche du trésor. Elle lui adresserait ses baisers par le vent, en espérant que celui-ci toucherait le visage du jeune homme et lui dirait qu’elle était vivante, qu’elle l’attendait, comme une femme attend un homme de courage qui suit sa route, en quête de songes et de trésors. De ce jour, le désert ne serait qu’une seule chose : l’espérance de son retour.<br/><br/>
        ------------------------------<br/><br/>
        Au soir du septième jour de voyage,<br/><br/>
        -***l’Alchimiste :*** Te voici bientôt parvenu au terme de ton voyage, dit-il. Tu as suivi ta Légende Personnelle, je t’en félicite.<br/>
        -👨🏾‍🦱*** Berger :*** Mais vous me guidez sans rien dire. J’ai cru que vous alliez m’enseigner ce que vous savez. Il y a quelque temps, je me suis trouvé dans le désert en compagnie d’un homme qui possédait des livres d’alchimie. Mais je n’ai rien pu apprendre.<br/>
        -***l’Alchimiste :*** Il n’y a qu’une façon d’apprendre. C’est par l’action. Tout ce que tu avais besoin de savoir, c’est le voyage qui te l’a enseigné. Il ne manque qu’une seule chose.`,
        A13111321311122131221312221221: `Qu’est-ce qui manque encore à mon savoir ?`,
        A13111321311122131221312221222: `j’ai  beaucoup  appris de mes voyages, la seule chose qui me manque est trésor.`
      },

      A13111321311122131221312221222: {
        lecon: `Les deux hommes fut arrêter par les soldats du désert, ils ont été condamné  à mort pour espionnage en temps de guerre.<br/>L’alchimiste réussi  à s’évader en se transformant en vent. Mais le berger fut exécuter car il ne savait pas ce transformer en vent.<br/>“La connaissance parle, mais la sagesse écoute.”  De Gaston Bachelard`
      },

      A13111321311122131221312221221: {
        question: `Le jeune homme voulut savoir ce que c’était, il insista. Mais l’Alchimiste continua de fixer l’horizon.<br/><br/>
        -👨🏾‍🦱*** Berger :*** Pourquoi vous nomme-t-on l’Alchimiste?<br/>
        -***l’Alchimiste :*** Je suis un Alchimiste parce que je suis un Alchimiste. J’ai appris cette science de mes aïeux, qui l’avaient apprise de leurs aïeux, et ainsi de suite depuis la création du monde. En ce temps-là, toute la science du Grand Œuvre pouvait s’inscrire sur une simple émeraude. Mais les hommes n’ont pas attaché d’importance aux choses simples, et ont commencé à écrire des traités, des interprétations, des études philosophiques. Ils ont aussi commencé à prétendre qu’ils connaissaient la voie mieux que les autres.<br/>
        -👨🏾‍🦱*** Berger :*** Qu’y avait-il d’écrit sur la Table d’Emeraude?<br/><br/>
        L’Alchimiste entreprit alors de dessiner sur le sable, et ce travail ne lui prit pas plus de cinq minutes. Cependant qu’il dessinait, le jeune homme se souvint du vieux roi et de la place où ils s’étaient un jour rencontrés; cela semblait remonter à des années et des années.
        Le jeune homme s’approcha et lut les mots écrits sur le sable.<br/><br/>
        -👨🏾‍🦱*** Berger :***C’est un code. On dirait ce qu’il y avait dans les livres de cet Anglais.<br/>
        -***l’Alchimiste :*** Non. C’est comme le vol des éperviers ; cela ne doit pas être compris par la seule raison. La Table d’Emeraude est un passage direct vers l’Ame du Monde.<br/>
        -👨🏾‍🦱*** Berger :*** Et qu’est-ce qui n’allait pas, pour les autres alchimistes, qui cherchaient l’or et qui ont échoué?<br/>
        -***l’Alchimiste :*** Ils se contentaient de chercher l’or. Ils cherchaient le trésor de leur Légende Personnelle, sans désirer vivre la Légende elle-même.<br/>
        -👨🏾‍🦱*** Berger :*** Est-ce qu’il faut que je comprenne la Table d’Emeraude?<br/>
        -***l’Alchimiste :*** Peut-être, si tu étais dans un laboratoire d’alchimie, serait-ce maintenant le bon moment pour étudier la meilleure manière d’entendre la Table d’Emeraude. Mais tu es dans le désert. Plonge-toi donc plutôt dans le désert. Il sert à la compréhension du monde aussi bien que n’importe quelle autre chose sur terre. Tu n’as même pas besoin de comprendre le désert : il suffit de contempler un simple grain de sable, et tu verras en lui toutes les merveilles de la Création.<br/>
        -👨🏾‍🦱*** Berger :*** Comment dois-je faire pour me plonger au sein du désert?<br/>
        -***l’Alchimiste :*** Ecoute ton cœur. Il connaît toute chose, parce qu’il vient de l’Ame du Monde, et qu’un jour il y retournera.`,
        A131113213111221312213122212211: `je vais essayer !`,
        A131113213111221312213122212212: ` je ne veux pas écouter mon cœur, car la raison est supérieure. `
      },

      A131113213111221312213122212212: {
        lecon: `Les deux hommes fut arrêter par les soldats du désert, ils ont été condamné  à mort pour espionnage en temps de guerre.<br/>L’alchimiste réussi  à s’évader en se transformant en vent. Mais le berger fut exécuter car il ne savait pas ce transformer en vent.<br/>“La connaissance parle, mais la sagesse écoute.”  De Gaston Bachelard`
      },

      A131113213111221312213122212211: {
        question: `Ils cheminèrent en silence deux journées encore. L’Alchimiste se montrait beaucoup plus circonspect, car ils approchaient de la zone des combats les plus violents. Et le jeune homme s’efforçait d’écouter son cœur. C’était un cœur difficile à entendre.<br/><br/>
        -👨🏾‍🦱*** Berger :*** Pourquoi devons-nous écouter notre cœur? <br/>
        -***l’Alchimiste :*** Parce que, là où sera ton cœur, là sera ton trésor.<br/>
        -👨🏾‍🦱*** Berger :*** Mon cœur est agité. Il fait des rêves, il se trouble, et il est amoureux d’une fille du désert. Il me demande des choses, me laisse des nuits et des nuits sans dormir quand je pense à elle.<br/>
        -***l’Alchimiste :*** C’est bien. Ton cœur est vivant. Continue à écouter ce qu’il a à te dire.
        Au cours des trois journées suivantes, ils aperçurent  plusieurs guerriers à l’horizon. Le cœur du jeune homme commença à parler de peur.<br/>
        -👨🏾‍🦱*** Berger :*** Mon cœur est traître.  Il ne veut pas que je continue.<br/>
        -***l’Alchimiste :*** C’est bien. Cela prouve que ton cœur vit. Il est normal d’avoir peur d’échanger contre un rêve tout ce que l’on a déjà réussi à obtenir.<br/><br/>
        Le jeune homme continua donc à écouter son cœur, tandis qu’ils cheminaient dans le désert. Il parvint à connaître ses ruses et ses stratagèmes, et finit par l’accepter comme il était. Alors, il cessa d’avoir peur et cessa d’avoir envie de retourner sur ses pas, car un certain soir son cœur lui dit qu’il était content. « Même si je me plains un peu, disait son cœur, c’est seulement que je suis un cœur d’homme, et les cœurs des hommes sont ainsi. Ils ont peur de réaliser leurs plus grands rêves, parce qu’ils croient ne pas mériter d’y arriver, ou ne pas pouvoir y parvenir. Nous, les cœurs, mourons de peur à la seule pensée d’amours enfuis à jamais, d’instants qui auraient pu être merveilleux et qui ne l’ont pas été, de trésors qui auraient pu être découverts et qui sont restés pour toujours enfouis dans le sable. Car, quand cela se produit, nous souffrons terriblement, pour finir. »<br/><br/>
        -👨🏾‍🦱*** Berger :*** Mon cœur craint de souffrir.<br/>
        -***l’Alchimiste :*** Dis-lui que la crainte de la souffrance est pire que la souffrance elle-même. Et qu’aucun cœur n’a jamais souffert alors qu’il était à la poursuite de ses rêves, parce que chaque instant de quête est un instant de rencontre avec Dieu et avec l’Eternité.<br/><br/>
        Le jeune homme, de ce jour, entendit son cœur. Il lui demanda de ne jamais l’abandonner. Il lui demanda de se serrer dans sa poitrine lorsqu’il serait loin de ses rêves, et de lui donner le signal d’alarme. Et il jura que, chaque fois qu’il entendrait ce signal, il y prendrait garde. Cette nuit-là, il parla de tous ces sujets avec l’Alchimiste. Et celui-ci comprit que le cœur du jeune homme était revenu à l’Ame du Monde.<br/><br/>
        -👨🏾‍🦱*** Berger :*** Que dois-je faire maintenant?<br/>
        -***l’Alchimiste :*** Continue de marcher dans la direction des Pyramides. Et reste attentif aux signes. Ton cœur est maintenant capable de te montrer le trésor.`,
        A1311132131112213122131222122111: `dans ce cas, je n’ai plus besoin de vous je vais continuer seul.`,
        A1311132131112213122131222122112: `C’était donc cela, que je ne savais pas encore?`
      },

      A1311132131112213122131222122111: {
        lecon: `Les deux hommes fut arrêter par les soldats du désert, ils ont été condamné  à mort pour espionnage en temps de guerre.<br/>L’alchimiste réussi  à s’évader en se transformant en vent. Mais le berger fut exécuter car il ne savait pas ce transformer en vent.<br/>“La connaissance parle, mais la sagesse écoute.”  De Gaston Bachelard`
      },

      A1311132131112213122131222122112: {
        question: `-***l’Alchimiste :*** Non. Ce qui manque encore à ton savoir, dit l’Alchimiste, c’est ceci ;  Avant de réaliser un rêve, l’Ame du Monde veut toujours évaluer tout ce qui a été appris durant le parcours. Si elle agit ainsi, ce n’est pas par méchanceté à notre égard, c’est pour que nous puissions, en même temps que notre rêve, conquérir également les leçons que nous apprenons en allant vers lui. Et c’est le moment où la plupart des gens renoncent. C’est ce que nous appelons, dans le langage du désert : mourir de soif quand les palmiers de l’oasis sont déjà en vue à l’horizon. Une quête commence toujours par la Chance du Débutant. Et s’achève toujours par l’Epreuve du Conquérant.<br/><br/> 
        Le soleil avait commencé à décliner quand le cœur du jeune homme donna le signal d’un danger. Ils étaient entourés de dunes énormes, et le garçon regarda l’Alchimiste; mais celui-ci, apparemment, n’avait rien remarqué. Cinq minutes plus tard, droites devant eux, il aperçut deux cavaliers dont les silhouettes se découpaient sur le couchant. Avant qu’il eût pu dire quoi que ce fût à l’Alchimiste, les deux cavaliers étaient devenus dix, puis cent, et pour finir toute l’étendue des dunes en fut couverte. C’étaient des guerriers vêtus de bleu, avec un triple anneau de couleur noire autour du turban. Même à cette distance, les yeux montraient la force des âmes. Et ces yeux parlaient de mort.<br/><br/> 
        Les deux voyageurs firent conduits jusqu’à un campement militaire qui se trouvait à proximité. Un soldat poussa l’Alchimiste et son compagnon à l’intérieur d’une tente, bien différente de celles qui se trouvaient dans l’Oasis. Il y avait là un chef de guerre entouré de son état-major.<br/><br/> 
        -***Soldat :*** Ce sont les espions ; On vous a vus dans le camp ennemi il y a trois jours.<br/> 
        -***l’Alchimiste :*** Je suis un homme qui marche dans le désert et qui connaît les étoiles. Je n’ai aucune information sur les troupes ou les mouvements des tribus. Je guidais seulement mon ami jusqu’ici.<br/> 
        -***chef :*** Qui est ton ami?<br/> 
        -***l’Alchimiste :*** Un alchimiste. Il connaît les pouvoirs de la nature. Et souhaite montrer au commandant ses extraordinaires capacités.<br/> 
        -***Officier :*** Que fait un étranger en terre étrangère?<br/> 
        -***l’Alchimiste :*** J’ai apporté de l’argent pour l’offrir à votre clan.<br/><br/> 
        Intervint alors l’Alchimiste, avant que le jeune homme eût pu prononcer un seul mot. Et, s’emparant de sa bourse, il donna les pièces d’or au chef. Celui-ci les prit sans rien dire. Il y avait là de quoi acheter un grand nombre d’armes.<br/><br/> 
        -***Chef :*** Qu’est-ce qu’un alchimiste?<br/>
        -***l’Alchimiste :*** Un homme qui connaît la nature et le monde. S’il le voulait, il détruirait ce campement en utilisant la seule force du vent.<br/><br/> 
        Les hommes rirent. Ils avaient l’habitude des violences de la guerre, et savaient que le vent ne peut pas asséner un coup mortel. Pourtant, chacun sentit son cœur se serrer dans sa poitrine.<br/><br/> 
        -***Chef :*** J’aimerais voir une chose pareille.<br/>
        -***l’Alchimiste :*** Il nous faut trois jours, répondit l’Alchimiste. Il va se transformer en vent, simplement pour montrer la force de son pouvoir. S’il ne réussit pas, nous vous offrons humblement nos vies, pour l’honneur de votre clan.`,
        A13111321311122131221312221221121: `je ne suis pas un alchimiste et  je ne suis pas capable de me transformer en vent.`,
        A13111321311122131221312221221122: `écouter en silence.`
      },

      A13111321311122131221312221221121: {
        lecon: `Les deux hommes fut arrêter par les soldats du désert, ils ont été condamné  à mort pour espionnage en temps de guerre.<br/>L’alchimiste réussi  à s’évader en se transformant en vent. Mais le berger fut exécuter car il ne savait pas ce transformer en vent.<br/>“La connaissance parle, mais la sagesse écoute.”  De Gaston Bachelard`
      },

      A13111321311122131221312221221122: {
        question: `-***Chef :*** Tu ne peux m’offrir ce qui m’appartient déjà.<br/><br/>
        Déclara le chef avec arrogance. Mais il concéda aux voyageurs le délai de trois jours.
        Le jeune homme, terrifié, était incapable de faire un mouvement. L’Alchimiste dut le tenir par le bras pour l’aider à sortir de la tente.<br/><br/>
        -***l’Alchimiste :*** Ne leur montre pas que tu as peur. Ce sont des hommes braves, et ils méprisent les lâches.<br/>
        -👨🏾‍🦱*** Berger :*** Vous leur avez donné tout mon trésor! Tout ce que j’avais pu gagner pendant toute ma vie!<br/>
        -***l’Alchimiste :*** Et à quoi cela te servirait-il si tu devais mourir? Ton argent t’a sauvé pour trois jours. Ce n’est pas si souvent que l’argent sert à retarder la mort.<br/><br/>
        Mais le jeune homme était trop effrayé pour pouvoir entendre des paroles de sagesse.<br/><br/>
        -***l’Alchimiste :*** « Ne t’abandonne pas au désespoir. Cela t’empêche de pouvoir converser avec ton cœur.<br/>
        -👨🏾‍🦱*** Berger :*** Mais je ne sais pas me transformer en vent.<br/>
        -***l’Alchimiste :*** Celui qui vit sa Légende Personnelle sait tout ce qu’il a besoin de savoir. Il n’y a qu’une chose qui puisse rendre un rêve impossible : c’est la peur d’échouer.<br/>
        -👨🏾‍🦱*** Berger :*** Je n’ai pas peur d’échouer. Simplement, je ne sais pas me transformer en vent.<br/>
        -***l’Alchimiste :*** Eh bien, il faudra que tu apprennes ! Ta vie en dépend.<br/>
        -👨🏾‍🦱*** Berger :*** Et si je n’y arrive pas?<br/>
        -***l’Alchimiste :*** Tu mourras d’avoir vécu ta Légende Personnelle. Cela vaut bien mieux que de mourir comme des millions de gens qui n’auront jamais rien su de l’existence d’une Légende Personnelle. Mais ne t’inquiète pas. En général, la mort fait que l’on devient plus attentif à la vie.<br/><br/> 
        Le premier jour s’écoula. Il y eut une grande bataille dans les environs, et de nombreux blessés furent amenés au campement. « Rien ne change avec la mort », pensait le jeune homme. Les guerriers qui mouraient étaient remplacés par d’autres, et la vie continuait.<br/><br/>
        Vers le soir, le jeune homme alla trouver l’Alchimiste, qui emmenait le faucon avec lui dans le désert.<br/><br/>
        -👨🏾‍🦱*** Berger :*** Je ne sais pas me transformer en vent.<br/>
        -***l’Alchimiste :*** Souviens-toi de ce que je t’ai dit ; le monde n’est que la partie visible de Dieu. Et l’Alchimie, c’est simplement amener la perfection spirituelle sur le plan matériel.<br/>
        -👨🏾‍🦱*** Berger :*** Que faites-vous?<br/>
        -***l’Alchimiste :*** Je nourris mon faucon.<br/>
        -👨🏾‍🦱*** Berger :*** Si je ne réussis pas à me transformer en vent, nous allons mourir. A quoi bon nourrir le faucon?<br/>
        -***l’Alchimiste :*** Toi, tu mourras, répondit l’Alchimiste. Moi, je sais me transformer en vent.<br/><br/>
        Le deuxième jour, le jeune homme grimpa au sommet d’un rocher qui se trouvait près du camp. Les sentinelles le laissèrent passer; elles avaient entendu parler du sorcier qui se transformait en vent, et ne voulaient pas l’approcher. De plus, le désert constituait une grande muraille infranchissable. Il passa le reste de l’après-midi de cette deuxième journée à regarder le désert. Il écouta son cœur. Et le désert écouta la peur qui l’habitait. Tous deux parlaient la même langue.`,
        A131113213111221312213122212211221: `Profiter de la nuit pour s’enfuir hors du camp !`,
        A131113213111221312213122212211222: ` Rester !`
      },

      A131113213111221312213122212211221: {
        lecon: `Les deux hommes fut arrêter par les soldats du désert, ils ont été condamné  à mort pour espionnage en temps de guerre.<br/>L’alchimiste réussi  à s’évader en se transformant en vent. Mais le berger fut exécuter car il ne savait pas ce transformer en vent.<br/>“La connaissance parle, mais la sagesse écoute.”  De Gaston Bachelard`
      },

      A131113213111221312213122212211222: {
        question: `Le troisième jour, le chef suprême rassembla autour de lui ses principaux officiers.<br/><br/>
        -***Chef :*** Allons voir ce garçon qui se transforme en vent.<br/>
        -***l’Alchimiste :***  Allons! <br/><br/>
         Le jeune homme les conduisit à l’endroit où il était venu la veille. Puis il demanda à tous de s’asseoir.<br/><br/>
        -👨🏾‍🦱*** Berger :*** Cela va demander un peu de temps.<br/>
        -***Chef :*** Nous ne sommes pas pressés. Nous sommes des hommes du désert.<br/><br/>
        Le jeune homme se mit à regarder l’horizon en face de lui.<br/><br/>
        -***Désert :*** Que me veux-tu aujourd’hui?  Ne nous sommes-nous pas assez contemplés hier?<br/>
        -👨🏾‍🦱*** Berger :*** Tu gardes, quelque part, celle que j’aime. Alors, quand je regarde tes étendues de sable, c’est elle que je contemple aussi. Je veux retourner vers elle et j’ai besoin de ton aide pour me transformer en vent.<br/>
        -***Désert :*** Qu’est-ce que l’amour?<br/>
        -👨🏾‍🦱*** Berger :*** L’amour, c’est quand le faucon vol au-dessus de tes sables. Car, pour lui, tu es une campagne verdoyante, et il n’est jamais revenu sans sa proie. Il connaît tes rochers, tes dunes, tes montagnes, et tu es généreux avec lui.<br/>
        -***Désert :*** Le bec du faucon m’arrache des morceaux. Cette proie, je la nourris pendant des années, je l’abreuve du peu d’eau que j’ai, lui montre où elle peut trouver à manger; et, un beau jour, voici que le faucon descend du ciel, juste comme j’allais sentir la caresse du gibier sur mes sables. Et le faucon emporte ce que j’avais fait grandir.<br/>
        -👨🏾‍🦱*** Berger :***Mais c’était précisément à cette fin que tu avais nourri et fait grandir le gibier, répondit le jeune homme: pour alimenter le faucon. Et le faucon alimentera l’homme. Et l’homme alimentera un jour tes sables, d’où naîtra à nouveau le gibier. Ainsi va le monde.<br/>
        -***Désert :*** C’est cela, l’amour?<br/>
        -👨🏾‍🦱*** Berger :*** C’est cela, oui. C’est ce qui fait que la proie se transforme en faucon, le faucon en homme, et l’homme à nouveau en désert. C’est cela qui fait que le plomb se transforme en or, et que l’or retourne se cacher sous la terre.<br/><br/>
        Le désert resta quelques instants silencieux.<br/><br/>
        -***Désert :*** Je te donne mes sables pour que le vent puisse souffler. Mais, à moi seul, je ne puis rien. Demande son aide au vent.<br/><br/>
        Une petite brise se mit à souffler. Les chefs de guerre observaient de loin le jeune homme, qui parlait une langue inconnue d’eux. L’Alchimiste souriait.<br/><br/>
        Le vent arriva près du jeune homme et lui effleura le visage. Il avait entendu sa conversation avec le désert, car les vents savent toujours tout. Ils parcourent le monde sans avoir jamais de lieu de naissance ni de lieu où mourir.<br/><br/>
        -***🌫Le vent :*** Qui t’a appris à parler le langage du désert et du vent?<br/>
        -👨🏾‍🦱*** Berger :*** Mon cœur.<br/>
        -***🌫Le vent :*** Tu ne peux être le vent. Nos natures sont différentes.<br/>
        -👨🏾‍🦱*** Berger :*** Ce n’est pas vrai. J’ai appris les secrets de l’Alchimie, tandis que je parcourais le monde avec toi. J’ai en moi les vents, les déserts, les océans, les étoiles, et tout ce qui a été créé dans l’Univers. Nous avons été faits par la même Main, et nous avons la même Ame. Je veux être comme toi, pénétrer partout, traverser les mers, ôter le sable qui recouvre mon trésor.<br/><br/>
        Le vent était très orgueilleux, et ce que disait le jeune homme l’irrita. Il se mit à souffler plus fort, soulevant les sables du désert. Mais il dut finalement reconnaître que, même après avoir parcouru le monde entier, il ne savait toujours pas transformer un homme en vent. Et il ne connaissait pas l’Amour.<br/><br/>
        -***🌫Le vent :*** Au cours de mes promenades à travers le monde, j’ai remarqué que beaucoup de gens parlaient de l’amour en regardant vers le ciel, dit le vent, furieux de devoir admettre ses limites. Peut-être vaudrait-il mieux demander au ciel.<br/>
        -👨🏾‍🦱*** Berger :*** Alors, aide-moi. Couvre ce lieu de poussière, pour que je puisse regarder le soleil sans être aveuglé.<br/><br/>
        Dans le camp, il devenait difficile de distinguer quoi que ce fût. Les chevaux hennissaient, les armes commencèrent à être recouvertes par le sable, les regards n’exprimaient plus que la frayeur. Sur le rocher, l’un des officiers se tourna vers le chef suprême et dit ;<br/><br/>
        -***Officier :*** Il vaudrait peut-être mieux en rester là !<br/>
        -***Chef :*** Je veux voir la grandeur d’Allah (dit le chef, avec du respect dans la voix). Je veux voir la transformation d’un homme en vent.`,
        A1311132131112213122131222122112221: `poursuivre l’aventure !  ▶️"`,
        A1311132131112213122131222122112222: `faire une pause !  ⏸️`
      },

      A1311132131112213122131222122112221: {
        question: `-👨🏾‍🦱*** Berger :*** Le vent m’a dit que tu connaissais l’Amour, dit le jeune homme au Soleil. Si tu connais l’Amour, tu connais aussi l’Âme du Monde, qui est faite d’Amour.<br/>
        -☀️***Le Soleil :*** D’où je suis, je peux voir l’âme du Monde. Elle est en communication avec mon âme et, à nous deux, nous faisons ensemble croître les plantes et avancer les brebis qui recherchent l’ombre. D’où je suis (et je suis très loin du monde), j’ai appris à aimer. Je sais que, si je m’approche un peu plus de la Terre, tout ce qu’elle porte périra et l’âme du Monde cessera d’exister. Alors, nous nous regardons mutuellement et nous nous aimons; je lui donne vie et chaleur, elle me donne une raison de vivre.<br/>
        -👨🏾‍🦱*** Berger :*** Tu connais l’Amour ?<br/>
        -☀️***Le Soleil :***  Et je connais l’âme du Monde, car nous avons de longues conversations au cours de ce voyage sans fin dans l’Univers. Elle me dit que son plus grave problème est que, jusqu’ici, seuls les minéraux et les végétaux ont compris que tout est une seule et unique chose. Et, pour autant, il n’est pas nécessaire que le fer soit semblable au cuivre et le cuivre semblable à l’or. Chacun remplit sa fonction exacte dans cette chose unique, et tout serait une Symphonie de Paix si la Main qui a écrit tout cela s’était arrêtée au cinquième jour. Mais il y a eu le sixième jour.<br/>
        -👨🏾‍🦱*** Berger :*** Tu es savant parce que tu vois tout à distance, dit le jeune homme. Mais tu ne connais pas l’Amour. S’il n’y avait pas eu de sixième jour, l’homme ne serait pas , l’homme ne serait pas, le cuivre serait toujours du cuivre et le plomb toujours du plomb. Chacun a sa Légende Personnelle, c’est vrai, mais un jour cette Légende Personnelle sera accomplie. Il faut donc se transformer en quelque chose de mieux, et avoir une nouvelle Légende Personnelle jusqu’à ce que l’Ame du Monde soit réellement une seule et unique chose.<br/><br/>
        Le Soleil resta songeur et se mit à briller plus fort. Le vent, qui appréciait l’entretien, souffla également plus fort, pour que le Soleil n’aveuglât pas le jeune homme.<br/><br/>
        -👨🏾‍🦱*** Berger :*** Les alchimistes parviennent à réaliser cette transformation. Ils nous montrent que, lorsque nous cherchons à être meilleurs que nous ne le sommes, tout devient meilleur aussi autour de nous.<br/>
        -☀️***Le Soleil :***  Qu’attends-tu de moi ?<br/>
        -👨🏾‍🦱*** Berger :*** Que tu m’aides à me transformer en vent.<br/>
        -***Le Soleil :*** La Nature me connaît comme la plus savante de toutes les créatures, dit le Soleil. Mais je ne sais comment te transformer en vent.<br/>
        -👨🏾‍🦱*** Berger :*** A qui dois-je m’adresser, alors ?<br/><br/>
        Le Soleil se tut un moment. Le vent écoutait, et allait répandre dans le monde entier que sa science était limitée. Il ne pouvait cependant pas échapper à ce jeune homme qui parlait le Langage du Monde.<br/><br/>
        -☀️***Le Soleil :***  Vois la Main qui a tout écrit.<br/><br/>
        Le vent poussa un cri de satisfaction et souffla avec plus de force que jamais. Les tentes dressées sur le sable furent bientôt arrachées, tandis que les animaux se libéraient de leurs attaches. Sur le rocher, les hommes s’agrippèrent les uns aux autres pour éviter d’être emportés.`,
        A13111321311122131221312221221122211: `Je doit arrêter de parler le Langage du Monde, car cela devient de plus en plus dangereux, pour moi et les autres.`,
        A13111321311122131221312221221122212: `Se tourner vers la Main qui avait tout écrit.`
      },

      A13111321311122131221312221221122211: {
        lecon: `Le chef ne fut pas convaincu, car malgré la puissance du vent et de la poussière le jeune homme était toujours visible au même endroit.<br/>«  Les choix que vous faites doivent être portés par vos espoirs et non vos peurs. » Nelson Mandela`
      },

      A13111321311122131221312221221122212: {
        question: `Alors, le jeune homme se tourna vers la Main qui avait tout écrit. Et, au lieu de dire le moindre mot, il sentit que l’Univers demeurait silencieux, et demeura silencieux de même. Un élan d’amour jaillit de son cœur, et il se mit à prier. C’était une prière qu’il n’avait encore jamais faite, car c’était une prière sans parole, et par laquelle il ne demandait rien. Il ne remerciait pas d’avoir pu trouver un pâturage pour ses moutons; il n’implorait pas d’arriver à vendre davantage de cristaux; il ne demandait pas que la femme qu’il avait rencontrée attende son retour.<br/><br/>
        Et le jeune homme se plongea dans l’Ame du Monde, et vit que l’Ame du Monde faisait partie de l’Ame de Dieu, et vit que l’Ame de Dieu était sa propre âme. Et qu’il pouvait, dès lors, réaliser des miracles.<br/><br/>
        Le simoun souffla ce jour-là comme jamais encore il n’avait soufflé. Quand le simoun eut cessé de souffler, tous portèrent leurs regards vers l’endroit où se trouvait le jeune homme. Il n’était plus là, mais se trouvait à côté d’une sentinelle presque entièrement recouverte de sable qui surveillait l’autre côté du camp. Les hommes étaient épouvantés par la sorcellerie. Deux personnes, cependant, souriaient : l’Alchimiste, parce qu’il avait trouvé son véritable disciple, et le chef suprême, parce que ce disciple avait entendu la gloire de Dieu.<br/><br/>
        Le lendemain, le chef fit ses adieux au jeune homme et à l’Alchimiste, et les fit accompagner par une escorte jusqu’à l’endroit où ils souhaiteraient se rendre.<br/><br/>
        Ils marchèrent toute une journée. A la tombée du soir, ils arrivèrent devant un monastère copte. L’Alchimiste renvoya l’escorte et mit pied à terre.<br/><br/>
        -***l’Alchimiste :*** A partir d’ici, tu vas aller seul, dit-il. Il n’y a que trois heures de marche jusqu’aux Pyramides.<br/>
        -👨🏾‍🦱*** Berger :*** Merci. Vous m’avez appris le Langage du Monde.<br/>
        -***l’Alchimiste :*** Je n’ai fait que te rappeler ce que tu savais déjà.<br/><br/>
        L’Alchimiste frappa à la porte du monastère. Un moine tout habillé de noir vint leur ouvrir. Ils s’entretinrent un moment en langue copte, puis l’Alchimiste fit entrer le jeune homme.<br/><br/>
        -***l’Alchimiste :*** Je lui ai demandé de me laisser utiliser la cuisine pour un moment.<br/><br/>
        L’Alchimiste alluma le feu, et le moine apporta un peu de plomb, que l’Alchimiste fit fondre dans un récipient en fer. Quand le plomb fut devenu liquide, il prit dans son sac ce curieux œuf de verre jaune qu’il avait. Il en racla une pellicule de l’épaisseur d’un cheveu, l’enveloppa de cire, et la jeta dans le récipient qui contenait le plomb fondu. Quand la préparation eut refroidi, le moine et le jeune homme regardèrent avec émerveillement : le métal avait séché tout autour de la paroi interne du récipient, mais ce n’était plus du plomb. C’était de l’or.<br/><br/>
        Ils retournèrent vers l’entrée du couvent. Là, l’Alchimiste partagea le disque en quatre morceaux.<br/><br/>
        -***L’Alchimiste :*** Ceci est pour vous(en présentant l’une des parts au moine). Pour votre générosité à l’égard des pèlerins.<br/>
        -***le moine :*** C’est là un remerciement qui va bien au-delà de ma générosité.<br/>
        -***L’Alchimiste :*** Ne parlez pas ainsi. La vie peut entendre, et vous donner moins une autre fois.
        Puis il s’approcha du jeune homme.<br/>
        -***L’Alchimiste :*** Voici pour toi. Pour remplacer l’or qui est resté entre les mains du chef de guerre. Cette portion est pour moi. Car je dois retourner en traversant à nouveau le désert, et il y a toujours la guerre entre les clans.<br/><br/>
        Il prit alors le quatrième morceau et le donna encore au moine.<br/><br/>
        -***L’Alchimiste :*** Cette part est pour le garçon qui est là. Au cas où il en aurait besoin.`,
        A131113213111221312213122212211222121: `pourquoi cette part supplémentaire ?`,
        A131113213111221312213122212211222122: `suis maintenant tout proche du trésor. Je n’ai pas besoin d’une part supplémentaire.`
      },

      A131113213111221312213122212211222122: {
        lecon: `“Tout ce qui arrive une fois peut ne plus jamais arriver. Mais tout ce qui arrive deux fois arrivera certainement une troisième fois. ”<br/>--***L’Alchimiste :*** tu as perdu l’argent que tu avais gagné au cours de ton voyage ; avec le voleur, et avec le chef de guerre. Je suis un vieil Arabe superstitieux, qui croit aux proverbes de mon pays.`
      },

      A131113213111221312213122212211222121: {
        question: `-***L’Alchimiste :*** Parce que, deux fois déjà, tu as perdu l’argent que tu avais gagné au cours de ton voyage ; avec le voleur, et avec le chef de guerre. Je suis un vieil Arabe superstitieux, qui croit aux proverbes de mon pays. Et il en est un qui dit ceci ; “Tout ce qui arrive une fois peut ne plus jamais arriver. Mais tout ce qui arrive deux fois arrivera certainement une troisième fois. ”<br/><br/>
        L’Alchimiste fit avancer son cheval.<br/><br/>
        -***L’Alchimiste :*** Quoi qu’elle fasse, dit-il, toute personne sur terre joue toujours le rôle principal de l’Histoire du monde. Et normalement elle n’en sait rien.<br/><br/>
        Le jeune homme sourit. Il n’avait jamais imaginé que la vie pût être si importante pour un berger.<br/><br/>
        -***L’Alchimiste :*** Adieu !<br/><br/>
        -👨🏾‍🦱*** Berger :*** Adieu !<br/><br/>
        Il chemina pendant deux heures et demie dans le désert, en essayant d’écouter attentivement ce que disait son cœur. C’était lui qui allait lui révéler le lieu exact où son trésor était caché. « Là où sera ton trésor, là sera également ton cœur », avait dit l’Alchimiste. Mais son cœur parlait d’autres choses. Il contait avec orgueil l’histoire d’un berger qui avait quitté ses moutons pour suivre un rêve qu’il avait fait deux fois.<br/><br/>
        C’est alors qu’il se préparait à gravir une dune, et à ce moment-là seulement, que son cœur murmura à son oreille : « Fais bien attention à l’endroit où tu pleureras; car c’est là que je me trouve, et c’est là que se trouve ton trésor. »<br/><br/>
        Quand, au bout de quelques minutes, il parvint au sommet de la dune, son cœur bondit dans sa poitrine. Illuminées par la pleine lune et la blancheur du désert, majestueuses, imposantes, se dressaient devant lui les Pyramides d’Egypte. Il tomba à genoux et pleura. Il remerciait Dieu d’avoir cru à sa Légende Personnelle, et d’avoir certain jour rencontré un roi, puis un marchand, un Anglais, un alchimiste. Et, par-dessus tout, d’avoir rencontré une femme du désert, qui lui avait fait comprendre que jamais l’Amour ne pourrait éloigner un homme de sa Légende Personnelle.<br/><br/>
        Mais il était arrivé à son trésor, et une œuvre n’est achevée que lorsque l’objectif est atteint. Là, au sommet de cette dune, il avait pleuré. Il regarda par terre et vit qu’à l’endroit où étaient tombées ses larmes un scarabée se promenait. Pendant ce temps qu’il avait passé dans le désert, il avait appris que les scarabées, en Egypte, étaient le symbole de Dieu. C’était encore un signe. Alors, il se mit à creuser.<br/><br/>
        Toute la nuit, il creusa à l’emplacement indiqué, sans rien trouver. Ses mains se fatiguèrent, finirent par être blessées, mais il continuait à croire en son cœur. Et son cœur lui avait dit de creuser où ses larmes seraient tombées.<br/><br/>
        Tout à coup, il entendit des pas. Quelques hommes s’approchèrent.<br/><br/>
        -***Inconnu1 :*** Que fais-tu là?<br/><br/>
        Il ne répondit pas. Mais il eut peur. Il avait maintenant un trésor à déterrer, et c’est pourquoi il avait peur.<br/><br/>
        -***Inconnu2 :*** Nous sommes des réfugiés de guerre, dit un autre. Nous avons besoin de savoir ce que tu caches là. Nous avons besoin d’argent<br/>
        -👨🏾‍🦱*** Berger :*** Je ne cache rien !<br/><br/>
        Mais l’un des hommes le prit par le bras et le tira hors du trou. Un autre se mit à le fouiller. Et ils trouvèrent le morceau d’or qui était dans l’une de ses poches.<br/><br/>
        -***Inconnu3 :*** Il a de l’or !<br/><br/>
        Le clair de lune illumina le visage de celui qui était en train de le fouiller et, dans ses yeux, il vit la mort.<br/><br/>
        -***Inconnu4 :*** Il doit y avoir encore de l’or caché dans la terre.<br/><br/>
        Et ils le forcèrent à continuer de creuser. Comme il ne trouvait toujours rien, ils commencèrent à le frapper. Ils le battirent longtemps, jusqu’à l’apparition des premiers rayons du soleil. Ses vêtements étaient en lambeaux, et il sentit que la mort était proche.`,
        A1311132131112213122131222122112221211: `révéler l’existence du trésor aux assaillants.`,
        A1311132131112213122131222122112221212: `ne rien dire sur l’existence du trésor.`
      },

      A1311132131112213122131222122112221212: {
        lecon: `« A quoi sert l’argent, si l’on doit mourir? Il est bien rare que l’argent puisse sauver quelqu’un de la mort » : ainsi avait dit l’Alchimiste.`
      },

      A1311132131112213122131222122112221211: {
        endlecon: `« A quoi sert l’argent, si l’on doit mourir? Il est bien rare que l’argent puisse sauver quelqu’un de la mort » : ainsi avait dit l’Alchimiste.<br/><br/>
        -👨🏾‍🦱*** Berger :*** Je cherche un trésor !<br/><br/>
        Et, malgré les blessures qu’il avait à la bouche, enflée à la suite des coups reçus, il raconta à ses assaillants qu’il avait rêvés par deux fois d’un trésor enfoui à proximité des Pyramides d’Egypte. Celui qui paraissait être le chef resta un long moment silencieux. Puis il s’adressa à l’un de ces acolytes ;<br/><br/>
        -***Chef des assaillants :*** On peut le laisser aller. Il n’a rien d’autre. Cet or, il avait dû le voler.<br/><br/>
        Le jeune homme tomba la face sur le sable. Deux yeux cherchèrent les siens; c’était le chef de la bande. Mais le jeune homme regardait dans la direction des Pyramides.<br/><br/>
        -***Chef des assaillants :*** Allons-nous-en !<br/><br/>
        Puis il se tourna vers le jeune homme ;<br/><br/>
        -***Chef des assaillants :*** Tu ne vas pas mourir, lui dit-il. Tu vas vivre, et apprendre qu’on n’a pas le droit d’être aussi bête. Ici, exactement là où tu te trouves, il y a maintenant près de deux ans, j’ai fait un rêve qui s’est répété. J’ai rêvé que je devais aller en Espagne, chercher dans la campagne une église en ruine où les bergers allaient souvent dormir avec leurs moutons, et où un sycomore poussait dans la sacristie; et si je creusais au pied de ce sycomore, je trouverais un trésor caché. Mais je ne suis pas assez bête pour aller traverser tout le désert simplement parce que j’ai fait deux fois le même rêve.<br/><br/>
        Puis il partit.<br/><br/>
        Le jeune homme se releva, non sans mal, et regarda une fois encore les Pyramides. Les Pyramides lui sourirent, et il leur sourit en retour, le cœur empli d’allégresse. Il avait trouvé le trésor.<br/><br/><br/>
        <h3>Épilogue</h3><br/>
        Il se nommait Santiago. Il arriva à la petite église abandonnée alors que la nuit était déjà tout près de tomber. Le sycomore poussait toujours dans la sacristie. Il se souvint qu’une fois il était venu là avec ses brebis et qu’il avait passé une nuit paisible, à l’exception du rêve qu’il avait fait. Maintenant, il était là sans son troupeau. Mais il avait avec lui une pelle.<br/><br/>
        Il resta longtemps à contempler le ciel. Il pensa à tous les chemins qu’il avait parcourus, et à l’étrange façon dont Dieu lui avait montré le trésor. S’il n’avait pas cru aux rêves qui se répètent, il n’aurait pas rencontré la gitane, ni le roi, ni le voleur, ni... « La liste est bien longue, c’est vrai; mais le chemin était jalonné par les signes, et je ne pouvais pas me tromper » , se dit-il.<br/><br/>
        Il s’endormit sans en avoir conscience et, quand il s’éveilla, le soleil était déjà haut. Alors, il se mit à creuser au pied du sycomore.<br/><br/>
        « Vieux sorcier, se disait-il, tu étais au courant de tout. Tu as même laissé un peu d’or pour que je puisse revenir jusqu’à cette église. Le moine a bien ri quand il m’a vu reparaître en haillons. Est-ce que tu ne pouvais pas m’épargner cela? » Il entendit le vent lui répondre : « Non. Si je te l’avais dit, tu n’aurais pas vu les Pyramides. Elles sont très belles, tu ne trouves pas? »<br/><br/>
        C’était la voix de l’Alchimiste. Il sourit, et se remit à creuser. Au bout d’une demi-heure, la pelle heurta quelque chose de dur. Une heure après, il avait devant lui un coffre plein de vieilles pièces d’or espagnoles. Il y avait également des pierres précieuses, des masques en or avec des plumes blanches et rouges, des idoles de pierre incrustées de brillants… <br/><br/>
        Il tira de sa besace Ourim et Toumim. Il ne s’était servi des deux pierres qu’une seule fois, sur un marché, un certain matin. La vie et sa route avaient toujours été peuplées de signes. Il rangea Ourim et Toumim dans le coffre d’or. Ces deux pierres faisaient, elles aussi, partie de son trésor, puisqu’elles rappelaient le souvenir de ce vieux roi qu’il ne rencontrerait plus jamais. « En vérité, la vie est généreuse pour celui qui vit sa Légende Personnelle », pensa-t-il.<br/><br/>
        Et il se souvint alors qu’il devait aller à Tarifa, et donner la dixième partie de tout cela à la gitane. « Comme les gitans sont malins! » se dit-il. Peut-être parce qu’ils voyageaient tellement.
        Mais le vent se remit à souffler. C’était le levant, le vent qui venait d’Afrique. Il n’apportait pas l’odeur du désert, ni la menace d’une invasion des Maures. En échange, il apportait un parfum qu’il connaissait bien, et le murmure d’un baiser, qui arriva doucement, tout doucement, pour se poser sur ses lèvres. Il sourit. C’était la première fois qu’elle faisait cela.<br/><br/>
        -👨🏾‍🦱*** Berger :*** Me voici, Fatima. J’arrive.`,
        lecon: "vous vener dobtenir le status de maitre alchiniste",
        badge_titre: "maitre alchimistre",
        badge_image:
          "https://www.canva.com/design/DAE9f0YTUWQ/_cw6X-giaRseN7AaTbFTZw/view?utm_content=DAE9f0YTUWQ&utm_campaign=share_your_design&utm_medium=link&utm_source=shareyourdesignpanel"
      }
    },

    pere_riche_pere_pauvre: {
      titre: "pere riche pere pauvre",
      resumer: "premier livre de developement personnel",
      acheter: "lien pour acheter",
      quiz: [
        {
          id: 0,
          question: "De quelle équipe fait partie Iceman?",
          options: [
            "The X-Men",
            "The Fantastic Four",
            "The Invaders",
            "The Liberators"
          ],
          answer: "The X-Men",
          heroId: 1009362
        },
        {
          id: 1,
          question:
            "Dans les X-Men, quelle substance constitue le squelette de Wolverine ?",
          options: ["Cavorite", "Vibranium", "Adamantium", "Neutronium"],
          answer: "Adamantium",
          heroId: 1009718
        }
      ]
    }
  }
];
