import React, { Component } from "react";
import { Container, Responsive } from "semantic-ui-react";

import Book from "../components/mobileBook";
import ComputerBook from "../components/computerBook";
import BigMenu from "../components/headerMenu";
import FooterMenu from "../components/fotterMenu";

export default class home extends Component {
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          <Container>
            <Book />
          </Container>
          <FooterMenu />
        </Responsive>
        <Responsive minWidth={768}>
          <BigMenu />
          <ComputerBook />
        </Responsive>
      </div>
    );
  }
}
