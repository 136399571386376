import React, { Component } from "react";
import { Container, Responsive } from "semantic-ui-react";
import { Helmet } from "react-helmet";

import MobileHome from "../components/mobileHome";
import ComputerHome from "../components/computerHome";
import BigMenu from "../components/headerMenu";
import Slider from "../components/slider";
import FooterMenu from "../components/fotterMenu";

export default class home extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Ivoire-Book | accueil </title>
        </Helmet>
        <Responsive {...Responsive.onlyMobile}>
          <Container>
            <BigMenu />
            <Slider />
            <MobileHome />
          </Container>
          <FooterMenu />
        </Responsive>
        <Responsive minWidth={768}>
          <BigMenu />
          <ComputerHome />
        </Responsive>
      </div>
    );
  }
}
